import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import debounce from 'lodash.debounce';
import Dialog from '@material-ui/core/Dialog';
import EditIcon from '@material-ui/icons/Edit';
import FiberIcon from '@material-ui/icons/FiberManualRecord';
import i18n from 'i18next';
import IconButton from '@material-ui/core/IconButton';
import MatToolbar from '@material-ui/core/Toolbar';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { connect } from 'react-redux';
import {
  CustomPaging,
  DataTypeProvider,
  PagingState,
  SearchState,
  SortingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  SearchPanel,
  Table,
  TableHeaderRow,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui';
import { notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { translateName } from '../../utils/utils';
import { Loading } from '../UI-Elements/Loading';
import { apiUrl, axiosCallApi } from '../../config/config';
import * as actions from '../../redux/actions';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 400
  },
  header: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: 50,
    paddingLeft: theme.spacing(4)
  }
});

function EditButton({ row, history }) {
  return (
    <IconButton
      onClick={() => {
        history.push(`/dashboard/properties/edit/${row._id}`);
      }}
      title="Edit row"
    >
      <EditIcon />
    </IconButton>
  );
}

function ViewButton({ row }) {
  return (
    <a
      href={`https://www.thebrik.com/property/${row.slug}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <IconButton title="View Request">
        <VisibilityIcon />
      </IconButton>
    </a>
  );
}

function StatusFormatter({ value }) {
  if (value === 'Yes') {
    return (
      <p style={{ marginTop: 5, textAlign: 'center' }}>
        <span className="active-product">{value}</span>
      </p>
    );
  } else {
    return (
      <p style={{ marginTop: 5, textAlign: 'center' }}>
        <span className="not-active-product">{value}</span>
      </p>
    );
  }
}

function StatusTypeProvider(props) {
  return <DataTypeProvider formatterComponent={StatusFormatter} {...props} />;
}

const Cell = withRouter(({ column, value, style, row, history }) => {
  let content = value;
  if (column.name === 'view') {
    content = <ViewButton row={row} history={history} />;
  }
  if (column.name === 'edit') {
    content = <EditButton row={row} history={history} />;
  }
  if (column.name === 'active') {
    content = <StatusFormatter value={value} />;
  }
  return <Table.Cell style={style}>{content}</Table.Cell>;
});

class PropertiesListModal extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      columns: [
        {
          name: 'createdAt',
          title: t('common.tables.headers.created-at'),
          getCellValue: (row) => moment(row.createdAt).format('DD/MM/YYYY HH:mm')
        },
        {
          name: 'region',
          title: t('list-properties.headers.area'),
          getCellValue: (row) => translateName(i18n.language, row.region)
        },
        {
          name: 'price',
          title: t('list-properties.headers.price'),
          getCellValue: (row) => row.price
        },
        {
          name: 'property-type',
          title: t('list-properties.headers.property-type'),
          getCellValue: (row) => row.type.label
        },
        {
          name: 'listingStatus',
          title: t('list-properties.headers.listing-status'),
          getCellValue: (row) =>
            row.approved ? (
              <Tooltip title={t('list-properties.listing-status.published')}>
                <FiberIcon style={{ color: '#87d068' }} aria-label="Delete" />
              </Tooltip>
            ) : (
              <Tooltip title={t('list-properties.listing-status.draft')}>
                <FiberIcon style={{ color: 'orange' }} aria-label="Delete" />
              </Tooltip>
            )
        },
        {
          name: 'propertyStatus',
          title: t('list-properties.headers.property-status'),
          getCellValue: (row) =>
            row.condition.value === 'available' ? (
              <Tooltip title={t('list-properties.property-status.available')}>
                <FiberIcon
                  style={{ color: '#87d068' }}
                  aria-label={row.condition.label}
                />
              </Tooltip>
            ) : row.condition.value === 'under-offer' ? (
              <Tooltip title={t('list-properties.property-status.under-offer')}>
                <FiberIcon
                  style={{ color: '#2db7f5' }}
                  aria-label={row.condition.label}
                />
              </Tooltip>
            ) : row.condition.value === 'advanced-payment' ? (
              <Tooltip title={t('list-properties.property-status.advanced-payment')}>
                <FiberIcon
                  style={{ color: '#108ee9' }}
                  aria-label={row.condition.label}
                />
              </Tooltip>
            ) : (
              <Tooltip title={t('list-properties.property-status.sold')}>
                <FiberIcon style={{ color: '#f50' }} aria-label={row.condition.label} />
              </Tooltip>
            )
        },
        { name: 'edit', title: t('common.tables.headers.edit') },
        { name: 'view', title: t('list-properties.headers.view-property') }
      ],
      tableColumnExtensions: [{ columnName: 'active', align: 'center' }],
      sortingStateColumnExtensions: [{ columnName: 'edit', sortingEnabled: false }],
      filter: '',
      loading: true,
      rows: [],
      sorting: [{ columnName: 'createdAt', direction: 'asc' }],
      statusColumns: ['active'],
      totalCount: 0
    };

    this.loadDataDebounced = debounce(() => {
      this.loadData();
    }, 600);
  }

  componentDidMount() {
    if (this.props.row) {
      axiosCallApi.post(`${apiUrl}/admin/getRole`).then((response) => {
        // eslint-disable-next-line
        this.setState({ scopes: response.data.scopes });
      });
      this.loadDataDebounced();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.sorting !== prevState.sorting) {
      this.props.onCurrentPageChange(0);
    }
    if (
      this.props.currentPage !== prevProps.currentPage ||
      this.props.pageSize !== prevProps.pageSize ||
      this.state.filter !== prevState.filter ||
      this.state.sorting !== prevState.sorting
    ) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  changeSorting = (sorting) => {
    this.setState({
      loading: true,
      sorting
    });
  };

  changePageSize = (pageSize) => {
    const { totalCount } = this.state;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.min(this.props.currentPage, totalPages);

    this.setState(
      {
        loading: true
      },
      () => {
        this.props.onPageSizeChange(pageSize);
        this.props.onCurrentPageChange(currentPage);
      }
    );
  };

  changeCurrentPage = (currentPage) => {
    this.setState(
      {
        loading: true
      },
      () => this.props.onCurrentPageChange(currentPage)
    );
  };

  changeSearchValue = (searchValue) => {
    this.setState(
      {
        loading: true,
        filter: searchValue
      },
      () => this.props.onCurrentPageChange(0)
    );
  };

  queryString() {
    const { sorting, filter } = this.state;
    const { pageSize, currentPage } = this.props;
    let queryString = `${apiUrl}/agent/properties/?perPage=${pageSize}&skip=${currentPage}&filter=${filter}&id=${this.props.row._id}`;
    const columnSorting = sorting[0];
    if (columnSorting) {
      const sortingDirectionString = columnSorting.direction === 'desc' ? 1 : -1;
      queryString = `${queryString}&orderby=${columnSorting.columnName}`;
      queryString = `${queryString}&sort=${sortingDirectionString}`;
    }
    return queryString;
  }

  loadData() {
    const queryString = this.queryString();
    if (queryString === this.lastQuery) {
      // this.setState({ loading: false });
      return;
    }
    axiosCallApi
      .get(queryString)
      .then((response) => {
        this.setState({
          rows: response.data.properties,
          totalCount: response.data.count,
          loading: false
        });
      })
      .catch(() => {
        notification.error({
          message: this.props.t('common.error-fetch')
        });
        this.setState({ loading: false });
      });
    this.lastQuery = queryString;
  }

  render() {
    const {
      classes,
      currentPage,
      handleCloseRequestModal,
      pageSize,
      pageSizes,
      row,
      t,
      visible
    } = this.props;
    const {
      columns,
      filter,
      loading,
      rows,
      sorting,
      sortingStateColumnExtensions,
      statusColumns,
      tableColumnExtensions,
      totalCount
    } = this.state;
    return (
      <Dialog
        className="request-modal"
        fullScreen
        onClose={handleCloseRequestModal}
        open={visible}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <MatToolbar className="request-modal-toolbar">
            <h2>
              {t('agent-properties-modal.info')} {row ? row.surname : ''}{' '}
              {row ? row.name : ''}
            </h2>
            <div className="request-modal-icons-section">
              <ReactToPrint
                trigger={() => (
                  <PrintIcon className="print-button" style={{ marginRight: 30 }} />
                )}
                content={() => this.componentRef}
              />
              <IconButton
                aria-label="Close"
                color="inherit"
                onClick={handleCloseRequestModal}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </MatToolbar>
        </AppBar>
        <div ref={(el) => (this.componentRef = el)}>
          <Paper
            style={{
              marginLeft: 30,
              marginRight: 30,
              marginTop: 85,
              position: 'relative'
            }}
          >
            <Grid rows={rows} columns={columns}>
              <SortingState
                columnExtensions={sortingStateColumnExtensions}
                onSortingChange={this.changeSorting}
                sorting={sorting}
              />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={this.changeCurrentPage}
                onPageSizeChange={this.changePageSize}
                pageSize={pageSize}
              />
              <CustomPaging totalCount={totalCount} />
              <SearchState onValueChange={this.changeSearchValue} value={filter} />
              <StatusTypeProvider for={statusColumns} />
              <Table
                // eslint-disable-next-line
                cellComponent={({ column, value, style, row }) => (
                  <Cell
                    agentModal={this.showAgentModal}
                    column={column}
                    row={row}
                    style={style}
                    value={value}
                  />
                )}
                columnExtensions={tableColumnExtensions}
              />
              <TableHeaderRow showSortingControls />
              <Toolbar />
              <SearchPanel messages={{ searchPlaceholder: t('common.tables.search') }} />
              <PagingPanel
                pageSizes={pageSizes}
                messages={{
                  showAll: t('common.tables.footer.all'),
                  rowsPerPage: t('common.tables.footer.rows-per-page'),
                  info: `${t('common.tables.footer.info.showing')} {from} ${t(
                    'common.tables.footer.info.to'
                  )} {to} ({count} ${t('common.tables.footer.info.total-elements')})`
                }}
              />
            </Grid>
            {loading && <Loading />}
          </Paper>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  currentPage: state.agentProperties.currentPage,
  pageSize: state.agentProperties.pageSize,
  pageSizes: state.agentProperties.pageSizes
});

const mapDispatchToProps = (dispatch) => ({
  onCurrentPageChange: (currentPage) =>
    dispatch(actions.createGridAgentPropertiesAction('currentPage', currentPage)),
  onPageSizeChange: (pageSize) =>
    dispatch(actions.createGridAgentPropertiesAction('pageSize', pageSize)),
  reset: () => dispatch(actions.resetGridAgentPropertiesAction())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation()(
    withRouter(withStyles(styles, { withTheme: true })(PropertiesListModal))
  )
);
