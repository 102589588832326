import NeighborhoodList from '../components/Regions/RegionsList';
import AddNewNeighborhood from '../components/Regions/AddNewRegion';
import EditNeighborhood from '../components/Regions/EditRegion';

const neighborhoodsRoutes = [
  {
    path: '/dashboard/neighborhood/list',
    exact: false,
    name: 'List Neighborhood',
    component: NeighborhoodList
  },
  {
    path: '/dashboard/neighborhood/add',
    exact: false,
    name: 'Add Neighborhood',
    component: AddNewNeighborhood
  },
  {
    path: '/dashboard/neighborhood/edit/:id',
    exact: false,
    name: 'Edit Neighborhood',
    component: EditNeighborhood
  }
];

export default neighborhoodsRoutes;
