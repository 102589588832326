import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import indigo from '@material-ui/core/colors/indigo';
import './styles/loading.css';

// eslint-disable-next-line
export const Loading = () => (
  <div className="loading-shading-mui">
    <CircularProgress
      className="loading-icon-mui"
      style={{ color: indigo[300] }}
      thickness={5}
    />
  </div>
);
