import TeamsList from '../components/Agents/AgentsList';
import AddNewTeam from '../components/Agents/AddNewAgent';
import EditTeam from '../components/Agents/EditAgent';

const agentRoutes = [
  {
    path: '/dashboard/agents/list',
    exact: false,
    name: 'List Agents',
    component: TeamsList
  },
  {
    path: '/dashboard/agents/add',
    exact: false,
    name: 'Add Agent',
    component: AddNewTeam
  },
  {
    path: '/dashboard/agents/edit/:id',
    exact: false,
    name: 'Edit Agent',
    component: EditTeam
  }
];

export default agentRoutes;
