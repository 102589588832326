import TransportationList from '../components/Transportation/TransportationList';
import AddNewTransportation from '../components/Transportation/AddNewTransportation';
import EditTransportation from '../components/Transportation/EditTransportation';

const transportationRoutes = [
  {
    path: '/dashboard/transportation/list',
    exact: false,
    name: 'List Transportation',
    component: TransportationList
  },
  {
    path: '/dashboard/transportation/add',
    exact: false,
    name: 'Add Transportation',
    component: AddNewTransportation
  },
  {
    path: '/dashboard/transportation/edit/:id',
    exact: false,
    name: 'Edit Transportation',
    component: EditTransportation
  }
];

export default transportationRoutes;
