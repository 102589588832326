import React from 'react';
import { Route } from 'react-router-dom';
import citiesRoutes from '../routes/cities.routes';

function Cities() {
  const routes = citiesRoutes.map((prop, key) => (
    <Route
      component={prop.component}
      exact={prop.exact}
      key={key.toString()}
      path={prop.path}
    />
  ));
  return <>{routes}</>;
}

export default Cities;
