import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import debounce from 'lodash.debounce';
import EditIcon from '@material-ui/icons/Edit';
import FiberIcon from '@material-ui/icons/FiberManualRecord';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import {
  CustomPaging,
  DataTypeProvider,
  PagingState,
  SearchState,
  SortingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  SearchPanel,
  Table,
  TableHeaderRow,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui';
import { Link, withRouter } from 'react-router-dom';
import { notification } from 'antd';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { Loading } from '../UI-Elements/Loading';
import { apiUrl, axiosCallApi } from '../../config/config';
import * as actions from '../../redux/actions';

const styles = (theme) => ({
  button: {
    marginLeft: theme.spacing(3),
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  paperWrapper: {
    boxShadow: 'none'
  }
});

function EmailFormatter({ value }) {
  return <a href={`mailto:${value}`}>{value}</a>;
}

function EmailTypeProvider(props) {
  return <DataTypeProvider formatterComponent={EmailFormatter} {...props} />;
}

function PhoneFormatter({ value }) {
  return <a href={`tel:${value}`}>{value}</a>;
}

function PhoneTypeProvider(props) {
  return <DataTypeProvider formatterComponent={PhoneFormatter} {...props} />;
}

function EditButton({ row, history }) {
  return (
    <IconButton
      onClick={() => {
        history.push(`/dashboard/content-managers/edit/${row._id}`);
      }}
      title="Edit row"
    >
      <EditIcon />
    </IconButton>
  );
}

const Cell = withRouter(({ column, value, style, row, history }) => {
  let content = value;
  if (column.name === 'edit') {
    content = <EditButton row={row} history={history} />;
  }
  if (column.name === 'email') {
    content = <EmailFormatter row={row} value={value} />;
  }
  if (column.name === 'mobile') {
    content = <PhoneFormatter row={row} value={value} />;
  }
  return <Table.Cell style={style}>{content}</Table.Cell>;
});

class ContentManagerList extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      columns: [
        {
          name: 'name',
          title: t('common.name'),
          getCellValue: (row) => row.name
        },
        {
          name: 'surname',
          title: t('common.last-name'),
          getCellValue: (row) => row.surname
        },
        {
          name: 'active',
          title: t('common.status'),
          getCellValue: (row) =>
            row.active ? (
              <Tooltip title={t('content-managers.edit.active')}>
                <FiberIcon style={{ color: '#87d068' }} aria-label="Delete" />
              </Tooltip>
            ) : (
              <Tooltip title={t('content-managers.edit.inactive')}>
                <FiberIcon style={{ color: '#f50' }} aria-label="Delete" />
              </Tooltip>
            )
        },
        {
          name: 'email',
          title: t('common.email'),
          getCellValue: (row) => row.email
        },
        {
          name: 'mobile',
          title: t('common.mobile'),
          getCellValue: (row) => row.mobile
        },
        { name: 'edit', title: t('content-managers.edit.edit-manager') }
      ],
      sortingStateColumnExtensions: [{ columnName: 'edit', sortingEnabled: false }],
      emailColumns: ['email'],
      filter: '',
      loading: true,
      mobileColumns: ['mobile'],
      rows: [],
      sorting: [{ columnName: 'createdAt', direction: 'desc' }],
      totalCount: 0
    };
    this.loadDataDebounced = debounce(() => {
      this.loadData();
    }, 600);
  }

  componentDidMount() {
    this.loadDataDebounced();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.sorting !== prevState.sorting) {
      this.props.onCurrentPageChange(0);
    }
    if (
      this.props.currentPage !== prevProps.currentPage ||
      this.props.pageSize !== prevProps.pageSize ||
      this.state.filter !== prevState.filter ||
      this.state.sorting !== prevState.sorting
    ) {
      this.loadData();
    }
  }

  changeSorting = (sorting) => {
    this.setState({
      loading: true,
      sorting
    });
  };

  changePageSize = (pageSize) => {
    const { totalCount } = this.state;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.min(this.props.currentPage, totalPages - 1);

    this.setState(
      {
        loading: true
      },
      () => {
        this.props.onPageSizeChange(pageSize);
        this.props.onCurrentPageChange(currentPage);
      }
    );
  };

  changeCurrentPage = (currentPage) => {
    this.setState(
      {
        loading: true
      },
      () => this.props.onCurrentPageChange(currentPage)
    );
  };

  changeSearchValue = (searchValue) => {
    this.setState(
      {
        loading: true,
        filter: searchValue
      },
      () => this.props.onCurrentPageChange(0)
    );
  };

  queryString() {
    const { sorting, filter } = this.state;
    const { pageSize, currentPage } = this.props;
    let queryString = `${apiUrl}/content-manager/all/?perPage=${pageSize}&skip=${currentPage}&filter=${filter}`;
    const columnSorting = sorting[0];
    if (columnSorting) {
      const sortingDirectionString = columnSorting.direction === 'desc' ? 1 : -1;
      queryString = `${queryString}&orderby=${columnSorting.columnName}`;
      queryString = `${queryString}&sort=${sortingDirectionString}`;
    }
    return queryString;
  }

  loadData() {
    const queryString = this.queryString();
    if (queryString === this.lastQuery) {
      return;
    }
    axiosCallApi
      .get(queryString)
      .then((response) => {
        this.setState({
          rows: response.data.cms,
          totalCount: response.data.count,
          loading: false
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        notification.error({
          message: 'An error has occured, please try again'
        });
      });
    this.lastQuery = queryString;
  }

  render() {
    const { classes, currentPage, pageSize, pageSizes, t } = this.props;
    const {
      columns,
      emailColumns,
      filter,
      loading,
      mobileColumns,
      rows,
      sorting,
      sortingStateColumnExtensions,
      tableColumnExtensions,
      totalCount
    } = this.state;

    return (
      <>
        <Typography variant="h6" gutterBottom>
          {t('content-managers.list.list-agents')}
          <Button
            className={[classes.button, 'secondaryBtnColor'].join(' ')}
            color="secondary"
            component={Link}
            to="/dashboard/content-managers/add"
            variant="contained"
          >
            <AddIcon className={classes.leftIcon} />
            {t('content-managers.add-new.add')}
          </Button>
        </Typography>
        <Paper className={classes.paperWrapper} style={{ position: 'relative' }}>
          <Grid rows={rows} columns={columns}>
            <SortingState
              columnExtensions={sortingStateColumnExtensions}
              onSortingChange={this.changeSorting}
              sorting={sorting}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.changeCurrentPage}
              onPageSizeChange={this.changePageSize}
              pageSize={pageSize}
            />
            <CustomPaging totalCount={totalCount} />
            <SearchState onValueChange={this.changeSearchValue} value={filter} />
            <PhoneTypeProvider for={mobileColumns} />
            <EmailTypeProvider for={emailColumns} />
            <Table cellComponent={Cell} columnExtensions={tableColumnExtensions} />
            <TableHeaderRow showSortingControls />
            <Toolbar />
            <SearchPanel messages={{ searchPlaceholder: t('common.tables.search') }} />
            <PagingPanel
              pageSizes={pageSizes}
              messages={{
                showAll: t('common.tables.footer.all'),
                rowsPerPage: t('common.tables.footer.rows-per-page'),
                info: `${t('common.tables.footer.info.showing')} {from} ${t(
                  'common.tables.footer.info.to'
                )} {to} ({count} ${t('common.tables.footer.info.total-elements')})`
              }}
            />
          </Grid>
          {loading && <Loading />}
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentPage: state.cms.currentPage,
  pageSize: state.cms.pageSize,
  pageSizes: state.cms.pageSizes
});

const mapDispatchToProps = (dispatch) => ({
  onCurrentPageChange: (currentPage) =>
    dispatch(actions.createGridContentManagersAction('currentPage', currentPage)),
  onPageSizeChange: (pageSize) =>
    dispatch(actions.createGridContentManagersAction('pageSize', pageSize))
});

export default withTranslation()(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContentManagerList))
  )
);
