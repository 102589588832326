import AddWebsiteUser from '../components/WebsiteUsers/AddWebsiteUser';

const websiteUsersRoutes = [
  {
    path: '/dashboard/website-users/add',
    exact: true,
    name: 'add website user',
    component: AddWebsiteUser
  }
];

export default websiteUsersRoutes;
