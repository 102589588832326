import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import GridCol from '@material-ui/core/Grid';
import { withTranslation } from 'react-i18next';
import { avatarUrl } from '../../config/config';

function AgentModal({ handleAgentModalClose, row, t, visible }) {
  return (
    <Dialog fullWidth maxWidth="sm" onClose={handleAgentModalClose} open={visible}>
      <DialogContent className="agent-dialog">
        <GridCol item xs={12} sm={12} md={12}>
          <h2 className="request-title">{t('agent-modal.agent-info')}</h2>
          <div className="agent-info-section">
            <div>
              <div className="request-info-title">
                <span className="label">{t('agent-modal.name')}</span>{' '}
                {row ? row.agent.name : ''}
              </div>
              <div className="request-info-title">
                <span className="label">{t('agent-modal.last-name')}</span>{' '}
                {row ? row.agent.surname : ''}
              </div>
              <div className="request-info-title">
                <span className="label">{t('agent-modal.phone')}</span>{' '}
                <a href={`tel:${row ? row.agent.phone : ''}`}>
                  {row ? row.agent.phone : ''}
                </a>
              </div>
              <div className="request-info-title">
                <span className="label">{t('agent-modal.mobile')}</span>{' '}
                <a href={`tel:${row ? row.agent.mobilePhone : ''}`}>
                  {row ? row.agent.mobilePhone : ''}
                </a>
              </div>
              <div className="request-info-title">
                <span className="label">{t('agent-modal.email')}</span>{' '}
                <a href={`mailto:${row ? row.agent.email : ''}`}>
                  {row ? row.agent.email : ''}
                </a>
              </div>
            </div>
            <img
              alt="Agent"
              className="img-fluid agent-avatar-small"
              src={`${avatarUrl}/${row ? row.agent.uid : ''}/${
                row ? row.agent.image : ''
              }`}
            />
          </div>
        </GridCol>
      </DialogContent>
    </Dialog>
  );
}

export default withTranslation()(AgentModal);
