import List from '../components/Sectors/SectorsList';
import Add from '../components/Sectors/AddNewSector';
import Edit from '../components/Sectors/EditSector';

const routes = [
  {
    path: '/dashboard/sector/list',
    exact: false,
    name: 'List Sector',
    component: List
  },
  {
    path: '/dashboard/sector/add',
    exact: false,
    name: 'Add Sector',
    component: Add
  },
  {
    path: '/dashboard/sector/edit/:id',
    exact: false,
    name: 'Edit Sector',
    component: Edit
  }
];

export default routes;
