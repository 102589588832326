import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import update from 'immutability-helper';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { apiUrl, axiosCallApi } from '../../config/config';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  button: {
    backgroundColor: 'transparent',
    border: '1px solid #000000',
    color: 'black',
    margin: theme.spacing(1),
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'black',
      color: '#FFFFFF'
    }
  },
  spinner: {
    alignItems: 'center',
    display: 'flex',
    height: '80vh',
    justifyContent: 'center'
  },
  input: {
    display: 'flex',
    height: 'inherit'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%'
  },
  formControlTitle: {
    margin: theme.spacing(1),
    width: '99%'
  },
  formText: {
    margin: theme.spacing(1),
    width: '94%'
  },
  formTextTitle: {
    margin: theme.spacing(1),
    width: '99%',
    marginTop: 20
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(0.25)
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    marginTop: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(2)
  },
  selectControl: {
    position: 'relative',
    width: '100%',
    zIndex: 99999
  },
  paperWrapper: {
    padding: 25,
    boxShadow: 'none'
  }
});

notification.config({
  duration: 3,
  placement: 'topRight',
  top: 24
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          children: props.children,
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      component="div"
      selected={props.isFocused}
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={[
        props.selectProps.classes.paper,
        props.selectProps.classes.selectControl
      ].join(' ')}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

class AddNewSector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sector: {
        active: true,
        city: null,
        nameEl: '',
        nameEn: '',
        nameZh: '',
        metaTitleEl: '',
        metaTitleEn: '',
        metaTitleZh: '',
        metaDescriptionEl: '',
        metaDescriptionEn: '',
        metaDescriptionZh: '',
        metaKeywordsEl: '',
        metaKeywordsEn: '',
        metaKeywordsZh: ''
      },
      country: null,
      county: null,
      countries: [],
      countryList: [],
      countyList: [],
      cityList: [],
      submitBtnIsDisabled: false,
      loading: false
    };
  }

  componentDidMount() {
    const { t } = this.props;

    axiosCallApi
      .get(`${apiUrl}/city/countries/all`)
      .then((response) => {
        const { countries } = response.data;
        const countryList = countries.map((c) => ({ label: c.labelEl, value: c.value }));
        this.setState({ countries, countryList });
      })
      .catch(() => {
        notification.error({
          message: t('common.error-fetch')
        });
      });
  }

  onInputChange = (e) => {
    this.setState(
      update(this.state, {
        sector: {
          [e.target.name]: {
            $set: e.target.value
          }
        }
      })
    );
  };



  submit = (e) => {
    e.preventDefault();
    const { t } = this.props;
    const data = this.state.sector;

    if (!data.city) {
      notification.error({
        message: t('sectors.edit.enter-city')
      });
      return;
    }

    axiosCallApi
      .post(`${apiUrl}/sector/new`, data)
      .then(() => {
        notification.success({
          message: t('sectors.add-new.create-success')
        });
        this.props.history.push('/dashboard/sector/list');
      })
      .catch((error) => {
        console.log('err', error);
        notification.error({
          message: t('sectors.add-new.create-error')
        });
      });
  };

  onCheckBoxActiveChange = (event) => {
    this.setState(
      update(this.state, {
        sector: {
          active: { $set: event.target.checked }
        }
      })
    );
  };

  handleSelectChangeCountry = (country) => {
    if (
      this.state.country &&
      this.state.country.value !== undefined &&
      country.value === this.state.country.value
    ) {
      return;
    }

    const { countries } = this.state;
    for (let i = 0; i < countries.length; i++) {
      if (countries[i].value === country.value) {
        const countyList = countries[i].counties.map((c) => ({
          label: c.labelEl,
          value: c.value
        }));
        this.setState(
          update(this.state, {
            sector: {
              city: { $set: null }
            },
            country: { $set: country },
            county: { $set: null },
            countyList: { $set: countyList }
          })
        );
        return;
      }
    }
  };

  handleSelectChangeCounty = (county) => {
    const { t } = this.props;

    if (
      this.state.county &&
      this.state.county.value !== undefined &&
      county.value === this.state.county.value
    ) {
      return;
    }

    axiosCallApi
      .get(`${apiUrl}/city/counties/${this.state.country.value}/${county.value}`)
      .then((response) => {
        const { cities } = response.data;
        const cityList = cities.map((c) => ({
          label: c.nameEl,
          value: c.value,
          _id: c._id
        }));
        this.setState(
          update(this.state, {
            sector: {
              city: { $set: null }
            },
            county: { $set: county },
            cityList: { $set: cityList }
          })
        );
      })
      .catch((error) => {
        console.log(error);
        notification.error({
          message: t('common.error-fetch')
        });
      });
  };

  handleSelectChangeCity = (city) => {
    this.setState(
      update(this.state, {
        sector: {
          city: { $set: city }
        }
      })
    );
  };

  render() {
    const { classes, theme, t } = this.props;
    // const { region } = this.state
    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary
      })
    };

    return (
      <div>
        <Paper className={classes.paperWrapper}>
          <h1 className="title-wrapper">{t('sectors.add-new.sector-info')}</h1>
          <form onSubmit={(e) => this.submit(e)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    value={this.state.country}
                    isSearchable
                    // name="country"
                    onChange={this.handleSelectChangeCountry}
                    options={this.state.countryList}
                    placeholder={t('common.country')}
                    required
                    styles={selectStyles}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    value={this.state.county}
                    isSearchable
                    // name="city"
                    onChange={this.handleSelectChangeCounty}
                    options={this.state.countyList}
                    placeholder={t('common.county')}
                    required
                    styles={selectStyles}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    value={this.state.sector.city}
                    isSearchable
                    // name="city"
                    onChange={this.handleSelectChangeCity}
                    options={this.state.cityList}
                    placeholder={t('common.city')}
                    required
                    styles={selectStyles}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="nameEl"
                    label="Όνομα"
                    name="nameEl"
                    onChange={this.onInputChange}
                    required
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="nameEn"
                    label="Name"
                    name="nameEn"
                    onChange={this.onInputChange}
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="nameZh"
                    label="名称"
                    name="nameZh"
                    onChange={this.onInputChange}
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid container>
                <FormControl
                  className={classes.formControl}
                  style={{ paddingTop: 28, marginBottom: 16 }}
                >
                  <FormLabel component="legend">{t('common.status')}</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.sector.active}
                        className="customCheckbox"
                        name="active"
                        onChange={this.onCheckBoxActiveChange}
                      />
                    }
                    label={
                      this.state.sector.active
                        ? t('areas.list.area-active')
                        : t('areas.list.area-inactive')
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={10}>
              <Grid item xs={12}>
                <Button className={classes.button} type="submit" variant="contained">
                  {t('common.submit')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(withStyles(styles, { withTheme: true })(AddNewSector))
);
