import React, { Component } from 'react';
import Avatar from 'react-avatar-edit';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import i18n from 'i18next';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import update from 'immutability-helper';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { apiUrl, axiosCallApi } from '../../config/config';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  button: {
    backgroundColor: 'transparent',
    border: '1px solid #000000',
    boxShadow: 'none',
    color: 'black',
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'black',
      color: '#FFFFFF'
    }
  },
  input: {
    display: 'flex',
    padding: 0
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%'
  },
  valueContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    fontSize: 16,
    left: 2,
    position: 'absolute'
  },
  paper: {
    marginTop: theme.spacing(1)
  },
  selectControl: {
    position: 'absolute',
    width: '100%',
    zIndex: 99999
  },
  divider: {
    height: theme.spacing(2)
  },
  paperWrapper: {
    boxShadow: 'none',
    padding: 20
  }
});

const language = localStorage.getItem('lng');

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        address: '',
        city: '',
        email: '',
        image: '',
        mobilePhone: '',
        name: '',
        phone: '',
        postalCode: '',
        property: '',
        surname: '',
        uid: ''
      },
      isAdminOrCM: false,
      language,
      preview: '',
      scopes: [],
      src: '',
      submitBtnIsDisabled: false
    };
  }

  componentDidMount() {
    const { t } = this.props;
    this.setState({ loading: true });
    axiosCallApi.post(`${apiUrl}/admin/getRole`).then((response) => {
      this.setState({
        isAdminOrCM:
          response.data.scopes.includes('admin') || response.data.scopes.includes('CM')
      });
      if (response.data.scopes.includes('admin') || response.data.scopes.includes('CM')) {
        axiosCallApi
          .get(`${apiUrl}/admin/profile`)
          .then((res) => {
            const user = res.data.admin;
            this.setState({ user });
          })
          .catch(() => {
            notification.error({
              message: t('common.error-fetch')
            });
          });
      } else {
        axiosCallApi
          .get(`${apiUrl}/agent/profile`)
          .then((res) => {
            const user = res.data.agent;
            user.base64 = res.data.base;
            this.setState({ user });
          })
          .catch(() => {
            notification.error({
              message: t('common.error-fetch')
            });
          });
      }

      this.setState({ scopes: response.data.scopes });
    });
  }

  handleLanguageChange = (e) => {
    const { isAdminOrCM } = this.state;
    this.setState({ language: e.target.value }, () => {
      i18n.changeLanguage(this.state.language);
      localStorage.setItem('lng', i18n.language);
      if (isAdminOrCM) {
        axiosCallApi
          .patch(`${apiUrl}/admin/profile/language/${e.target.value}`)
          .then(() => {})
          .catch((error) => console.log(error));
      } else {
        axiosCallApi
          .patch(`${apiUrl}/agent/profile/${e.target.value}`)
          .then(() => {})
          .catch((error) => console.log(error));
      }
    });
  };

  submitEditProfile = (e) => {
    e.preventDefault();
    const { isAdminOrCM } = this.state;
    const { t } = this.props;
    const data = this.state.user;
    if (isAdminOrCM) {
      axiosCallApi
        .patch(`${apiUrl}/admin/profile`, data)
        .then(() => {
          notification.success({
            message: t('add-new-agent.response-edit-profile-success')
          });
          this.props.history.push('/dashboard/agents/list');
        })
        .catch(() => {
          notification.error({
            message: t('common.error-fetch')
          });
        });
    } else {
      axiosCallApi
        .patch(`${apiUrl}/agent/profile`, data)
        .then(() => {
          notification.success({
            message: t('add-new-agent.response-edit-profile-success')
          });
          this.props.history.push('/dashboard/properties/list');
        })
        .catch(() => {
          notification.error({
            message: t('common.error-fetch')
          });
        });
    }
  };

  onInputChange = (e) => {
    this.setState(
      update(this.state, {
        user: {
          [e.target.name]: {
            $set: e.target.value
          }
        }
      })
    );
  };

  onClose = () => {
    this.setState({ preview: null });
  };

  onCrop = (preview) => {
    this.setState({ preview });
    const data = {
      preview
    };
    axiosCallApi
      .post(`${apiUrl}/agent/upload`, data)
      .then((response) =>
        this.setState(
          update(this.state, {
            user: {
              image: { $set: response.data.image }
            }
          })
        )
      )
      .catch((e) => console.log(e));
  };

  onBeforeFileLoad = (elem) => {
    const { t } = this.props;
    if (elem.target.files[0].size > 5000000) {
      // eslint-disable-next-line
      alert(t('add-new-agent.file-too-big'));
      elem.target.value = '';
    }
  };

  render() {
    const { classes, history, t } = this.props;
    const { user, preview, isAdminOrCM } = this.state;

    return (
      <div>
        <Paper style={{ position: 'relative' }} className={classes.paperWrapper}>
          <h1 className="title-wrapper">
            <span>{t('menu.edit-profile')}</span>
          </h1>
          <form onSubmit={(e) => this.submitEditProfile(e)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 25 }}>
                  <TextField
                    fullWidth
                    id="name"
                    label={t('common.name')}
                    name="name"
                    onChange={this.onInputChange}
                    required
                    type="text"
                    value={user.name}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 25 }}>
                  <TextField
                    fullWidth
                    id="surname"
                    label={t('common.last-name')}
                    name="surname"
                    onChange={this.onInputChange}
                    required
                    value={user.surname}
                  />
                </FormControl>
              </Grid>
              {!isAdminOrCM ? (
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl className={classes.formControl} style={{ paddingTop: 25 }}>
                    <TextField
                      fullWidth
                      id="property"
                      label={t('add-new-agent.specialty')}
                      name="property"
                      onChange={this.onInputChange}
                      required
                      text="text"
                      value={user.property}
                    />
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 25 }}>
                  <TextField
                    fullWidth
                    id="phone"
                    label={t('common.phone')}
                    name="phone"
                    onChange={this.onInputChange}
                    type="number"
                    value={user.phone}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 25 }}>
                  <TextField
                    fullWidth
                    id="mobile"
                    label={t('common.mobile')}
                    name="mobile"
                    onChange={this.onInputChange}
                    type="number"
                    value={user.mobilePhone}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 25 }}>
                  <TextField
                    fullWidth
                    id="email"
                    label={t('common.email')}
                    name="email"
                    onChange={this.onInputChange}
                    required
                    type="email"
                    value={user.email}
                  />
                </FormControl>
              </Grid>
              {!isAdminOrCM ? (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl
                      className={classes.formControl}
                      style={{ paddingTop: 25 }}
                    >
                      <TextField
                        fullWidth
                        id="city"
                        label={t('add-new-agent.city')}
                        name="city"
                        onChange={this.onInputChange}
                        required
                        type="text"
                        value={user.city}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl
                      className={classes.formControl}
                      style={{ paddingTop: 25 }}
                    >
                      <TextField
                        fullWidth
                        id="address"
                        label={t('add-new-agent.address')}
                        name="address"
                        onChange={this.onInputChange}
                        required
                        type="text"
                        value={user.address}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormControl
                      className={classes.formControl}
                      style={{ paddingTop: 25 }}
                    >
                      <TextField
                        fullWidth
                        id="zipCode"
                        label={t('add-new-agent.postal-code')}
                        name="zipCode"
                        onChange={this.onInputChange}
                        required
                        type="text"
                        value={user.postalCode}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={12}>
                    {user.uid ? (
                      <div className="avatar-wrapper">
                        <div>
                          <span>{t('add-new-agent.choose-avatar')}</span>
                          <Avatar
                            height={200}
                            onBeforeFileLoad={this.onBeforeFileLoad}
                            onClose={this.onClose}
                            onCrop={this.onCrop}
                            onFileLoad={this.uploadImage}
                            src={user.base64}
                            width={200}
                          />
                        </div>
                        {preview ? (
                          <div className="avatar-preview">
                            <span>{t('add-new-agent.preview-avatar')}</span>
                            <div id="avatar_container-9d8d-ba30-2b3a">
                              <div
                                className="konvajs-content preview-content"
                                role="presentation"
                              >
                                <canvas
                                  width="200"
                                  height="200"
                                  className="preview-canvas"
                                />
                                <img src={preview} alt="Preview" />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </Grid>
                </>
              ) : null}
              <Grid container spacing={10}>
                <Grid item xs={12}>
                  <Button className={classes.button} type="submit" variant="contained">
                    {t('common.submit')}
                  </Button>
                  <Button
                    className={classes.button}
                    onClick={() => history.push('/dashboard/properties/list')}
                    variant="contained"
                  >
                    {t('common.cancel')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Paper
          style={{ position: 'relative', marginTop: 25 }}
          className={classes.paperWrapper}
        >
          <h1 className="title-wrapper">
            <span>{t('settings.language')}</span>
          </h1>
          <form onSubmit={(e) => this.submitEditAgent(e)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="age-simple">
                    {t('settings.select-language')}
                  </InputLabel>
                  <Select
                    inputProps={{ name: 'age', id: 'age-simple' }}
                    onChange={this.handleLanguageChange}
                    value={this.state.language}
                  >
                    <MenuItem value="el">Ελληνικά</MenuItem>
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="zh">中文</MenuItem>
                  </Select>
                  <FormHelperText>{t('settings.select-language-helper')}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(withStyles(styles, { withTheme: true })(Profile))
);
