import React from 'react';
import AccountIcon from '@material-ui/icons/AccountBox';
import Collapse from '@material-ui/core/Collapse';
import ContentManagerIcon from '@material-ui/icons/SupervisedUserCircle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/PeopleSharp';
import PermIcon from '@material-ui/icons/PermIdentity';
import RequestIcon from '@material-ui/icons/MailOutline';
import RssIcon from '@material-ui/icons/RssFeedSharp';
import StatisticsIcon from '@material-ui/icons/BarChart';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { apiUrl, axiosCallApi } from '../../config/config';

const styles = () => ({
  root: {
    background: 'rgb(45, 52, 70)',
    maxWidth: 360,
    width: '100%'
  },
  nested: {
    background: 'rgb(32, 39, 57)',
    paddingLeft: 8,
    paddingRight: 8
  },
  nestedButton: {
    paddingLeft: 24
  },
  menuItem: {
    color: '#ffffff'
  }
});

class NestedList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scopes: [],
      statistics: false
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevState.scopes.length === 0) {
      axiosCallApi.post(`${apiUrl}/admin/getRole`).then((response) => {
        this.setState({ scopes: response.data.scopes });
      });
    }
  }

  handleProperties = () => {
    this.setState((state) => ({ properties: !state.properties }));
  };

  handleStatistics = () => {
    this.setState((state) => ({ statistics: !state.statistics }));
  };

  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.root}>
        <List component="nav">
          {this.state.scopes.includes('admin') && (
            <>
              <ListItem button className="menu-item" onClick={this.handleStatistics}>
                <ListItemIcon>
                  <StatisticsIcon className="menu-icon" />
                </ListItemIcon>
                <ListItemText
                  className="menu-item"
                  primary={t('menu.statistics.statistics')}
                />
                {this.state.statistics ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.statistics} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <NavLink to="/dashboard/property-statistics/list">
                    <ListItem button disableGutters className={classes.nested}>
                      <ListItem button disableGutters className={classes.nestedButton}>
                        <ListItemText
                          className="menu-item"
                          primary={t('menu.statistics.estate-statistics')}
                        />
                      </ListItem>
                    </ListItem>
                  </NavLink>
                  <NavLink to="/dashboard/market-statistics/list">
                    <ListItem button disableGutters className={classes.nested}>
                      <ListItem button disableGutters className={classes.nestedButton}>
                        <ListItemText
                          className="menu-item"
                          primary={t('menu.statistics.market-statistics')}
                        />
                      </ListItem>
                    </ListItem>
                  </NavLink>
                </List>
              </Collapse>
            </>
          )}
          <ListItem button className="menu-item" onClick={this.handleProperties}>
            <ListItemIcon>
              <HomeIcon className="menu-icon" />
            </ListItemIcon>
            <ListItemText
              className="menu-item"
              primary={t('menu.properties.properties')}
            />
            {this.state.properties ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.properties} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink to="/dashboard/properties/add">
                <ListItem button disableGutters className={classes.nested}>
                  <ListItem button disableGutters className={classes.nestedButton}>
                    <ListItemText
                      className="menu-item"
                      primary={t('menu.properties.add-new-property')}
                    />
                  </ListItem>
                </ListItem>
              </NavLink>
              <NavLink to="/dashboard/properties/list">
                <ListItem button disableGutters className={classes.nested}>
                  <ListItem button disableGutters className={classes.nestedButton}>
                    <ListItemText
                      className="menu-item"
                      primary={t('menu.properties.list-properties')}
                    />
                  </ListItem>
                </ListItem>
              </NavLink>
              <NavLink to="/dashboard/amenities/list">
                <ListItem button disableGutters className={classes.nested}>
                  <ListItem button disableGutters className={classes.nestedButton}>
                    <ListItemText
                      className="menu-item"
                      primary={t('menu.properties.amenities')}
                    />
                  </ListItem>
                </ListItem>
              </NavLink>
              <NavLink to="/dashboard/city/list">
                <ListItem disableGutters button className={classes.nested}>
                  <ListItem disableGutters button className={classes.nestedButton}>
                    <ListItemText
                      className="menu-item"
                      primary={t('menu.properties.cities')}
                    />
                  </ListItem>
                </ListItem>
              </NavLink>
              <NavLink to="/dashboard/sector/list">
                <ListItem button disableGutters className={classes.nested}>
                  <ListItem button disableGutters className={classes.nestedButton}>
                    <ListItemText
                      className="menu-item"
                      primary={t('menu.properties.sectors')}
                    />
                  </ListItem>
                </ListItem>
              </NavLink>
              <NavLink to="/dashboard/neighborhood/list">
                <ListItem button disableGutters className={classes.nested}>
                  <ListItem button disableGutters className={classes.nestedButton}>
                    <ListItemText
                      className="menu-item"
                      primary={t('menu.properties.areas')}
                    />
                  </ListItem>
                </ListItem>
              </NavLink>
              <NavLink to="/dashboard/transportation/list">
                <ListItem button disableGutters className={classes.nested}>
                  <ListItem button disableGutters className={classes.nestedButton}>
                    <ListItemText
                      className="menu-item"
                      primary={t('menu.properties.transportations')}
                    />
                  </ListItem>
                </ListItem>
              </NavLink>
              <NavLink to="/dashboard/nearby/list">
                <ListItem button disableGutters className={classes.nested}>
                  <ListItem button disableGutters className={classes.nestedButton}>
                    <ListItemText
                      className="menu-item"
                      primary={t('menu.properties.nearby')}
                    />
                  </ListItem>
                </ListItem>
              </NavLink>
            </List>
          </Collapse>
          {(this.state.scopes.includes('admin') || this.state.scopes.includes('CM')) && (
            <NavLink to="/dashboard/requests/list" className="menu-item">
              <ListItem button>
                <ListItemIcon>
                  <RequestIcon className="menu-icon" />
                </ListItemIcon>
                <ListItemText className="menu-item" primary={t('menu.requests')} />
              </ListItem>
            </NavLink>
          )}
          {(this.state.scopes.includes('admin') || this.state.scopes.includes('CM')) && (
            <NavLink to="/dashboard/agents/list" className={classes.menuItem}>
              <ListItem button>
                <ListItemIcon>
                  <PeopleIcon className="menu-icon" />
                </ListItemIcon>
                <ListItemText className="menu-item" primary={t('menu.agents')} />
              </ListItem>
            </NavLink>
          )}

          {this.state.scopes.includes('admin') && (
            <NavLink to="/dashboard/content-managers/list">
              <ListItem button>
                <ListItemIcon>
                  <ContentManagerIcon className="menu-icon" />
                </ListItemIcon>
                <ListItemText
                  className="menu-item"
                  primary={t('menu.content-managers')}
                />
              </ListItem>
            </NavLink>
          )}
          {(this.state.scopes.includes('admin') || this.state.scopes.includes('CM')) && (
            <NavLink to="/dashboard/articles/list">
              <ListItem button>
                <ListItemIcon>
                  <RssIcon className="menu-icon" />
                </ListItemIcon>
                <ListItemText className="menu-item" primary={t('menu.blog')} />
              </ListItem>
            </NavLink>
          )}
          {this.state.scopes.includes('admin') && (
            <NavLink to="/dashboard/website-users/add">
              <ListItem button>
                <ListItemIcon>
                  <PermIcon className="menu-icon" />
                </ListItemIcon>
                <ListItemText className="menu-item" primary={t('menu.website-users')} />
              </ListItem>
            </NavLink>
          )}

          <NavLink to="/dashboard/profile">
            <ListItem button>
              <ListItemIcon>
                <AccountIcon className="menu-icon" />
              </ListItemIcon>
              <ListItemText className="menu-item" primary={t('menu.account')} />
            </ListItem>
          </NavLink>
        </List>
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(NestedList));
