import React, { useState } from 'react';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable
} from '@dnd-kit/sortable';
import Button from '@material-ui/core/Button';
import { CSS } from '@dnd-kit/utilities';
import { withStyles } from '@material-ui/styles';

// const styles = (theme) => ({
//   button: {
//     backgroundColor: 'transparent',
//     border: '1px solid #000000',
//     color: 'black',
//     margin: theme.spacing(1),
//     boxShadow: 'none',
//     '&:hover': {
//       backgroundColor: 'black',
//       color: '#FFFFFF'
//     }
//   }
// })

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '98%',
    paddingBottom: 12
  }
});

function SortableItem({ id, url, index, item, classes }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id
  });

  const style = {
    width: 140,
    height: 140,
    // padding: 4,
    // padding: 20,
    border: '1px solid',
    transform: CSS.Transform.toString(transform),
    transition,
    marginBottom: 40
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {/* <div style={{ width: 120, height: 120, pointerEvents: 'none' }}> */}
      <img
        // src={names[ind].url}
        src={url}
        style={{ width: 136, height: 136, pointerEvents: 'none', objectFit: 'contain' }}
      />
      <div style={{ marginBottom: 60 }} />

      {/* </div> */}
    </div>
  );
}

function ReorderPhotos({ images, save, close, className, classes }) {
  const [items, setItems] = useState(
    images.map((im) => ({
      ...im,
      id: im.uid
    }))
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((itemsz) => {
        const oldIndex = itemsz.findIndex(({ id }) => id === active.id);
        const newIndex = itemsz.findIndex(({ id }) => id === over.id);

        return arrayMove(itemsz, oldIndex, newIndex);
      });
    }
  }

  function Grid({ children }) {
    return (
      <div
        style={{
          display: 'inline-grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridGap: 10
        }}
      >
        {children}
      </div>
    );
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  return (
    <>
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <Button variant="contained" className={className} onClick={() => save(items)}>
          done
        </Button>
        <div style={{ width: 20, height: 2 }} />
        <Button variant="contained" className={className} onClick={close}>
          cancel
        </Button>
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items.map(({ id }) => id)} strategy={rectSortingStrategy}>
          <Grid>
            {items.map((item, i) => (
              <SortableItem
                id={item.id}
                index={i}
                key={item.id}
                item={item}
                url={item.url || item.thumbUrl}
                classes={classes}
              />
            ))}
          </Grid>
        </SortableContext>
      </DndContext>
    </>
  );
}

export default withStyles(styles, { withTheme: true })(ReorderPhotos);
