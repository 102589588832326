const headers = {
  'Content-Type': 'application/json',
  Authorization: `JWT ${localStorage.getItem('token')}`
};

const axios = require('axios');

const config = {};

config.production = {};
config.production.apiUrl = 'https://api.thebrik.com';
config.production.port = 3005;
config.production.imagesUrl = 'https://api.thebrik.com/static/properties';
config.production.regionImagesUrl = 'https://api.thebrik.com/static/regions';
config.production.articleImagesUrl = 'https://api.thebrik.com/static/articles';
config.production.avatarUrl = 'https://api.thebrik.com/static/agents';
config.production.inlineImagesUrl = 'https://api.thebrik.com/static/tmp';

// config.development = {};
// config.development.apiUrl = 'https://api.thebrik.com';
// config.development.imagesUrl = 'https://api.thebrik.com/static/properties';
// config.development.articleImagesUrl = 'https://api.thebrik.com/static/articles';
// config.development.avatarUrl = 'https://api.thebrik.com/static/agents';
// config.development.inlineImagesUrl = 'https://api.thebrik.com/static/tmp'

config.development = {};
config.development.apiUrl = 'http://localhost:3334';
config.development.imagesUrl = 'http://localhost:3334/static/properties';
config.development.regionImagesUrl = 'http://localhost:3334/static/regions';
config.development.articleImagesUrl = 'http://localhost:3334/static/articles';
config.development.avatarUrl = 'http://localhost:3334/static/agents';
config.development.inlineImagesUrl = 'http://localhost:3334/static/tmp';

// config.development.apiUrl = 'https://api.thebrik.com';
// config.development.port = 3005;
// config.development.imagesUrl = 'https://api.thebrik.com/static/properties';
// config.development.articleImagesUrl = 'https://api.thebrik.com/static/articles';
// config.development.avatarUrl = 'https://api.thebrik.com/static/agents';

const currentConfig = config[process.env.NODE_ENV];

const axiosCallApi = axios.create({
  baseURL: config[process.env.NODE_ENV].apiUrl,
  timeout: 15000
});

const authToken = localStorage.getItem('token');
if (authToken) {
  axiosCallApi.defaults.headers.common.Authorization = `bearer ${authToken}`;
}

module.exports = {
  apiUrl: currentConfig.apiUrl,
  port: currentConfig.port,
  imagesUrl: currentConfig.imagesUrl,
  regionImagesUrl: currentConfig.regionImagesUrl,
  articleImagesUrl: currentConfig.articleImagesUrl,
  inlineImagesUrl: currentConfig.inlineImagesUrl,
  avatarUrl: currentConfig.avatarUrl,
  axiosCallApi,
  headers
};
