import React, { Component } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import i18n from 'i18next';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import { notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { apiUrl, axiosCallApi } from '../../config/config';

const logo = './images/nextcore.png';

const styles = (theme) => ({
  layout: {
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: 'auto',
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(8),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(1)
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%' // Fix IE11 issue.
  },
  submit: {
    marginTop: theme.spacing(3)
  }
});

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agentId: '',
      changedPassword: '',
      email: '',
      id: '',
      name: '',
      password: '',
      showModal: false
    };
  }

  componentDidMount() {
    const language = localStorage.getItem('lng');
    if (!language) {
      localStorage.setItem('lng', i18n.language);
    }
  }

  changeLanguage = () => {
    const language = localStorage.getItem('lng');
    i18n.changeLanguage(language === 'en' ? 'el' : 'en');
    localStorage.setItem('lng', i18n.language);
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleLogin = (e) => {
    const { t } = this.props;
    e.preventDefault();
    const data = {
      email: this.state.email,
      password: this.state.password
    };

    return axios
      .post(`${apiUrl}/admin/login`, data)
      .then(async (response) => {
        if (response.data.forcePassword) {
          this.setState({
            agentId: response.data.agentId,
            id: response.data.id,
            name: response.data.name,
            showModal: true
          });
        } else {
          await localStorage.setItem('token', response.data.token.accessToken);
          await localStorage.setItem('expirationDate', response.data.token.expires_at);
          await localStorage.setItem('lng', response.data.token.language);
          axiosCallApi.defaults.headers.common.Authorization = `JWT ${response.data.token}`;
          notification.success({
            message: t('login.success')
          });
          if (response.data.admin.scopes.includes('admin')) {
            this.props.history.push('dashboard/property-statistics/list');
          } else {
            this.props.history.push('dashboard/properties/list');
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          notification.error({
            message: t('login.error')
          });
        } else {
          notification.error({
            message: t('common.error-fetch')
          });
        }
      });
  };

  submitForcePassword = () => {
    const { t } = this.props;
    const data = {
      agentId: this.state.agentId,
      id: this.state.id,
      password: this.state.changedPassword
    };
    axios
      .post(`${apiUrl}/admin/change-agent-password`, data)
      .then(() => {
        notification.success({
          message: t('login.agent-password-change-success')
        });
        this.setState({ showModal: false });
      })
      .catch(() => {
        notification.error({
          message: t('login.agent-password-change-error')
        });
        this.setState({ showModal: false });
      });
  };

  render() {
    const { classes, t } = this.props;
    return (
      <>
        <CssBaseline />
        <main>
          <div
            className="sign-in-page"
            style={{ background: "url('./images/login-bg.png')" }}
          >
            <div className="sign-in-content-wrapper">
              <div className="sign-in-content">
                <div className="logo-wrapper">
                  <img src={logo} className="logo img-fluid" alt="" />
                </div>
                <form className={classes.form} onSubmit={(e) => this.handleLogin(e)}>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="email">{t('login.email')}</InputLabel>
                    <Input
                      autoComplete="email"
                      autoFocus
                      id="email"
                      inputProps={{ autoCapitalize: 'none' }}
                      name="email"
                      onChange={this.handleChange}
                      type="email"
                    />
                  </FormControl>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="password">{t('login.password')}</InputLabel>
                    <Input
                      autoComplete="current-password"
                      id="password"
                      name="password"
                      onChange={this.handleChange}
                      type="password"
                    />
                  </FormControl>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: 15
                    }}
                  >
                    <Button
                      className="change-lng-button"
                      color="secondary"
                      onClick={this.changeLanguage}
                      variant="contained"
                    >
                      {t('common.language')}
                    </Button>
                    <Button
                      className="sign-in-btn"
                      color="primary"
                      type="submit"
                      variant="raised"
                    >
                      {t('login.sign-in')}
                    </Button>
                  </div>
                </form>
                {/* <div className="border-bottom" /> */}
                {/* <div className="forgot-password-section"> */}
                {/*  <div>Forgot password</div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
          {/* force password modal */}
          <Dialog
            aria-labelledby="form-dialog-title"
            onClose={this.handleClose}
            open={this.state.showModal}
          >
            <DialogTitle id="form-dialog-title">Hello {this.state.name}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('login.first-visit-change-password')}
              </DialogContentText>
              <TextField
                autoFocus
                fullWidth
                id="name"
                label="New Password"
                margin="dense"
                onChange={(e) => this.setState({ changedPassword: e.target.value })}
                type="password"
                value={this.state.changedPassword}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                {t('common.cancel')}
              </Button>
              <Button onClick={this.submitForcePassword} color="primary">
                {t('common.submit')}
              </Button>
            </DialogActions>
          </Dialog>
        </main>
      </>
    );
  }
}

export default withTranslation()(withRouter(withStyles(styles)(SignIn)));
