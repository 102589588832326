import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import Grid from '@material-ui/core/Grid';
import i18n from 'i18next';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { debounce } from 'lodash';
import Select from 'react-select';
import update from 'immutability-helper';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { Icon, message, Modal, notification, Upload } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { apiUrl, axiosCallApi, regionImagesUrl } from '../../config/config';
import { translateName } from '../../utils/utils';
import GoogleMap from '../GoogleMap/GoogleMap';
import ReorderPhotos from '../../utils/ReorderPhotos';
import MyCustomUploadAdapterPlugin from '../UploadAdapter/UploadAdapter';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  button: {
    backgroundColor: 'transparent',
    border: '1px solid #000000',
    color: 'black',
    margin: theme.spacing(1),
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'black',
      color: '#FFFFFF'
    }
  },
  spinner: {
    alignItems: 'center',
    display: 'flex',
    height: '80vh',
    justifyContent: 'center'
  },
  input: {
    display: 'flex',
    height: 'inherit'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%'
  },
  formControlTitle: {
    margin: theme.spacing(1),
    width: '99%'
  },
  formText: {
    margin: theme.spacing(1),
    width: '94%'
  },
  formTextTitle: {
    margin: theme.spacing(1),
    width: '99%',
    marginTop: 20
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(0.25)
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    marginTop: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(2)
  },
  selectControl: {
    position: 'relative',
    width: '100%',
    zIndex: 99999
  },
  paperWrapper: {
    padding: 25,
    boxShadow: 'none'
  }
});

notification.config({
  duration: 3,
  placement: 'topRight',
  top: 24
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          children: props.children,
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      component="div"
      selected={props.isFocused}
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={[
        props.selectProps.classes.paper,
        props.selectProps.classes.selectControl
      ].join(' ')}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

class EditRegion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region: {
        active: true,
        city: null,
        sector: null,
        lat: 37.9988844,
        lng: 23.735316,
        nameEl: '',
        nameEn: '',
        nameZh: '',
        metaTitleEn: '',
        metaTitleEl: '',
        metaTitleZh: '',
        metaDescriptionEn: '',
        metaDescriptionEl: '',
        metaDescriptionZh: '',
        metaKeywordsEl: '',
        metaKeywordsEn: '',
        metaKeywordsZh: '',
        images: [],
        descriptionEl: '',
        descriptionEn: '',
        descriptionZh: '',
        nearBy: [],
        houseTypesMetaData: {
          apartment: {
            titleEl: '',
            metaDescriptionEl: '',
            titleEn: '',
            metaDescriptionEn: ''
          },
          studio: {
            titleEl: '',
            metaDescriptionEl: '',
            titleEn: '',
            metaDescriptionEn: ''
          },
          villa: {
            titleEl: '',
            metaDescriptionEl: '',
            titleEn: '',
            metaDescriptionEn: ''
          },
          maisonette: {
            titleEl: '',
            metaDescriptionEl: '',
            titleEn: '',
            metaDescriptionEn: ''
          },
          detached_house: {
            titleEl: '',
            metaDescriptionEl: '',
            titleEn: '',
            metaDescriptionEn: ''
          }
        }
      },
      fileList: [],
      country: null,
      county: null,
      countries: [],
      countryList: [],
      countyList: [],
      citiesList: [],
      sectorList: [],
      nearByList: [],
      submitBtnIsDisabled: false,
      editFileList: false
    };
  }

  async componentDidMount() {
    const { match, t } = this.props;

    try {
      const fetchCountries = await axiosCallApi.get(`${apiUrl}/city/countries/all`);
      const { countries } = fetchCountries.data;

      const countryList = countries.map((c) => ({ label: c.labelEl, value: c.value }));

      const fetchRegion = await axiosCallApi.get(`${apiUrl}/region/${match.params.id}`);
      const { region } = fetchRegion.data;

      region.city = {
        ...region.city,
        label: region.city.nameEl,
        value: region.city.nameEl
        // _id: sector.city._id,
      };

      if (region.sector && region.sector !== undefined) {
        region.sector = {
          ...region.sector,
          label: region.sector.nameEl,
          value: region.sector.nameEl
          // _id: sector.city._id,
        };
      }

      // this.setState({ countryList, region })
      let countyList = [];
      let countryIndex;
      let countyIndex;
      for (let i = 0; i < countries.length; i++) {
        if (countries[i].value === region.city.country.value) {
          countryIndex = i;
          countyList = countries[i].counties.map((c) => ({
            label: c.labelEl,
            value: c.value
          }));

          countyIndex = countries[i].counties.findIndex(
            (cy) => cy.value === region.city.county.value
          );

          break;
        }
      }

      const fetchCities = await axiosCallApi.get(
        `${apiUrl}/city/counties/${region.city.country.value}/${countries[countryIndex].counties[countyIndex].value}`
      );
      const cityList = fetchCities.data.cities.map((c) => ({
        label: c.nameEl,
        value: c.value,
        _id: c._id
      }));

      const fetchSectors = await axiosCallApi.get(
        `${apiUrl}/region/cities/${region.city._id}`
      );
      const sectorList = fetchSectors.data.sectors.map((s) => ({
        label: s.nameEl,
        value: s.value,
        _id: s._id
      }));

      const imageList = region.images.map((image) => ({
        isOld: true,
        name: image.image.name,
        status: 'done',
        type: 'original',
        uid: image.image.uid,
        url: `${regionImagesUrl}/${region._id}/${image.image.name}`
      }));

      axiosCallApi
        .get(`${apiUrl}/nearby/names`)
        .then((response) => {
          const regionNearBy = [];
          const newNearbys = response.data.map((el) => {
            for (let i = 0; i < region.nearBy.length; i++) {
              if (region.nearBy[i]._id === el._id) {
                regionNearBy.push({
                  label: translateName(i18n.language, el),
                  value: el._id
                });
                break;
              }
            }
            // if (region.nearBy.includes(el._id)) {
            //   regionNearBy.push({
            //     label: translateName(i18n.language, el),
            //     value: el._id
            //   });
            // }
            return {
              label: translateName(i18n.language, el),
              value: el._id
            };
          });

          this.setState(
            update(this.state, {
              nearByList: {
                $set: newNearbys
              },
              region: {
                nearBy: {
                  $set: regionNearBy
                }
              }
            })
          );
        })
        .catch((err) => {
          console.log(err);
          notification.error({
            message: t('common.no-poi-found')
          });
        });

      this.setState({
        countries,
        countryList,
        region,
        country: region.city.country,
        county: region.city.county,
        countyList,
        cityList,
        sectorList,
        fileList: imageList,
        loading: false
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: t('common.error-fetch')
      });
    }
  }

  onInputChange = (e) => {
    this.setState(
      update(this.state, {
        region: {
          [e.target.name]: {
            $set: e.target.value
          }
        }
      })
    );
  };

  onMetaChange = (e) => {
    this.setState(
      update(this.state, {
        region: {
          houseTypesMetaData: {
            [e.target.id]: {
              [e.target.name]: {
                $set: e.target.value
              }
            }
          }
        }
      })
    );
  };

  submit = (e) => {
    e.preventDefault();
    const data = this.state.region;
    const { match, t } = this.props;
    axiosCallApi
      .patch(`${apiUrl}/region/single/${match.params.id}`, data)
      .then(() => {
        notification.success({
          message: t('areas.edit.update-success')
        });
        this.props.history.push('/dashboard/neighborhood/list');
      })
      .catch(() => {
        notification.error({
          message: t('areas.edit.update-error')
        });
      });
  };

  deleteRegion = () => {
    const { match, t } = this.props;
    axiosCallApi
      .delete(`${apiUrl}/region/single/${match.params.id}`)
      .then(() => {
        notification.success({
          message: t('areas.edit.delete-success')
        });
        this.handleDeleteClose();
        this.props.history.push('/dashboard/neighborhood/list');
      })
      .catch(() => {
        notification.error({
          message: t('areas.edit.delete-error')
        });
      });
  };

  handleDeleteOpen = () => {
    this.setState({ deleteDialog: true });
  };

  handleDeleteClose = () => {
    this.setState({ deleteDialog: false });
  };

  // eslint-disable-next-line
  changeDescriptionEnDebounced = debounce(
    (input) => this.handleDescriptionChangeEn(input),
    2000,
    { maxWait: 2000 }
  );

  changeDescriptionElDebounced = debounce(
    (input) => this.handleDescriptionChangeEl(input),
    2000,
    { maxWait: 2000 }
  );

  changeDescriptionZhDebounced = debounce(
    (input) => this.handleDescriptionChangeZh(input),
    2000,
    { maxWait: 2000 }
  );

  handleDescriptionChangeEn = (value) => {
    this.setState(
      update(this.state, {
        region: {
          descriptionEn: { $set: value }
        }
      })
    );
  };

  handleDescriptionChangeEl = (value) => {
    this.setState(
      update(this.state, {
        region: {
          descriptionEl: { $set: value }
        }
      })
    );
  };

  handleDescriptionChangeZh = (value) => {
    this.setState(
      update(this.state, {
        region: {
          descriptionZh: { $set: value }
        }
      })
    );
  };

  onCheckBoxActiveChange = (event) => {
    this.setState(
      update(this.state, {
        region: {
          active: { $set: event.target.checked }
        }
      })
    );
  };

  handleSelectChangeCountry = (country) => {
    if (
      this.state.country &&
      this.state.country.value !== undefined &&
      country.value === this.state.country.value
    ) {
      return;
    }

    const { countries } = this.state;
    for (let i = 0; i < countries.length; i++) {
      if (countries[i].value === country.value) {
        const countyList = countries[i].counties.map((c) => ({
          label: c.labelEl,
          value: c.value
        }));
        this.setState(
          update(this.state, {
            region: {
              city: { $set: null }
            },
            country: { $set: country },
            county: { $set: null },
            countyList: { $set: countyList }
          })
        );
        return;
      }
    }
  };

  handleSelectChangeCounty = (county) => {
    const { t } = this.props;

    if (
      this.state.county &&
      this.state.county.value !== undefined &&
      county.value === this.state.county.value
    ) {
      return;
    }

    axiosCallApi
      .get(`${apiUrl}/city/counties/${this.state.country.value}/${county.value}`)
      .then((response) => {
        const { cities } = response.data;
        const cityList = cities.map((c) => ({
          label: c.nameEl,
          value: c.value,
          _id: c._id
        }));
        this.setState(
          update(this.state, {
            region: {
              city: { $set: null },
              sector: { $set: null }
            },
            county: { $set: county },
            cityList: { $set: cityList },
            sectorList: { $set: [] }
          })
        );
      })
      .catch((error) => {
        console.log(error);
        notification.error({
          message: t('common.error-fetch')
        });
      });
  };

  handleSelectChangeCity = (city) => {
    const { t } = this.props;

    if (
      this.state.region.city &&
      this.state.region.city.value !== undefined &&
      city.value === this.state.region.city.value
    ) {
      return;
    }

    axiosCallApi
      .get(`${apiUrl}/region/cities/${city._id}`)
      .then((response) => {
        const { sectors } = response.data;
        const sectorList = sectors.map((c) => ({
          label: c.nameEl,
          value: c.value,
          _id: c._id
        }));
        this.setState(
          update(this.state, {
            region: {
              city: { $set: city },
              sector: { $set: null }
            },
            sectorList: { $set: sectorList }
          })
        );
      })
      .catch((error) => {
        console.log(error);
        notification.error({
          message: t('common.error-fetch')
        });
      });
  };

  handleSelectChangeSector = (sector) => {
    //
    // if (this.state.region.sector && this.state.region.sector.value !== undefined &&
    //     sector.value === this.state.region.sector.value
    //   ) {
    //   return
    // }

    this.setState(
      update(this.state, {
        region: {
          sector: { $set: sector }
        }
      })
    );
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handleChangeImage = ({ fileList }) => {
    this.setState({ fileList });
  };

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  removeImage = (file) => {
    const { t } = this.props;
    const imgName = file.response ? file.response.data.image.name : file.name;
    axiosCallApi
      .delete(`${apiUrl}/uploads/delete/temp/single/${imgName}`)
      .then(() => {
        this.setState(
          update(this.state, {
            region: {
              images: (images) => images.filter((item) => item.image.name !== imgName)
            }
          })
        );
        notification.success({
          message: `${t('image-upload.image-delete.part1')} ${file.name} ${t(
            'image-upload.image-delete.part2'
          )} `
        });
      })
      .catch(() => message.error(`${t('image-upload.image-error')} ${file.name}`));
  };

  beforeUpload = (file) => {
    const { t } = this.props;
    return new Promise((resolve, reject) => {
      if (
        file.type !== 'image/jpeg' &&
        file.type !== 'image/png' &&
        file.type !== 'image/jpg'
      ) {
        notification.error({ message: t('image-upload.unsupported-files') });
        reject(file);
      } else if (file.size / 1024 / 1024 > 5) {
        notification.error({ message: t('image-upload.image-file-size') });
        reject(file);
      } else {
        resolve(file);
      }
    });
  };

  handleUpload = ({ ...props }) => {
    const { t } = this.props;
    if (this.state.fileList.length > 30) {
      return message.error(
        `${t('image-upload.max-files-reached.part1')} ${t(
          'image-upload.max-files-reached.part4'
        )} ${t('image-upload.max-files-reached.part5')}`
      );
    }
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append('file', props.file, props.file.name);
    axiosCallApi
      .post(`${apiUrl}/uploads/regions/new`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: ({ total, loaded }) => {
          props.onProgress(
            { percent: Math.round((loaded / total) * 100).toFixed(2) },
            props.file
          );
        }
      })
      .then((response) => {
        props.onSuccess(response, props.file);
        notification.success({
          message: ` ${t('image-upload.image-success')}  ${props.file.name}`
        });
        this.setState(
          update(this.state, {
            region: {
              images: {
                $push: [response.data]
              }
            }
          }),
          () => {}
        );
      })
      .catch(() => {
        message.error(`${t('image-upload.image-error')}  ${props.file.name}`);
        return props.onError;
      });
    return {
      abort() {
        message.error(`${t('image-upload.image-stop')}  ${props.file.name}`);
      }
    };
  };

  // moveImage = (left, i) => {
  //
  //   const { fileList } = this.state
  //   const { images } = this.state.region
  //
  //   const newFileList = [...fileList]
  //   const newImages = [...images]
  //
  //   if (left) {
  //     const it = newFileList[i];
  //     newFileList[i] = newFileList[i-1];
  //     newFileList[i-1] = it;
  //     const it2 = newImages[i];
  //     newImages[i] = newImages[i-1];
  //     newImages[i-1] = it2;
  //   }
  //   else {
  //     const it = newFileList[i];
  //     newFileList[i] = newFileList[i+1];
  //     newFileList[i+1] = it;
  //     const it2 = newImages[i];
  //     newImages[i] = newImages[i+1];
  //     newImages[i+1] = it2;
  //   }
  //
  //   this.setState(
  //     update(this.state, {
  //       fileList: { $set: newFileList },
  //       region: {
  //         images: {
  //           $set: newImages
  //         }
  //       }
  //     })
  //   )
  //
  // }

  handleNearByMultipleChange = (e) => {
    this.setState(
      update(this.state, {
        region: { nearBy: { $set: e } }
      })
    );
  };

  onMapClick = (lat, lng) => {
    this.setState(
      update(this.state, {
        region: {
          lat: { $set: lat },
          lng: { $set: lng }
        }
      })
    );
  };

  savePhotosOrder = (photos) => {
    const newImages = photos.map((im) =>
      this.state.region.images.find((pi) => pi.image.uid === im.id)
    );

    // newImages = newImages.map(im => {
    //   return {
    //     ...im,
    //     uid: id
    //   }
    // })

    this.setState(
      update(this.state, {
        fileList: { $set: photos },
        editFileList: { $set: false },
        region: {
          images: {
            $set: newImages
          }
        }
      })
    );
  };

  render() {
    const { classes, theme, t } = this.props;
    const { region, previewImage, previewVisible } = this.state;
    const { houseTypesMetaData } = region;

    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary
      })
    };

    return (
      <div>
        <Paper className={classes.paperWrapper}>
          <h1 className="title-wrapper">{t('areas.edit.edit-area')}</h1>
          <form onSubmit={(e) => this.submit(e)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    value={this.state.country}
                    isSearchable
                    // name="country"
                    onChange={this.handleSelectChangeCountry}
                    options={this.state.countryList}
                    placeholder={t('common.country')}
                    required
                    styles={selectStyles}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    value={this.state.county}
                    isSearchable
                    // name="city"
                    onChange={this.handleSelectChangeCounty}
                    options={this.state.countyList}
                    placeholder={t('common.county')}
                    required
                    styles={selectStyles}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    value={region.city}
                    isSearchable
                    // name="city"
                    onChange={this.handleSelectChangeCity}
                    options={this.state.cityList}
                    placeholder={t('common.city')}
                    required
                    styles={selectStyles}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    value={region.sector}
                    isSearchable
                    isClearable
                    // name="city"
                    onChange={this.handleSelectChangeSector}
                    options={this.state.sectorList}
                    placeholder={t('common.sector')}
                    required
                    styles={selectStyles}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    // className="basic-multi-select"
                    classNamePrefix="Περιοχές"
                    components={components}
                    isMulti
                    onChange={this.handleNearByMultipleChange}
                    options={this.state.nearByList}
                    placeholder={t('add-new-property.property.nearby')}
                    styles={selectStyles}
                    value={region.nearBy}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="nameEl"
                    label="Όνομα"
                    name="nameEl"
                    onChange={this.onInputChange}
                    required
                    type="text"
                    value={region.nameEl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="nameEn"
                    label="Name"
                    name="nameEn"
                    onChange={this.onInputChange}
                    type="text"
                    value={region.nameEn}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="nameZh"
                    label="名称"
                    name="nameZh"
                    onChange={this.onInputChange}
                    type="text"
                    value={region.nameZn}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <FormControl className={classes.formControl}>
                <FormLabel component="legend">{t('common.status')}</FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={region.active}
                      className="customCheckbox"
                      name="active"
                      onChange={this.onCheckBoxActiveChange}
                    />
                  }
                  label={
                    region.active
                      ? t('areas.list.area-active')
                      : t('areas.list.area-inactive')
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Card style={{ padding: 15, marginTop: 25 }}>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl className={classes.formControlTitle}>
                    <TextField
                      fullWidth
                      id="metaTitleEl"
                      label="Meta Title Greek"
                      name="metaTitleEl"
                      onChange={this.onInputChange}
                      value={region.metaTitleEl}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlTitle}>
                    <TextField
                      fullWidth
                      id="metaDescriptionEl"
                      label="Meta Description Greek"
                      name="metaDescriptionEl"
                      onChange={this.onInputChange}
                      value={region.metaDescriptionEl}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlTitle}>
                    <TextField
                      fullWidth
                      id="metaKeywordsEl"
                      label="Meta Keywords Greek"
                      name="metaKeywordsEl"
                      onChange={this.onInputChange}
                      value={region.metaKeywordsEl}
                    />
                  </FormControl>
                  <Typography variant="h6" gutterBottom>
                    {t('add-new-property.property.area-description-el')}
                  </Typography>
                  <FormControl className={classes.formTextTitle}>
                    <FormLabel component="legend" style={{ marginBottom: 16 }}>
                      {t('add-new-property.property.description-el')}
                    </FormLabel>
                    <CKEditor
                      config={{ extraPlugins: [MyCustomUploadAdapterPlugin] }}
                      data={region.descriptionEl}
                      editor={ClassicEditor}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        this.changeDescriptionElDebounced(data);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Card>
              <Card style={{ padding: 15, marginTop: 25 }}>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl className={classes.formControlTitle}>
                    <TextField
                      fullWidth
                      id="metaTitleEn"
                      label="Meta Title English"
                      name="metaTitleEn"
                      onChange={this.onInputChange}
                      value={region.metaTitleEn}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlTitle}>
                    <TextField
                      fullWidth
                      id="metaDescriptionEn"
                      label="Meta Description English"
                      name="metaDescriptionEn"
                      onChange={this.onInputChange}
                      value={region.metaDescriptionEn}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlTitle}>
                    <TextField
                      fullWidth
                      id="metaKeywordsEn"
                      label="Meta Keywords English"
                      name="metaKeywordsEn"
                      onChange={this.onInputChange}
                      value={region.metaKeywordsEn}
                    />
                  </FormControl>
                  <Typography variant="h6" gutterBottom>
                    {t('add-new-property.property.area-description-en')}
                  </Typography>
                  <FormControl className={classes.formTextTitle}>
                    <FormLabel component="legend" style={{ marginBottom: 16 }}>
                      {t('add-new-property.property.description-en')}
                    </FormLabel>
                    <CKEditor
                      config={{ extraPlugins: [MyCustomUploadAdapterPlugin] }}
                      data={region.descriptionEn}
                      editor={ClassicEditor}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        this.changeDescriptionEnDebounced(data);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Card>
              <Card style={{ padding: 15, marginTop: 25, marginBottom: 25 }}>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl className={classes.formControlTitle}>
                    <TextField
                      fullWidth
                      id="metaTitleZh"
                      label="Meta Title Chinese"
                      name="metaTitleZh"
                      onChange={this.onInputChange}
                      value={region.metaTitleZh}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlTitle}>
                    <TextField
                      fullWidth
                      id="metaDescriptionZh"
                      label="Meta Description Chinese"
                      name="metaDescriptionZh"
                      onChange={this.onInputChange}
                      value={region.metaDescriptionZh}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlTitle}>
                    <TextField
                      fullWidth
                      id="metaKeywordsZh"
                      label="Meta Keywords Chinese"
                      name="metaKeywordsZh"
                      onChange={this.onInputChange}
                      value={region.metaKeywordsZh}
                    />
                  </FormControl>
                  <Typography variant="h6" gutterBottom>
                    {t('add-new-property.property.area-description-zh')}
                  </Typography>
                  <FormControl className={classes.formTextTitle}>
                    <FormLabel component="legend" style={{ marginBottom: 16 }}>
                      {t('add-new-property.property.description-zh')}
                    </FormLabel>
                    <CKEditor
                      config={{ extraPlugins: [MyCustomUploadAdapterPlugin] }}
                      data={region.descriptionZh}
                      editor={ClassicEditor}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        this.changeDescriptionZhDebounced(data);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Card>
            </Grid>

            <Grid container style={{ marginTop: 35 }}>
              <Grid item xs={12} sm={6} md={12}>
                <h1 className="title-wrapper">
                  <span>{t('add-new-property.location.info')}</span>
                </h1>
              </Grid>
              <div style={{ height: 400, width: '100%' }}>
                <GoogleMap
                  center={[region.fakeLat, region.fakeLng]}
                  lat={region.lat}
                  lng={region.lng}
                  name={region.title}
                  onMapClick={this.onMapClick}
                />
              </div>
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl className={classes.formControl}>
                <FormLabel component="legend">{t('cities.add-new.images')}</FormLabel>
                {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 4, marginBottom: 8 }}>
                  {fileList.map((f, i) => (
                    f && i !== -5 &&
                    <div key={i.toString()} style={{ width: 112, paddingRight: 4, display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                      {i !== fileList.length - 1 && i !== 0 ?
                        <>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px 5px', cursor: 'pointer' }} onClick={() => this.moveImage(true, i)}>
                            <ArrowLeft />
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px 5px', cursor: 'pointer' }} onClick={() => this.moveImage(false, i)}>
                            <ArrowRight />
                          </div>
                        </>
                      : i !== 0 ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px 5px', cursor: 'pointer' }} onClick={() => this.moveImage(true, i)}>
                          <ArrowLeft />
                        </div>
                      : i !== fileList.length - 1 ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3px 5px', cursor: 'pointer' }} onClick={() => this.moveImage(false, i)}>
                          <ArrowRight />
                        </div>
                      : null
                      }
                    </div>
                  ))}
                </div> */}

                {!this.state.editFileList && this.state.fileList.length > 1 && (
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={() => this.setState({ editFileList: true })}
                    style={{ marginBottom: 16 }}
                  >
                    edit
                  </Button>
                )}

                {this.state.editFileList ? (
                  <ReorderPhotos
                    className={classes.button}
                    images={this.state.fileList}
                    save={this.savePhotosOrder}
                    close={() => this.setState({ editFileList: false })}
                  />
                ) : (
                  <Upload
                    beforeUpload={this.beforeUpload}
                    customRequest={this.handleUpload}
                    fileList={this.state.fileList}
                    multiple
                    listType="picture-card"
                    onChange={this.handleChangeImage}
                    onPreview={this.handlePreview}
                    onRemove={(file) => this.removeImage(file)}
                  >
                    <div>
                      <Icon type="plus" />
                      <div className="ant-upload-text">
                        {t('add-new-property.property.upload-images')}
                      </div>
                    </div>
                  </Upload>
                )}
                <Modal
                  footer={null}
                  onCancel={this.handleCancel}
                  visible={previewVisible}
                >
                  <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
              </FormControl>
            </Grid>
            <Grid container style={{ marginTop: 35 }}>
              <h1 className="title-wrapper">
                <span>House types SEO Metadata</span>
              </h1>
            </Grid>
            <Grid container>
              <FormLabel
                component="legend"
                style={{ marginBottom: 16, fontWeight: 'bold' }}
              >
                Apartment
              </FormLabel>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="apartment"
                    label="Title Greek"
                    name="titleEl"
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.apartment.titleEl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="apartment"
                    label="Meta Description Greek"
                    name="metaDescriptionEl"
                    multiline
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.apartment.metaDescriptionEl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="apartment"
                    label="Title English"
                    name="titleEn"
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.apartment.titleEn}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="apartment"
                    label="Meta Description English"
                    name="metaDescriptionEn"
                    multiline
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.apartment.metaDescriptionEn}
                  />
                </FormControl>
              </Grid>
              <FormLabel
                component="legend"
                style={{ marginBottom: 16, marginTop: 24, fontWeight: 'bold' }}
              >
                Studio
              </FormLabel>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="studio"
                    label="Title Greek"
                    name="titleEl"
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.studio.titleEl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="studio"
                    label="Meta Description Greek"
                    name="metaDescriptionEl"
                    multiline
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.studio.metaDescriptionEl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="studio"
                    label="Title English"
                    name="titleEn"
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.studio.titleEn}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="studio"
                    label="Meta Description English"
                    name="metaDescriptionEn"
                    multiline
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.studio.metaDescriptionEn}
                  />
                </FormControl>
              </Grid>
              <FormLabel
                component="legend"
                style={{ marginBottom: 16, marginTop: 24, fontWeight: 'bold' }}
              >
                Maisonette
              </FormLabel>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="maisonette"
                    label="Title Greek"
                    name="titleEl"
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.maisonette.titleEl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="maisonette"
                    label="Meta Description Greek"
                    name="metaDescriptionEl"
                    multiline
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.maisonette.metaDescriptionEl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="maisonette"
                    label="Title English"
                    name="titleEn"
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.maisonette.titleEn}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="maisonette"
                    label="Meta Description English"
                    name="metaDescriptionEn"
                    multiline
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.maisonette.metaDescriptionEn}
                  />
                </FormControl>
              </Grid>
              <FormLabel
                component="legend"
                style={{ marginBottom: 16, marginTop: 24, fontWeight: 'bold' }}
              >
                Villa
              </FormLabel>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="villa"
                    label="Title Greek"
                    name="titleEl"
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.villa.titleEl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="villa"
                    label="Meta Description Greek"
                    name="metaDescriptionEl"
                    multiline
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.villa.metaDescriptionEl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="villa"
                    label="Title English"
                    name="titleEn"
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.villa.titleEn}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="villa"
                    label="Meta Description English"
                    name="metaDescriptionEn"
                    multiline
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.villa.metaDescriptionEn}
                  />
                </FormControl>
              </Grid>
              <FormLabel
                component="legend"
                style={{ marginBottom: 16, marginTop: 24, fontWeight: 'bold' }}
              >
                Detached House
              </FormLabel>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="detached_house"
                    label="Title Greek"
                    name="titleEl"
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.detached_house.titleEl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="detached_house"
                    label="Meta Description Greek"
                    multiline
                    name="metaDescriptionEl"
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.detached_house.metaDescriptionEl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="detached_house"
                    label="Title English"
                    name="titleEn"
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.detached_house.titleEn}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6} style={{ marginBottom: 24 }}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="detached_house"
                    label="Meta Description English"
                    name="metaDescriptionEn"
                    onChange={this.onMetaChange}
                    type="text"
                    value={houseTypesMetaData.detached_house.metaDescriptionEn}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={10}>
              <Grid item xs={12}>
                <Button className={classes.button} type="submit" variant="contained">
                  {t('common.submit')}
                </Button>
                <Button
                  className="secondary rightButton deleteButton"
                  onClick={() => this.handleDeleteOpen()}
                  variant="contained"
                >
                  {t('areas.edit.delete')}
                </Button>
              </Grid>
            </Grid>
          </form>
          <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            onClose={this.handleDeleteClose}
            open={this.state.deleteDialog}
          >
            <DialogTitle style={{ background: 'red' }} id="alert-dialog-title">
              <span style={{ color: 'white' }}>
                {t('areas.edit.are-you-sure')}
                <em>{region.nameEl}</em>;
              </span>
            </DialogTitle>
            <DialogContent style={{ background: 'red', color: 'white' }}>
              <DialogContentText
                style={{ background: 'red', color: 'white' }}
                id="alert-dialog-description"
              >
                <strong>{t('areas.edit.attention')}</strong>
                {t('areas.edit.cannot-revert')}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                background: 'red',
                margin: 0,
                paddingBottom: 8,
                paddingRight: 4,
                paddingTop: 8
              }}
            >
              <Button autoFocus onClick={this.handleDeleteClose} style={{ outline: 0 }}>
                {t('common.cancel')}
              </Button>
              <Button
                style={{ outline: 0, color: 'white' }}
                onClick={() => this.deleteRegion(this.props.match.params.id)}
              >
                {t('areas.edit.delete')}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(withStyles(styles, { withTheme: true })(EditRegion))
);
