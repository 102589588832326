import AmenitiesList from '../components/Amenities/AmenitiesList';
import AddNewAmenity from '../components/Amenities/AddNewAmenity';
import EditAmenity from '../components/Amenities/EditAmenity';

const amenitiesRoutes = [
  {
    path: '/dashboard/amenities/list',
    exact: false,
    name: 'List Amenities',
    component: AmenitiesList
  },
  {
    path: '/dashboard/amenities/add',
    exact: false,
    name: 'Add Amenity',
    component: AddNewAmenity
  },
  {
    path: '/dashboard/amenities/edit/:id',
    exact: false,
    name: 'Edit Amenity',
    component: EditAmenity
  }
];

export default amenitiesRoutes;
