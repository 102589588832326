import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import debounce from 'lodash.debounce';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import {
  CustomPaging,
  DataTypeProvider,
  PagingState,
  SearchState,
  SortingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  SearchPanel,
  Table,
  TableHeaderRow,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui';
import { Link, withRouter } from 'react-router-dom';
import { notification, Tag } from 'antd';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { Loading } from '../UI-Elements/Loading';
import { apiUrl, avatarUrl, axiosCallApi } from '../../config/config';
import RequestListModal from './RequestListModal';
import PropertiesListModal from './PropertiesListModal';
import Image from '../sharedComponents/Image';
import * as actions from '../../redux/actions';

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  paperWrapper: {
    boxShadow: 'none'
  }
});

function PhoneFormatter({ value }) {
  return <a href={`tel:${value}`}>{value}</a>;
}

function PhoneTypeProvider(props) {
  return <DataTypeProvider formatterComponent={PhoneFormatter} {...props} />;
}

function EmailFormatter({ value }) {
  return <a href={`mailto:${value}`}>{value}</a>;
}

function EmailTypeProvider(props) {
  return <DataTypeProvider formatterComponent={EmailFormatter} {...props} />;
}

function RequestFormatter({ value, row, requestsModal }) {
  return (
    <Tag
      className="tag-hover"
      onClick={() => (row.requests.length > 0 ? requestsModal(row) : null)}
      color={value === '0' ? '#f50' : '#2db7f5'}
    >
      {value}
    </Tag>
  );
}

function RequestProvider(props) {
  return <DataTypeProvider formatterComponent={RequestFormatter} {...props} />;
}

function PropertiesFormatter({ value, row, propertiesModal }) {
  return (
    <Tag
      className="tag-hover"
      onClick={() => (row.properties.length > 0 ? propertiesModal(row) : null)}
      color={value === '0' ? '#f50' : '#2db7f5'}
    >
      {value}
    </Tag>
  );
}

function PropertiesProvider(props) {
  return <DataTypeProvider formatterComponent={PropertiesFormatter} {...props} />;
}

function ImageFormatter({ value, row }) {
  return (
    <Image
      image={
        row.image
          ? `${avatarUrl}/${row.uid}/${value}`
          : `${avatarUrl}/dummy_agent_round.png`
      }
      styles="list-images"
    />
  );
}

function ImageTypeProvider(props) {
  return <DataTypeProvider formatterComponent={ImageFormatter} {...props} />;
}

function EditButton({ row, history }) {
  return (
    <IconButton
      onClick={() => {
        history.push(`/dashboard/agents/edit/${row._id}`);
      }}
      title="Edit row"
    >
      <EditIcon />
    </IconButton>
  );
}

function Cell({ column, history, propertiesModal, requestsModal, row, style, value }) {
  let content = value;
  if (column.name === 'edit') {
    content = <EditButton row={row} history={history} />;
  }
  if (column.name === 'image') {
    content = <ImageFormatter row={row} value={value} />;
  }
  if (column.name === 'email') {
    content = <EmailFormatter row={row} value={value} />;
  }
  if (column.name === 'phone') {
    content = <PhoneFormatter row={row} value={value} />;
  }
  if (column.name === 'requests') {
    content = <RequestFormatter row={row} value={value} requestsModal={requestsModal} />;
  }
  if (column.name === 'mobilePhone') {
    content = <PhoneFormatter row={row} value={value} />;
  }
  if (column.name === 'props') {
    content = (
      <PropertiesFormatter row={row} value={value} propertiesModal={propertiesModal} />
    );
  }
  return <Table.Cell style={style}>{content}</Table.Cell>;
}

class AgentsList extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      columns: [
        {
          name: 'image',
          title: t('common.picture'),
          getCellValue: (row) => row.image
        },
        {
          name: 'name',
          title: t('common.name'),
          getCellValue: (row) => row.name
        },
        {
          name: 'surname',
          title: t('common.last-name'),
          getCellValue: (row) => row.surname
        },
        {
          name: 'phone',
          title: t('common.phone'),
          getCellValue: (row) => row.phone
        },
        {
          name: 'mobilePhone',
          title: t('common.mobile'),
          getCellValue: (row) => row.mobilePhone
        },
        {
          name: 'email',
          title: t('common.email'),
          getCellValue: (row) => row.email
        },
        {
          name: 'props',
          title: t('agents.list.total-properties'),
          getCellValue: (row) =>
            row.properties.length === 0 ? '0' : row.properties.length
        },
        {
          name: 'requests',
          title: t('agents.list.total-requests'),
          getCellValue: (row) => (row.requests.length === 0 ? '0' : row.requests.length)
        },
        { name: 'edit', title: t('agents.list.edit') }
      ],
      tableColumnExtensions: [
        { columnName: 'active', align: 'center' },
        { columnName: 'requests', align: 'center' },
        { columnName: 'props', align: 'center' }
      ],
      sortingStateColumnExtensions: [{ columnName: 'edit', sortingEnabled: false }],
      currentPage: 0,
      emailColumns: ['email'],
      filter: '',
      imageColumns: ['image'],
      loading: true,
      mobileColumns: ['mobile'],
      open: false,
      pageSize: 15,
      pageSizes: [5, 10, 15, 25, 50, 100],
      phoneColumns: ['telephone'],
      propertiesKey: 0,
      propertiesModal: false,
      propsColumns: ['props'],
      requestColumns: ['requests'],
      requestsKey: 1000,
      requestsModal: false,
      rows: [],
      sorting: [{ columnName: 'createdAt', direction: 'desc' }],
      totalCount: 0
    };
    this.loadDataDebounced = debounce(() => {
      this.loadData();
    }, 600);
  }

  componentDidMount() {
    this.loadDataDebounced();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.sorting !== prevState.sorting) {
      this.props.onCurrentPageChange(0);
    }
    if (
      this.props.currentPage !== prevProps.currentPage ||
      this.props.pageSize !== prevProps.pageSize ||
      this.state.filter !== prevState.filter ||
      this.state.sorting !== prevState.sorting
    ) {
      this.loadData();
    }
  }

  changeSorting = (sorting) => {
    this.setState({
      loading: true,
      sorting
    });
  };

  changePageSize = (pageSize) => {
    const { totalCount } = this.state;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.min(this.props.currentPage, totalPages - 1);

    this.setState(
      {
        loading: true
      },
      () => {
        this.props.onPageSizeChange(pageSize);
        this.props.onCurrentPageChange(currentPage);
      }
    );
  };

  changeCurrentPage = (currentPage) => {
    this.setState(
      {
        loading: true
      },
      () => this.props.onCurrentPageChange(currentPage)
    );
  };

  changeSearchValue = (searchValue) => {
    this.setState(
      {
        loading: true,
        filter: searchValue
      },
      () => this.props.onCurrentPageChange(0)
    );
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  showModal = (row) => {
    const { rows } = this.state;
    const index = rows.findIndex((item) => item._id === row._id);
    const data = {
      read: true
    };

    this.setState({
      selectedRow: row
    });

    this.setState(
      update(this.state, {
        selectedRow: { $set: row },
        rows: {
          [index]: {
            read: { $set: true }
          }
        }
      })
    );

    this.handleClickOpenRequest();
    if (!rows[index].read) {
      axiosCallApi
        .patch(`${apiUrl}/request/status/${row._id}`, data)
        .catch((error) => console.log(error));
    }
  };

  showRequestsModal = (row) => {
    this.setState({
      requestsKey: this.state.requestsKey + 1,
      requestsModal: true,
      selectedRow: row
    });
  };

  closeRequestsModal = () => {
    this.setState({ requestsModal: false, selectedRow: '' });
  };

  showPropertiesModal = (row) => {
    this.setState({
      propertiesKey: this.state.propertiesKey + 1,
      propertiesModal: true,
      selectedRow: row
    });
  };

  closePropertiesModal = () => {
    this.setState({ propertiesModal: false, selectedRow: '' });
  };

  deleteRequest = (id) => {
    this.setState(
      update(this.state, {
        rows: (items) =>
          items.map((item) => {
            item.requests = item.requests.filter((req) => id !== req);
            return item;
          })
      })
    );
  };

  queryString() {
    const { sorting, filter } = this.state;
    const { pageSize, currentPage } = this.props;
    let queryString = `${apiUrl}/agent/all/?perPage=${pageSize}&skip=${currentPage}&filter=${filter}`;
    const columnSorting = sorting[0];
    if (columnSorting) {
      const sortingDirectionString = columnSorting.direction === 'desc' ? 1 : -1;
      queryString = `${queryString}&orderby=${columnSorting.columnName}`;
      queryString = `${queryString}&sort=${sortingDirectionString}`;
    }
    return queryString;
  }

  loadData() {
    const { t } = this.props;
    const queryString = this.queryString();
    if (queryString === this.lastQuery) {
      return;
    }
    axiosCallApi
      .get(queryString)
      .then((response) => {
        this.setState({
          rows: response.data.agents,
          totalCount: response.data.count,
          loading: false
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        notification.error({
          message: t('common.error-fetch')
        });
      });
    this.lastQuery = queryString;
  }

  render() {
    const { classes, currentPage, history, pageSize, pageSizes, t } = this.props;
    const {
      columns,
      emailColumns,
      filter,
      imageColumns,
      loading,
      mobileColumns,
      phoneColumns,
      propertiesKey,
      propertiesModal,
      propsColumns,
      requestColumns,
      requestsKey,
      requestsModal,
      rows,
      selectedRow,
      sorting,
      sortingStateColumnExtensions,
      tableColumnExtensions,
      totalCount
    } = this.state;

    return (
      <>
        <Typography variant="h6" gutterBottom>
          {t('agents.list.list-agents')}
          <Button
            className={[classes.button, 'secondaryBtnColor'].join(' ')}
            color="secondary"
            component={Link}
            to="/dashboard/agents/add"
            variant="contained"
          >
            <AddIcon className={classes.leftIcon} />
            {t('agents.list.add-new-agent')}
          </Button>
        </Typography>
        <Paper style={{ position: 'relative' }} className={classes.paperWrapper}>
          <Grid rows={rows} columns={columns}>
            <SortingState
              columnExtensions={sortingStateColumnExtensions}
              onSortingChange={this.changeSorting}
              sorting={sorting}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.changeCurrentPage}
              onPageSizeChange={this.changePageSize}
              pageSize={pageSize}
            />
            <CustomPaging totalCount={totalCount} />
            <EmailTypeProvider for={emailColumns} />
            <ImageTypeProvider for={imageColumns} />
            <PhoneTypeProvider for={mobileColumns} />
            <PhoneTypeProvider for={phoneColumns} />
            <PropertiesProvider for={propsColumns} />
            <RequestProvider for={requestColumns} />
            <SearchState value={filter} onValueChange={this.changeSearchValue} />
            <Table
              columnExtensions={tableColumnExtensions}
              cellComponent={({ column, value, style, row }) => (
                <Cell
                  column={column}
                  history={history}
                  index={rows.findIndex((item) => row._id === item._id)}
                  propertiesModal={this.showPropertiesModal}
                  requestsModal={this.showRequestsModal}
                  row={row}
                  style={style}
                  value={value}
                />
              )}
            />
            <TableHeaderRow showSortingControls />
            <Toolbar />
            <SearchPanel messages={{ searchPlaceholder: t('common.tables.search') }} />
            <PagingPanel
              pageSizes={pageSizes}
              messages={{
                showAll: t('common.tables.footer.all'),
                rowsPerPage: t('common.tables.footer.rows-per-page'),
                info: `${t('common.tables.footer.info.showing')} {from} ${t(
                  'common.tables.footer.info.to'
                )} {to} ({count} ${t('common.tables.footer.info.total-elements')})`
              }}
            />
          </Grid>
          {loading && <Loading />}
        </Paper>
        <PropertiesListModal
          classes={classes}
          handleCloseRequestModal={this.closePropertiesModal}
          key={propertiesKey}
          row={selectedRow}
          visible={propertiesModal}
        />
        <RequestListModal
          classes={classes}
          deleteRequest={this.deleteRequest}
          handleCloseRequestModal={this.closeRequestsModal}
          key={requestsKey}
          row={selectedRow}
          visible={requestsModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentPage: state.agents.currentPage,
  pageSize: state.agents.pageSize,
  pageSizes: state.agents.pageSizes
});

const mapDispatchToProps = (dispatch) => ({
  onCurrentPageChange: (currentPage) =>
    dispatch(actions.createGridAgentsAction('currentPage', currentPage)),
  onPageSizeChange: (pageSize) =>
    dispatch(actions.createGridAgentsAction('pageSize', pageSize))
});

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AgentsList)))
);
