import MarketStatisticsList from '../components/MarketStatistics/MarketStatisticsList';

const marketStatisticsRoutes = [
  {
    path: '/dashboard/market-statistics/list',
    exact: false,
    name: 'List Notifications',
    component: MarketStatisticsList
  }
];

export default marketStatisticsRoutes;
