import React from 'react';
import { Route } from 'react-router-dom';
import propertyStatisticsRoutes from '../routes/propertyStatistics.routes';

function PropertyStatistics() {
  const routes = propertyStatisticsRoutes.map((prop, key) => (
    <Route
      component={prop.component}
      exact={prop.exact}
      key={key.toString()}
      path={prop.path}
    />
  ));
  return <>{routes}</>;
}

export default PropertyStatistics;
