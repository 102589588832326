import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import i18n from 'i18next';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MobileStepper from '@material-ui/core/MobileStepper';
import moment from 'moment';
import PublicIcon from '@material-ui/icons/Public';
import { notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { translateName } from '../../utils/utils';
import { apiUrl, avatarUrl, axiosCallApi, imagesUrl } from '../../config/config';
import GoogleMap from '../GoogleMap/GoogleMap';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 400
  },
  header: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: 50,
    paddingLeft: theme.spacing(4)
  }
});

class ViewRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      request: {
        property: {
          condition: [],
          images: [],
          region: []
        },
        agent: {}
      },
      activeStep: 0,
      deleteRequestDialog: false
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.getRequest(match.params.id);
  }

  componentDidUpdate(prevProps) {
    const { location, match } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      this.getRequest(match.params.id);
    }
  }

  handleClickOpenDeleteDialog = () => {
    this.setState({ deleteRequestDialog: true });
  };

  handleCloseDeleteDialog = () => {
    this.setState({ deleteRequestDialog: false });
  };

  deleteRequest = () => {
    const { match, t } = this.props;
    axiosCallApi
      .delete(`${apiUrl}/request/${match.params.id}`)
      .then(() => {
        notification.success({
          message: t('requestPages.delete-success')
        });
        this.handleCloseDeleteDialog();
      })
      .catch(() => {
        notification.error({
          message: t('requestPages.delete-error')
        });
      });
  };

  getRequest = async (req) => {
    const { t } = this.props;
    axiosCallApi
      .get(`${apiUrl}/request/${req}`)
      .then((response) => {
        const { request } = response.data;
        this.setState({ request });
      })
      .catch(() => {
        notification.error({
          message: t('common.error-fetch')
        });
      });
  };

  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1
    }));
  };

  render() {
    const { classes, theme, t } = this.props;
    const { request } = this.state;
    const maxSteps = request.property.images.length;
    return (
      <div key={Math.random()}>
        <Grid spacing={24} container className="request-modal-info-section">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Button
              className="custom-btn"
              color="primary"
              href={`https://www.thebrik.com/property/${request.property.slug}`}
              target="_blank"
              variant="contained"
            >
              <PublicIcon className="button-icon" />
              {t('requestPages.show-property')}
            </Button>
            <Button
              onClick={this.handleClickOpenDeleteDialog}
              style={{
                background: '#FD3D78',
                color: 'white',
                marginLeft: 40
              }}
              variant="contained"
              className="custom-btn"
            >
              <DeleteIcon className="button-icon" />
              {t('requestPages.delete-request')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <div className="card-wrapper">
              <h2 className="request-title">{t('requestPages.request-info')}</h2>
              <div className="request-info-title">
                <span className="label">{t('requestPages.created-at')}</span>{' '}
                {moment(request.createdAt).format('DD/MM/YYYY HH:mm')}
              </div>
              <div className="request-info-title">
                <span className="label">{t('requestPages.client-name')}</span>{' '}
                {request.name}
              </div>
              <div className="request-info-title">
                <span className="label">{t('requestPages.client-phone')}</span>{' '}
                <a href={`tel:${request.phone}`}>{request.phone}</a>
              </div>
              <div className="request-info-title">
                <span className="label">{t('requestPages.client-email')}</span>{' '}
                <a href={`mailto:${request.email}`}>{request.email}</a>
              </div>
              <div className="request-info-title">
                <span className="label">{t('requestPages.client-message')}</span>{' '}
                {request.message}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <div className="card-wrapper">
              <h2 className="request-title">{t('requestPages.agent-info')}</h2>
              <div className="agent-info-section">
                <div>
                  <div className="request-info-title">
                    <span className="label">{t('requestPages.agent-name')}</span>{' '}
                    {request.agent ? request.agent.name : ''}
                  </div>
                  <div className="request-info-title">
                    <span className="label">{t('requestPages.agent-last-name')}</span>{' '}
                    {request.agent ? request.agent.surname : ''}
                  </div>
                  <div className="request-info-title">
                    <span className="label">{t('requestPages.agent-phone')}</span>{' '}
                    <a href={`tel:${request.agent ? request.agent.phone : ''}`}>
                      {request.agent ? request.agent.phone : ''}
                    </a>
                  </div>
                  <div className="request-info-title">
                    <span className="label">{t('requestPages.agent-mobile')}</span>{' '}
                    <a href={`tel:${request.agent ? request.agent.mobilePhone : ''}`}>
                      {request.agent ? request.agent.mobilePhone : ''}
                    </a>
                  </div>
                  <div className="request-info-title">
                    <span className="label">{t('requestPages.agent-email')}</span>{' '}
                    <a href={`mailto:${request.agent ? request.agent.email : ''}`}>
                      {request.agent ? request.agent.email : ''}
                    </a>
                  </div>
                </div>

                <img
                  alt="#"
                  className="img-fluid agent-avatar"
                  src={
                    request.agent.image
                      ? `${avatarUrl}/${request.agent.uid}/${request.agent.image}`
                      : `${avatarUrl}/dummy_agent_round.png`
                  }
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <div className="card-wrapper">
              <h2 className="request-title">{t('requestPages.property-info')}</h2>
              <div className="request-info-title">
                <span className="label">{t('requestPages.property-title')}</span>{' '}
                {request.property ? request.property.titleEn : ''}
              </div>
              <div className="request-info-title">
                <span className="label">{t('requestPages.property-area')}</span>{' '}
                {request.property
                  ? translateName(i18n.language, request.property.region)
                  : ''}
              </div>
              <div className="request-info-title">
                <span className="label">{t('requestPages.property-status')}</span>{' '}
                {request.property ? request.property.condition.label : ''}
              </div>
              <div className="request-info-title">
                <span className="label">{t('requestPages.property-price')}</span>{' '}
                {request.property ? request.property.price : ''}€
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid spacing={24} container className="request-modal-property-section">
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <div className="card-wrapper">
              <h2 className="request-title">{t('requestPages.property-images')}</h2>
              {request.property.images.length > 0 ? (
                <img
                  style={{ padding: 20 }}
                  className="img-fluid"
                  src={`${imagesUrl}/${request.property._id}/${
                    request.property.images[this.state.activeStep].image.name
                  }`}
                  alt={request.property.images[this.state.activeStep].label}
                />
              ) : null}
              <MobileStepper
                activeStep={this.state.activeStep}
                className={classes.mobileStepper}
                position="static"
                steps={maxSteps}
                nextButton={
                  <Button
                    size="small"
                    onClick={this.handleNext}
                    disabled={this.state.activeStep === maxSteps - 1}
                  >
                    {t('requestPages.property-images-next')}
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    disabled={this.state.activeStep === 0}
                    onClick={this.handleBack}
                    size="small"
                  >
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    {t('requestPages.property-images-previous')}
                  </Button>
                }
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} className="request-modal-map-section">
            <div className="card-wrapper">
              <h2 className="request-title">{t('requestPages.property-location')}</h2>
              <div style={{ padding: 20, height: 440 }}>
                <GoogleMap
                  center={[
                    request.property ? request.property.fakeLat : '',
                    request.property ? request.property.fakeLng : ''
                  ]}
                  lng={request.property ? request.property.fakeLng : ''}
                  lat={request.property ? request.property.fakeLat : ''}
                  name={request.property ? request.property.titleEn : ''}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          onClose={this.handleCloseDeleteDialog}
          open={this.state.deleteRequestDialog}
        >
          <DialogTitle style={{ background: '#FD3D78' }} id="alert-dialog-title">
            <span style={{ color: 'white' }}>{t('requestPages.are-you-sure')}</span>
          </DialogTitle>
          <DialogContent style={{ background: '#FD3D78' }}>
            <div style={{ color: 'white', marginTop: 25 }}>
              <strong>{t('requestPages.attention')}</strong>{' '}
              {t('requestPages.cannot-revert')}
            </div>
          </DialogContent>
          <DialogActions
            style={{
              background: '#FD3D78',
              margin: 0,
              paddingBottom: 8,
              paddingRight: 4,
              paddingTop: 8
            }}
          >
            <Button
              autoFocus
              onClick={this.handleCloseDeleteDialog}
              style={{ border: '1px solid #000000' }}
            >
              {t('common.cancel')}
            </Button>
            <Button
              autoFocus
              onClick={this.deleteRequest}
              style={{ outline: 0, color: 'white', border: '1px solid white' }}
            >
              {t('requestPages.delete')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(withStyles(styles, { withTheme: true })(ViewRequest))
);
