import React, { Component } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import Dashboard from '../../components/Dashboard/Dashboard';
import { axiosCallApi } from '../../config/config';

class AuthGuard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userIsAuthenticated: true
    };
  }
  // eslint-disable-next-line
  componentDidMount() {
    const token = localStorage.getItem('token');
    if (!token) return this.props.history.push('/login');
    const expirationDate = localStorage.getItem('expirationDate');

    const dateNow = new Date();

    if (expirationDate < dateNow.getTime() / 1000) {
      localStorage.clear();
      return this.props.history.push('/login');
    }
    if (token) {
      axiosCallApi.defaults.headers.common.Authorization = `bearer ${token}`;
    }
    this.setState({
      userIsAuthenticated: true
    });
  }

  shouldComponentUpdate(nextState) {
    return this.state.userIsAuthenticated !== nextState.userIsAuthenticated;
  }

  render() {
    if (!this.state.userIsAuthenticated) {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <Route
          exact
          path="/"
          render={() => (
            <Redirect to="/dashboard" auth={this.state.userIsAuthenticated} />
          )}
        />
        <Route
          path="/dashboard"
          render={() => <Dashboard auth={this.state.userIsAuthenticated} />}
        />
      </div>
    );
  }
}

export default withRouter(AuthGuard);
