export const GRID_STATE_AGENTS_CHANGE = 'GRID_STATE_AGENTS_CHANGE';
export const GRID_STATE_AMENITIES_CHANGE = 'GRID_STATE_AMENITIES_CHANGE';
export const GRID_STATE_PROPERTIES_CHANGE = 'GRID_STATE_PROPERTIES_CHANGE';
export const GRID_STATE_BLOG_CHANGE = 'GRID_STATE_BLOG_CHANGE';
export const GRID_STATE_COMFORTS_CHANGE = 'GRID_STATE_COMFORTS_CHANGE';
export const GRID_STATE_NEARBY_CHANGE = 'GRID_STATE_NEARBY_CHANGE';
export const GRID_STATE_NEIGHBORHOOD_CHANGE = 'GRID_STATE_NEIGHBORHOOD_CHANGE';
export const GRID_STATE_CITY_CHANGE = 'GRID_STATE_CITY_CHANGE';
export const GRID_STATE_SECTOR_CHANGE = 'GRID_STATE_SECTOR_CHANGE';
export const GRID_STATE_REQUESTS_CHANGE = 'GRID_STATE_REQUESTS_CHANGE';
export const GRID_STATE_TRANSPORTS_CHANGE = 'GRID_STATE_TRANSPORTS_CHANGE';
export const GRID_STATE_AGENT_REQUESTS_CHANGE = 'GRID_STATE_AGENT_REQUESTS_CHANGE';
export const GRID_STATE_RESET_AGENT_REQUESTS = 'GRID_STATE_RESET_AGENT_REQUESTS';
export const GRID_STATE_AGENT_PROPERTIES_CHANGE = 'GRID_STATE_AGENT_PROPERTIES_CHANGE';
export const GRID_STATE_RESET_AGENT_PROPERTIES = 'GRID_STATE_RESET_AGENT_PROPERTIES';
export const GRID_STATE_CONTENT_MANAGERS_CHANGE = 'GRID_STATE_CONTENT_MANAGERS_CHANGE';
