import * as actionTypes from './actionTypes';

// eslint-disable-next-line
export const createGridAgentRequestsAction = (partialStateName, partialStateValue) => ({
  type: actionTypes.GRID_STATE_AGENT_REQUESTS_CHANGE,
  partialStateName,
  partialStateValue
});

// eslint-disable-next-line
export const resetGridAgentRequestsAction = () => ({
  type: actionTypes.GRID_STATE_RESET_AGENT_REQUESTS
});
