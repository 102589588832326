import update from 'immutability-helper/index';
import * as actionTypes from '../actions/actionTypes';

const gridInitialState = {
  currentPage: 0,
  pageSize: 25,
  pageSizes: [5, 10, 15, 25, 50, 100]
};

const reducer = (state = gridInitialState, action) => {
  if (action.type === actionTypes.GRID_STATE_PROPERTIES_CHANGE) {
    if (action.partialStateName === 'pageSize') {
      return update(state, {
        currentPage: {
          $set: 0
        },
        pageSize: {
          $set: action.partialStateValue
        }
      });
    }
    return {
      ...state,
      [action.partialStateName]: action.partialStateValue
    };
  }
  return state;
};

export default reducer;
