import contentManagerList from '../components/ContentManagers/ContentManagerList';
import AddNewContentManager from '../components/ContentManagers/AddNewContentManager';
import EditContentManager from '../components/ContentManagers/EditContentManager';

const agentRoutes = [
  {
    path: '/dashboard/content-managers/list',
    exact: false,
    name: 'List Content Managers',
    component: contentManagerList
  },
  {
    path: '/dashboard/content-managers/add',
    exact: false,
    name: 'Add Content Manager',
    component: AddNewContentManager
  },
  {
    path: '/dashboard/content-managers/edit/:id',
    exact: false,
    name: 'Edit content managers',
    component: EditContentManager
  }
];

export default agentRoutes;
