import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import classNames from 'classnames';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import HomeIcon from '@material-ui/icons/Home';
import i18n from 'i18next';
import IconButton from '@material-ui/core/IconButton';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import moment from 'moment';
import socketIOClient from 'socket.io-client';
import SoundOffIcon from '@material-ui/icons/NotificationsOff';
import SoundOnIcon from '@material-ui/icons/NotificationsActive';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import update from 'immutability-helper';
import { Popover } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Nearby from '../../containers/Nearby';
import Neighborhoods from '../../containers/Neighborhoods';
import Cities from '../../containers/Cities';
import Sectors from '../../containers/Sectors';
import WebsiteUsers from '../../containers/WebsiteUsers';
import NestedList from './NestedList';
import Profile from '../../containers/Profile';
import Properties from '../../containers/Properties';
import PropertyStatistics from '../../containers/PropertyStatistics';
import Request from '../../containers/Request';
import Transportation from '../../containers/Transportation';
import { apiUrl, avatarUrl, axiosCallApi } from '../../config/config';
import { translateTitle } from '../../utils/utils';
import MarketStatistics from '../../containers/MarketStatistics';
import ContentManagers from '../../containers/ContentManagers';
import Articles from '../../containers/Articles';
import Amenities from '../../containers/Amenities';
import Agents from '../../containers/Agents';

const logo = '/images/nextcore.png';
const audio = new Audio('/sound.mp3');
const drawerWidth = 280;
const language = localStorage.getItem('lng');

const styles = (theme) => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    backgroundColor: 'white',
    paddingRight: 24
  },
  toolbarIcon: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    background: {
      // TODO: Fix background image in top left bar/position
      image: 'url(./images/logo.png)'
    },
    boxShadow: 'none',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    zIndex: theme.zIndex.drawer + 1
  },
  appBarShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    width: `calc(100% - ${drawerWidth}px)`
  },
  badge: {
    top: '50%',
    right: -3,
    // The border color match the background color.
    border: `2px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    }`
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1,
    color: '#000000'
  },
  drawerPaper: {
    background: 'rgb(45, 52, 70)',
    position: 'relative',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    whiteSpace: 'nowrap',
    width: drawerWidth
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: { width: theme.spacing(9) }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    background: 'whitesmoke',
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    padding: theme.spacing(3)
  },
  chartContainer: {
    marginLeft: -22
  },
  tableContainer: {
    height: 320
  },
  margin: {
    margin: theme.spacing(2)
  },
  padding: {
    padding: `0 ${theme.spacing(2)}px`
  }
});

class Dashboard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      endpoint: apiUrl,
      notificationSound: localStorage.getItem('notificationSound'),
      open: true,
      propertyNotifications: [],
      propertyPopover: false,
      requestNotifications: [],
      requestPopover: false
    };
  }

  componentDidMount() {
    const { endpoint } = this.state;
    const socket = socketIOClient(endpoint);
    const notification = localStorage.getItem('notificationSound');

    if (!language) {
      localStorage.setItem('lng', i18n.language);
    }
    this.setState({ notificationSound: notification === 'true' });

    socket.on('delete-request', (data) => {
      const index = this.state.requestNotifications.findIndex(
        (item) => data._id === item._id
      );
      this.setState(
        update(this.state, {
          requestNotifications: {
            $splice: [[index, 1]]
          }
        })
      );
    });

    socket.on('request-change-status', (data) => {
      const index = this.state.requestNotifications.findIndex(
        (item) => data._id === (item.request ? item.request._id : null)
      );
      if (index !== -1) {
        this.setState(
          update(this.state, {
            requestNotifications: {
              [index]: {
                read: {
                  $set: data.read
                }
              }
            }
          })
        );
      }
    });

    socket.on('delete-editProperty', (data) => {
      const index = this.state.propertyNotifications.findIndex(
        (item) => data._id === item._id
      );
      if (index !== -1) {
        this.setState(
          update(this.state, {
            propertyNotifications: {
              $splice: [[index, 1]]
            }
          })
        );
      }
    });

    socket.on('edit-property', (data) => {
      this.setState(
        update(this.state, {
          propertyNotifications: {
            $apply: (items) => {
              items.unshift(data);
              return items;
            }
          }
        })
      );
    });

    setTimeout(() => {
      axiosCallApi.get(`${apiUrl}/notifications/request`).then(() => {
        socket.on('new-request', (data) => {
          this.playSound();
          this.setState(
            update(this.state, {
              requestNotifications: {
                $apply: (items) => {
                  items.unshift(data);
                  return items;
                }
              }
            }),
            () => this.forceUpdate()
          );
        });

        socket.on('get-requests', (data) => {
          this.setState({ requestNotifications: data });
        });
      });

      axiosCallApi.get(`${apiUrl}/notifications/properties`).then(() => {
        socket.on('new-property', (data) => {
          this.playSound();
          this.setState(
            update(this.state, {
              propertyNotifications: {
                $apply: (items) => {
                  items.unshift(data);
                  return items;
                }
              }
            }),
            () => this.forceUpdate()
          );
        });
        socket.on('get-properties', (data) =>
          this.setState({ propertyNotifications: data })
        );
      }, 1000);
    });
  }

  playSound = () => {
    if (this.state.notificationSound) {
      audio.play();
    }
    this.forceUpdate();
  };

  changeRequestStatus = (status, request, index) => {
    const data = {
      read: status
    };
    axiosCallApi
      .patch(`${apiUrl}/request/status/${request.request._id}`, data)
      .then(() => {
        this.setState(
          update(this.state, {
            requestNotifications: {
              [index]: {
                read: { $set: status }
              }
            }
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changePropertyStatus = (status, property, index) => {
    const data = {
      read: status,
      event: property.event
    };
    if (!property.read) {
      axiosCallApi
        .patch(`${apiUrl}/notifications/properties/status/${property._id}`, data)
        .then(() => {
          this.setState(
            update(this.state, {
              propertyNotifications: {
                [index]: {
                  read: { $set: status }
                }
              }
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleRequestPopover = () => {
    this.setState({ requestPopover: !this.state.requestPopover });
  };

  hideRequestPopover = () => {
    this.setState({ requestPopover: false });
  };

  handlePropertyPopover = () => {
    this.setState({ propertyPopover: !this.state.propertyPopover });
  };

  hidePropertyPopover = () => {
    this.setState({ propertyPopover: false });
  };

  logout = () => {
    localStorage.clear();
    axiosCallApi.defaults.headers.common.Authorization = '';
    this.props.history.push('/login');
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleNotificationSound = () => {
    this.setState({ notificationSound: !this.state.notificationSound }, () => {
      localStorage.setItem('notificationSound', this.state.notificationSound);
    });
  };

  textTruncate = (str, length, ending) => {
    if (!str) return;
    if (length == null) {
      length = 35;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      // eslint-disable-next-line
      return str.substring(0, length - ending.length) + ending;
    } else {
      // eslint-disable-next-line
      return str;
    }
  };

  render() {
    const { classes, t } = this.props;

    const { notificationSound, propertyNotifications, requestNotifications } = this.state;

    const requestContent = (
      <div className="topbarMessage withImg">
        <div className="isoDropdownHeader">
          <h3>
            {t('notifications.requests.requests')}{' '}
            <span style={{ fontStyle: 'italic', fontSize: 12 }}>
              ({requestNotifications.filter((item) => !item.read).length}{' '}
              {t('notifications.requests.unread')})
            </span>
          </h3>
        </div>
        <div className="isoDropdownBody">
          <div className="custom-scroll" style={{ height: 290 }}>
            {requestNotifications.length > 0 ? (
              requestNotifications.map((item, i) => (
                <div
                  className={['isoDropdownListItem', item.read ? `isOpened` : ''].join(
                    ' '
                  )}
                  key={i.toString()}
                  onClick={() => {
                    this.props.history.push(
                      `/dashboard/requests/view/${item.request._id}`
                    );
                    this.changeRequestStatus(true, item, i);
                    this.hideRequestPopover();
                  }}
                >
                  <div className="isoImgWrapper">
                    {item.agent ? (
                      <img
                        alt="#"
                        src={
                          item.agent.image
                            ? `${avatarUrl}/${item.agent.uid}/${item.agent.image}`
                            : `${avatarUrl}/dummy_agent_round.png`
                        }
                      />
                    ) : null}
                  </div>
                  <div className="isoListContent">
                    <div className="isoListHead">
                      {item.property ? (
                        <p>
                          <strong>{t('notifications.requests.property')}</strong>
                          {this.textTruncate(
                            translateTitle(i18n.language, item.property)
                          )}
                        </p>
                      ) : null}
                      <span className="isoDate">
                        {item.property
                          ? moment(item.createdAt).format('DD-MM-YYYY HH:mm')
                          : null}
                      </span>
                    </div>
                    <p>
                      <strong>{t('notifications.requests.client')} </strong>
                      {item.clientName}
                    </p>
                    <p>
                      <strong>{t('notifications.requests.agent')} </strong>
                      {item.agent ? item.agent.name : ''}
                      {item.agent ? item.agent.surname : ''}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-notifications-label">
                {t('notifications.no-notification')}
              </div>
            )}
          </div>
        </div>
      </div>
    );

    const propertyContent = (
      <div className="topbarMessage withImg">
        <div className="isoDropdownHeader">
          <h3>
            {t('notifications.properties.property.part1')}{' '}
            {t('notifications.properties.property.part2')}
          </h3>
        </div>
        <div className="isoDropdownBody">
          <div className="custom-scroll" style={{ height: 290 }}>
            {propertyNotifications.length > 0 ? (
              propertyNotifications.map((item, i) => (
                <div
                  className={['isoDropdownListItem', item.read ? `isOpened` : ''].join(
                    ' '
                  )}
                  key={i.toString()}
                  onClick={() => {
                    this.props.history.push(
                      `/dashboard/properties/edit/${item.property._id}`
                    );
                    this.changePropertyStatus(true, item, i);
                    this.hidePropertyPopover();
                  }}
                >
                  <div className="isoImgWrapper">
                    {item.agent ? (
                      <img
                        alt="#"
                        src={
                          item.agent.image
                            ? `${avatarUrl}/${item.agent.uid}/${item.agent.image}`
                            : `${avatarUrl}/dummy_agent_round.png`
                        }
                      />
                    ) : null}
                  </div>
                  <div className="isoListContent">
                    <div className="isoListHead">
                      <p>
                        <strong>{t('notifications.properties.property.part3')}</strong>
                        {this.textTruncate(translateTitle(i18n.language, item.property))}
                      </p>
                      <span className="isoDate">
                        {moment(item.createdAt).format('DD-MM-YYYY HH:mm')}
                      </span>
                    </div>
                    {item.agent ? (
                      <p>
                        <strong>{t('notifications.properties.agent')}</strong>
                        {item.agent.name} {item.agent.surname}
                      </p>
                    ) : (
                      ''
                    )}
                    <p>
                      <strong>{t('notifications.properties.type.type')}</strong>
                      {item.event === 'editProperty'
                        ? t('notifications.properties.type.pending')
                        : t('notifications.properties.type.new')}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-notifications-label">
                {t('notifications.no-notification')}
              </div>
            )}
          </div>
        </div>
      </div>
    );

    return (
      <>
        <CssBaseline />
        <div className={classes.root}>
          <AppBar
            className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
            position="absolute"
          >
            <Toolbar className={classes.toolbar} disableGutters={!this.state.open}>
              <IconButton
                aria-label="Open drawer"
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.menuButtonHidden
                )}
                onClick={this.handleDrawerOpen}
                style={{ color: '#000000' }}
              >
                <MenuIcon />
              </IconButton>
              <div className="d-flex align-items-center mr-auto">
                <Typography className={classes.title} color="inherit" noWrap variant="h6">
                  Brik Dashboard
                </Typography>
                <div onClick={this.logout} className="logout-button">
                  Logout
                </div>
              </div>

              <div style={{ marginRight: 20 }}>
                <div
                  className="isoIconWrapper"
                  style={{
                    cursor: 'pointer',
                    display: 'inline-flex',
                    verticalAlign: 'middle'
                  }}
                >
                  {notificationSound ? (
                    <SoundOnIcon
                      onClick={() => this.handleNotificationSound()}
                      style={{ color: '#2d3446' }}
                    />
                  ) : (
                    <SoundOffIcon
                      onClick={() => this.handleNotificationSound()}
                      style={{ color: '#2d3446' }}
                    />
                  )}
                </div>
              </div>
              <div className="print-button">
                <Popover
                  content={requestContent}
                  onVisibleChange={this.handleRequestPopover}
                  placement="bottomLeft"
                  trigger="click"
                  visible={this.state.requestPopover}
                >
                  <div className="isoIconWrapper">
                    <Badge
                      overlap="rectangular"
                      classes={{ badge: classes.badge }}
                      badgeContent={
                        requestNotifications.filter((item) => !item.read).length
                      }
                      color="secondary"
                      style={{
                        marginRight:
                          requestNotifications.filter((item) => !item.read).length > 0
                            ? 25
                            : 20
                      }}
                    >
                      <MailIcon style={{ color: '#777777' }} />
                    </Badge>
                  </div>
                </Popover>
              </div>
              <div style={{ marginRight: 40 }} className="print-button">
                <Popover
                  content={propertyContent}
                  onVisibleChange={this.handlePropertyPopover}
                  placement="bottomLeft"
                  trigger="click"
                  visible={this.state.propertyPopover}
                >
                  <div className="isoIconWrapper">
                    <Badge
                      overlap="rectangular"
                      classes={{ badge: classes.badge }}
                      badgeContent={
                        propertyNotifications.filter((item) => !item.read).length
                      }
                      color="secondary"
                    >
                      <HomeIcon style={{ color: '#2196f3' }} />
                    </Badge>
                  </div>
                </Popover>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !this.state.open && classes.drawerPaperClose
              )
            }}
            open={this.state.open}
          >
            <div className={classes.toolbarIcon}>
              <img src={logo} className="logo" alt="" style={{ padding: 15 }} />
              <IconButton onClick={this.handleDrawerClose} style={{ color: 'white' }}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <NestedList />
          </Drawer>
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Agents />
            <Amenities />
            <Articles />
            <ContentManagers />
            <MarketStatistics />
            <Nearby />
            <WebsiteUsers />
            <Neighborhoods />
            <Cities />
            <Sectors />
            <Profile />
            <Properties />
            <PropertyStatistics />
            <Request />
            <Transportation />
          </main>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(withStyles(styles)(Dashboard)));
