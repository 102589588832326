import React from 'react';
import { Route } from 'react-router-dom';
import transportationRoutes from '../routes/transportation.routes';

function Transportation() {
  const routes = transportationRoutes.map((prop, key) => (
    <Route
      component={prop.component}
      exact={prop.exact}
      key={key.toString()}
      path={prop.path}
    />
  ));
  return <>{routes}</>;
}

export default Transportation;
