import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from '@material-ui/core/Paper';
import ReactDOM from 'react-dom';
import SearchIcon from '@material-ui/icons/Search';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography/Typography';
import { Alert, notification } from 'antd';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { apiUrl, axiosCallApi, imagesUrl } from '../../config/config';
import HouseCard from '../UI-Elements/HouseCards';

const styles = (theme) => ({
  button: {
    marginLeft: theme.spacing(3),
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%'
  },
  fab: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  card: {
    minWidth: 100
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});

const data = [
  {
    name: 'January',
    views: 0,
    requests: 0
  },
  {
    name: 'February',
    views: 0,
    requests: 0
  },
  {
    name: 'March',
    views: 0,
    requests: 0
  },
  {
    name: 'April',
    views: 0,
    requests: 0
  },
  {
    name: 'May',
    views: 0,
    requests: 0
  },
  {
    name: 'June',
    views: 0,
    requests: 0
  },
  {
    name: 'July',
    views: 0,
    requests: 0
  },
  {
    name: 'August',
    views: 0,
    requests: 0
  },
  {
    name: 'September',
    views: 0,
    requests: 0
  },
  {
    name: 'October',
    views: 0,
    requests: 0
  },
  {
    name: 'November',
    views: 0,
    requests: 0
  },
  {
    name: 'December',
    views: 0,
    requests: 0
  }
];

notification.config({
  placement: 'topRight',
  top: 250,
  duration: 4
});

class PropertyStatisticsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data,
      labelWidth: 0,
      month: 'all',
      months: [],
      price: 999999999,
      properties: [],
      propertyData: data,
      propertyId: '',
      propertyName: '',
      propertyRequests: {},
      propertyViews: {},
      propertyYear: new Date().getFullYear(),
      region: '',
      regionOptions: [],
      showPropertyStats: false,
      totalRequests: {},
      totalRequestsCount: 0,
      totalViews: {},
      totalViewsCount: 0,
      year: new Date().getFullYear()
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.filterDataPerYear(this.state.year);
      this.initPropertyFilters().then();
      this.setState({
        // eslint-disable-next-line
        labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
      });
    }, 300);
  }

  initPropertyFilters = async () => {
    try {
      const regions = await axiosCallApi.get(`${apiUrl}/region/names`);
      const regionOptions = regions.data.map((el) => ({
        name: el.nameEl,
        value: el._id
      }));
      this.setState({ regionOptions });
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'There was a problem with property filters'
      });
    }
  };

  filterDataPerYear = (year) => {
    axiosCallApi
      .get(`${apiUrl}/statistics/get-properties-stats?year=${year}`)
      .then((response) => {
        // eslint-disable-next-line
        const data = [];
        const { totalRequests, totalRequestsCount, totalViews, totalViewsCount } =
          response.data;
        if (totalViews.length === 0 || totalRequests.length === 0) return;
        const months = Object.keys(totalViews[0]);
        months.forEach((el) => {
          data.push({
            name: el,
            views: totalViews[0][el],
            requests: totalRequests[0][el]
          });
        });
        this.setState({
          data,
          months,
          totalRequests: totalRequests[0],
          totalRequestsCount,
          totalViews: totalViews[0],
          totalViewsCount
        });
      })
      .catch((error) => {
        console.log(error);
        notification.error({
          message: 'There was a problem please try again later'
        });
      });
  };

  handleSelectChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.filterDataPerYear(this.state.year);
    });
  };

  handleSelectChangePropertyYear = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.getHouseDetails(this.state.propertyId, this.state.propertyName);
    });
  };

  handleSelectChangeProperties = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {});
  };

  getHouses = async () => {
    if (this.state.region === '') {
      notification.info({
        message: 'Please Select a region'
      });
      return;
    }
    this.setState({ showPropertyStats: false });
    const { price } = this.state;
    let priceRange;
    if (price === 0) {
      priceRange = [0, 50000];
    } else if (price === 50000) {
      priceRange = [50000, 100000];
    } else if (price === 100000) {
      priceRange = [100000, 200000];
    } else if (price === 200000) {
      priceRange = [200000, 500000];
    } else if (price === 500000) {
      priceRange = [500000, 9999999999];
    } else if (price === 999999999) {
      priceRange = [0, 9999999999];
    }
    // eslint-disable-next-line
    const data = {
      priceRange,
      regions: [this.state.region]
    };
    try {
      const properties = await axiosCallApi.post(
        `${apiUrl}/statistics/filter-apartments`,
        data
      );
      this.setState({ properties: properties.data.apartments });
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'There was a problem please try again later'
      });
    }
  };

  getHouseDetails = async (id, propertyName) => {
    try {
      const response = await axiosCallApi.get(
        `${apiUrl}/statistics/get-property-stats/${id}/${this.state.propertyYear}`,
        data
      );
      const stats = response.data;
      console.log(stats);
      const propertyData = [];
      let propertyViewsCount = 0;
      let propertyRequestsCount = 0;
      if (stats.propertyViews.length === 0 || stats.propertyRequests.length === 0) {
        this.setState({
          propertyData: [],
          propertyViewsCount: 0,
          propertyRequestsCount: 0
        });
        return;
      }
      this.state.months.forEach((el) => {
        propertyViewsCount += stats.propertyViews[0][el];
        propertyRequestsCount += stats.propertyRequests[0][el];
        propertyData.push({
          name: el,
          requests: stats.propertyRequests[0][el],
          views: stats.propertyViews[0][el]
        });
      });
      this.setState(
        {
          propertyData,
          propertyId: id,
          propertyName,
          propertyRequests: stats.propertyRequests[0],
          propertyRequestsCount,
          propertyViews: stats.propertyViews[0],
          propertyViewsCount
        },
        () => this.setState({ showPropertyStats: true })
      );
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'There was a problem please try again later'
      });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <Typography variant="h6" gutterBottom>
          Apartment Statistics
        </Typography>
        <Paper style={{ position: 'relative', marginTop: 10, padding: 25 }}>
          <h1 className="title-wrapper">
            <span>Total Statistics</span>
          </h1>
          <Grid container>
            <Grid item xs={12} sm={12} md={4}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-age-simple"
                >
                  Year
                </InputLabel>
                <Select
                  onChange={this.handleSelectChange}
                  value={this.state.year}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      name="year"
                      id="outlined-age-simple"
                    />
                  }
                >
                  <MenuItem value={2018}>2018</MenuItem>
                  <MenuItem value={2019}>2019</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={4}> */}
            {/* <FormControl variant="outlined" className={classes.formControl}> */}
            {/* <InputLabel */}
            {/* ref={ref => { */}
            {/* this.InputLabelRef = ref; */}
            {/* }} */}
            {/* htmlFor="outlined-age-simple" */}
            {/* > */}
            {/* Month */}
            {/* </InputLabel> */}
            {/* <Select */}
            {/* value={this.state.month} */}
            {/* onChange={this.handleSelectChange} */}
            {/* input={ */}
            {/* <OutlinedInput */}
            {/* labelWidth={this.state.labelWidth} */}
            {/* name="month" */}
            {/* id="outlined-age-simple" */}
            {/* /> */}
            {/* } */}
            {/* > */}
            {/* <MenuItem value="all"> */}
            {/* <em>All</em> */}
            {/* </MenuItem> */}
            {/* <MenuItem value={'January'}>January</MenuItem> */}
            {/* <MenuItem value={'February'}>February</MenuItem> */}
            {/* <MenuItem value={'March'}>March</MenuItem> */}
            {/* <MenuItem value={'April'}>April</MenuItem> */}
            {/* <MenuItem value={'May'}>May</MenuItem> */}
            {/* <MenuItem value={'June'}>June</MenuItem> */}
            {/* <MenuItem value={'July'}>July</MenuItem> */}
            {/* <MenuItem value={'August'}>August</MenuItem> */}
            {/* <MenuItem value={'September'}>September</MenuItem> */}
            {/* <MenuItem value={'October'}>October</MenuItem> */}
            {/* <MenuItem value={'November'}>November</MenuItem> */}
            {/* <MenuItem value={'December'}>December</MenuItem> */}
            {/* </Select> */}
            {/* </FormControl> */}
            {/* </Grid> */}
          </Grid>
          <Grid container style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card} style={{ margin: 5 }}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Total Views
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {this.state.month === 'all'
                      ? this.state.totalViewsCount
                      : this.state.totalViews[this.state.month]}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Card className={classes.card} style={{ margin: 5 }}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    Total Requests
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {this.state.month === 'all'
                      ? this.state.totalRequestsCount
                      : this.state.totalRequests[this.state.month]}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <BarChart
              width={1100}
              height={300}
              data={this.state.data}
              style={{ marginTop: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Views" fill="#8884d8" style={{ marginTop: 10 }} />
              <Bar dataKey="Requests" fill="#82ca9d" />
            </BarChart>
          </Grid>
        </Paper>
        <Paper style={{ position: 'relative', marginTop: 10, padding: 25 }}>
          <h1 className="title-wrapper">
            <span>Statistics per apartment</span>
          </h1>
          <Grid container style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-age-simple"
                >
                  Region
                </InputLabel>
                <Select
                  onChange={this.handleSelectChangeProperties}
                  value={this.state.region}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      name="region"
                      id="outlined-age-simple"
                    />
                  }
                >
                  {this.state.regionOptions.map((el, i) => (
                    <MenuItem value={el.value} key={i.toString()}>
                      {el.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-age-simple"
                >
                  Price
                </InputLabel>
                <Select
                  value={this.state.price}
                  onChange={this.handleSelectChangeProperties}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      name="price"
                      id="outlined-age-simple"
                    />
                  }
                >
                  <MenuItem value={999999999}>None</MenuItem>
                  <MenuItem value={0}>0-50.000 €</MenuItem>
                  <MenuItem value={50000}>50.000-100.000 €</MenuItem>
                  <MenuItem value={100000}>100.000-200.000 €</MenuItem>
                  <MenuItem value={200000}>200.000-500.000 €</MenuItem>
                  {/* eslint-disable-next-line */}
                  <MenuItem value={500000}> >500.000 €</MenuItem>
                </Select>
              </FormControl>
              <Fab
                color="primary"
                aria-label="Search"
                className={classes.fab}
                onClick={this.getHouses}
                style={{ cursor: 'pointer' }}
              >
                <SearchIcon />
              </Fab>
            </Grid>
            <Grid item xs={12} sm={6} md={12} style={{ marginTop: 25 }}>
              <h1 className="title-wrapper">
                <span>Apartments</span>
              </h1>
              <Grid className="apartments-statistics-container custom-scroll" container>
                {this.state.properties.length === 0 ? (
                  <Alert
                    style={{ margin: '20px auto' }}
                    message="No apartments found"
                    description="Change the filters and try again "
                    type="info"
                    showIcon
                  />
                ) : (
                  this.state.properties.map((property, i) => (
                    <div
                      key={i.toString()}
                      style={{ margin: 10 }}
                      onClick={() => this.getHouseDetails(property._id, property.title)}
                    >
                      <HouseCard
                        bedrooms={property.bedrooms}
                        floor={property.floor}
                        image={`${imagesUrl}/${property._id}/${property.images[0].image.name}`}
                        name={property.title}
                        region={property.region.nameEl}
                        totalArea={property.totalArea}
                      />
                    </div>
                  ))
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {this.state.showPropertyStats && (
          <Paper style={{ position: 'relative', marginTop: 10, padding: 20 }}>
            <Grid container>
              <Grid item xs={12} sm={6} md={12}>
                <h1 className="title-wrapper">
                  <span>Statistics for apartment {this.state.propertyName}</span>
                </h1>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel
                    ref={(ref) => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="outlined-age-simple"
                  >
                    Year
                  </InputLabel>
                  <Select
                    disabled={!this.state.showPropertyStats}
                    onChange={this.handleSelectChangePropertyYear}
                    value={this.state.propertyYear}
                    input={
                      <OutlinedInput
                        labelWidth={this.state.labelWidth}
                        name="propertyYear"
                        id="outlined-age-simple"
                      />
                    }
                  >
                    <MenuItem value={2018}>2018</MenuItem>
                    <MenuItem value={2019}>2019</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Grid item xs={12} sm={6} md={4}>
                <Card className={classes.card} style={{ margin: 5 }}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Total Views
                    </Typography>
                    <Typography variant="h5" component="h2">
                      {this.state.propertyViewsCount}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card className={classes.card} style={{ margin: 5 }}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      Total Requests
                    </Typography>
                    <Typography variant="h5" component="h2">
                      {this.state.propertyRequestsCount}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <BarChart
                width={1100}
                height={300}
                data={this.state.propertyData}
                style={{ marginTop: 20 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Views" fill="#8884d8" style={{ marginTop: 10 }} />
                <Bar dataKey="Requests" fill="#82ca9d" />
              </BarChart>
            </Grid>
          </Paper>
        )}
      </>
    );
  }
}

export default withRouter(withStyles(styles)(PropertyStatisticsList));
