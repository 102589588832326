import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import debounce from 'lodash.debounce';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import MatToolbar from '@material-ui/core/Toolbar';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';
import Slide from '@material-ui/core/Slide';
import update from 'immutability-helper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { connect } from 'react-redux';
import {
  CustomPaging,
  DataTypeProvider,
  PagingState,
  SearchState,
  SortingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  SearchPanel,
  Table,
  TableHeaderRow,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui';
import { notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { Loading } from '../UI-Elements/Loading';
import { apiUrl, axiosCallApi } from '../../config/config';
import PropertyModal from '../sharedComponents/PropertyModal';
import * as actions from '../../redux/actions';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 400
  },
  button: {
    marginLeft: 25,
    marginTop: 25
  },
  header: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  appBar: {
    background: 'rgb(45, 52, 70)',
    position: 'relative'
  },
  flex: {
    flex: 1
  }
});

function PhoneFormatter({ value }) {
  return <a href={`tel:${value}`}>{value}</a>;
}

function PhoneTypeProvider(props) {
  return <DataTypeProvider formatterComponent={PhoneFormatter} {...props} />;
}

function EmailFormatter({ value }) {
  return <a href={`mailto:${value}`}>{value}</a>;
}

function EmailTypeProvider(props) {
  return <DataTypeProvider formatterComponent={EmailFormatter} {...props} />;
}

function PropertyFormatter({ value, row }) {
  return (
    <a
      href={`https://www.thebrik.com/property/${row.property.slug}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {value}
    </a>
  );
}

function PropertyProvider(props) {
  return <DataTypeProvider formatterComponent={PropertyFormatter} {...props} />;
}

function ViewButton({ row, modal }) {
  return (
    <IconButton onClick={() => modal(row)} title="View Request">
      <VisibilityIcon />
    </IconButton>
  );
}

function Cell({ column, history, requestModal, row, style, value }) {
  let content = value;
  if (column.name === 'view') {
    content = <ViewButton row={row} history={history} modal={requestModal} />;
  }

  if (column.name === 'email') {
    content = <EmailFormatter row={row} value={value} />;
  }
  if (column.name === 'phone') {
    content = <PhoneFormatter row={row} value={value} />;
  }
  if (column.name === 'agentPhone') {
    content = <PhoneFormatter row={row} value={value} />;
  }
  if (column.name === 'contactPhone') {
    content = <PhoneFormatter row={row} value={value} />;
  }

  if (column.name === 'property') {
    content = <PropertyFormatter value={value} row={row} />;
  }
  return <Table.Cell style={style}>{content}</Table.Cell>;
}

class RequestListModal extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      columns: [
        {
          name: 'createdAt',
          title: 'Date',
          getCellValue: (row) => moment(row.createdAt).format('DD/MM/YYYY HH:mm')
        },
        {
          name: 'name',
          title: t('requests.table.headers.client-name'),
          getCellValue: (row) => row.name
        },
        { name: 'email', title: 'Email', getCellValue: (row) => row.email },
        {
          name: 'phone',
          title: t('requests.table.headers.client-phone'),
          getCellValue: (row) => row.phone
        },
        {
          name: 'agent',
          title: t('requests.table.headers.agent'),
          getCellValue: (row) => `${row.agent.name} ${row.agent.surname}`
        },
        {
          name: 'agentPhone',
          title: t('requests.table.headers.agent-phone'),
          getCellValue: (row) => row.agent.mobilePhone
        },
        {
          name: 'property',
          title: t('requests.table.headers.property'),
          getCellValue: (row) => (row.property ? row.property.titleEn : '')
        },
        { name: 'view', title: t('requests.table.headers.view-request') }
      ],
      agentColumns: ['agent'],
      agentModal: false,
      currentPage: 0,
      emailColumns: ['email'],
      endpoint: apiUrl,
      filter: '',
      key: 0,
      loading: true,
      loadingIndex: -1,
      loadingStatus: false,
      mobileColumns: ['mobile'],
      pageSizes: [5, 10, 15, 25, 50, 100],
      phoneColumns: ['contactPhone'],
      propertyColumns: ['property'],
      requestModal: false,
      rows: [],
      selectedRow: '',
      sorting: [{ columnName: 'createdAt', direction: 'asc' }],
      statusColumns: ['active'],
      totalCount: 0
    };

    this.loadDataDebounced = debounce(() => {
      this.loadData();
    }, 600);
  }

  componentDidMount() {
    if (this.props.row) {
      axiosCallApi.post(`${apiUrl}/admin/getRole`).then((response) => {
        this.setState({ scopes: response.data.scopes });
      });
      this.loadDataDebounced();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.sorting !== prevState.sorting) {
      this.props.onCurrentPageChange(0);
    }
    if (
      this.props.currentPage !== prevProps.currentPage ||
      this.props.pageSize !== prevProps.pageSize ||
      this.state.filter !== prevState.filter ||
      this.state.sorting !== prevState.sorting
    ) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  handleClickOpenRequest = () => {
    this.setState({ requestModal: true });
  };

  handleCloseRequestModal = () => {
    this.setState({ requestModal: false, selectedRow: '' });
  };

  showModal = (row) => {
    this.setState({
      selectedRow: row
    });
    this.handleClickOpenRequest();
  };

  deleteRequest = (id) => {
    this.props.deleteRequest(id);
    const index = this.state.rows.findIndex((item) => id === item._id);
    if (index !== -1) {
      this.setState(
        update(this.state, {
          totalCount: {
            $set: this.state.totalCount - 1
          },
          rows: {
            $splice: [[index, 1]]
          }
        })
      );
    }
  };

  changeSorting = (sorting) => {
    this.setState({
      loading: true,
      sorting
    });
  };

  changePageSize = (pageSize) => {
    const { totalCount } = this.state;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.min(this.props.currentPage, totalPages);

    this.setState(
      {
        loading: true
      },
      () => {
        this.props.onPageSizeChange(pageSize);
        this.props.onCurrentPageChange(currentPage);
      }
    );
  };

  changeCurrentPage = (currentPage) => {
    this.setState(
      {
        loading: true
      },
      () => this.props.onCurrentPageChange(currentPage)
    );
  };

  changeSearchValue = (searchValue) => {
    this.setState(
      {
        loading: true,
        filter: searchValue
      },
      () => this.props.onCurrentPageChange(0)
    );
  };

  queryString() {
    const { sorting, filter } = this.state;
    const { pageSize, currentPage } = this.props;
    let queryString = `${apiUrl}/agent/requests/?perPage=${pageSize}&skip=${currentPage}&filter=${filter}&id=${this.props.row._id}`;
    const columnSorting = sorting[0];
    if (columnSorting) {
      const sortingDirectionString = columnSorting.direction === 'desc' ? 1 : -1;
      queryString = `${queryString}&orderby=${columnSorting.columnName}`;
      queryString = `${queryString}&sort=${sortingDirectionString}`;
    }
    return queryString;
  }

  loadData() {
    const queryString = this.queryString();
    if (queryString === this.lastQuery) {
      // this.setState({ loading: false });
      return;
    }
    axiosCallApi
      .get(queryString)
      .then((response) => {
        this.setState({
          loading: false,
          rows: response.data.requests,
          totalCount: response.data.count
        });
      })
      .catch(() => {
        notification.error({
          message: this.props.t('common.error-fetch')
        });
        this.setState({ loading: false });
      });
    this.lastQuery = queryString;
  }

  render() {
    const {
      columns,
      emailColumns,
      filter,
      loading,
      mobileColumns,
      phoneColumns,
      propertyColumns,
      requestModal,
      rows,
      selectedRow,
      sorting,
      sortingStateColumnExtensions,
      tableColumnExtensions,
      totalCount
    } = this.state;

    const {
      classes,
      currentPage,
      handleCloseRequestModal,
      pageSize,
      pageSizes,
      row,
      t,
      visible
    } = this.props;

    return (
      <Dialog
        className="request-modal"
        fullScreen
        onClose={handleCloseRequestModal}
        open={visible}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <MatToolbar className="request-modal-toolbar">
            <h2>
              {t('agent-requests-modal.info')} {row ? row.surname : ''}{' '}
              {row ? row.name : ''}
            </h2>
            <div className="request-modal-icons-section">
              <ReactToPrint
                trigger={() => (
                  <PrintIcon style={{ marginRight: 30 }} className="print-button" />
                )}
                content={() => this.componentRef}
              />
              <IconButton
                aria-label="Close"
                color="inherit"
                onClick={handleCloseRequestModal}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </MatToolbar>
        </AppBar>
        <div ref={(el) => (this.componentRef = el)}>
          <Paper
            style={{
              position: 'relative'
            }}
          >
            <Grid rows={rows} columns={columns}>
              <SortingState
                columnExtensions={sortingStateColumnExtensions}
                onSortingChange={this.changeSorting}
                sorting={sorting}
              />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={this.changeCurrentPage}
                onPageSizeChange={this.changePageSize}
                pageSize={pageSize}
              />
              <CustomPaging totalCount={totalCount} />
              <SearchState value={filter} onValueChange={this.changeSearchValue} />
              <EmailTypeProvider for={emailColumns} />
              <PhoneTypeProvider for={phoneColumns} />
              <PhoneTypeProvider for={mobileColumns} />
              <PropertyProvider for={propertyColumns} />
              <Table
                columnExtensions={tableColumnExtensions}
                // eslint-disable-next-line
                cellComponent={({ column, value, style, row }) => (
                  <Cell
                    column={column}
                    requestModal={this.showModal}
                    row={row}
                    style={style}
                    value={value}
                  />
                )}
              />
              <TableHeaderRow showSortingControls />
              <Toolbar />
              <SearchPanel messages={{ searchPlaceholder: t('common.tables.search') }} />
              <PagingPanel
                pageSizes={pageSizes}
                messages={{
                  showAll: t('common.tables.footer.all'),
                  rowsPerPage: t('common.tables.footer.rows-per-page'),
                  info: `${t('common.tables.footer.info.showing')} {from} ${t(
                    'common.tables.footer.info.to'
                  )} {to} ({count} ${t('common.tables.footer.info.total-elements')})`
                }}
              />
            </Grid>
            {loading && <Loading />}
          </Paper>
          <div style={{ marginTop: 85 }}>
            <PropertyModal
              classes={classes}
              deleteRequest={this.deleteRequest}
              handleCloseRequestModal={this.handleCloseRequestModal}
              language={localStorage.getItem('lng')}
              row={selectedRow}
              visible={requestModal}
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  currentPage: state.agentRequests.currentPage,
  pageSize: state.agentRequests.pageSize,
  pageSizes: state.agentRequests.pageSizes
});

const mapDispatchToProps = (dispatch) => ({
  onCurrentPageChange: (currentPage) =>
    dispatch(actions.createGridAgentRequestsAction('currentPage', currentPage)),
  onPageSizeChange: (pageSize) =>
    dispatch(actions.createGridAgentRequestsAction('pageSize', pageSize)),
  reset: () => dispatch(actions.resetGridAgentPropertiesAction())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation()(withRouter(withStyles(styles, { withTheme: true })(RequestListModal)))
);
