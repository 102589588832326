import React from 'react';
import GoogleMapReact from 'google-map-react';
import GoogleMapMarker from './GoogleMapMarker';

function GoogleMap({ center, onMapClick, name, lat, lng, defaultZoom }) {
  // const places = this.props.placesList
  //   .map((place, i) => {
  //     const {id, ...coords} = place;
  //     return (
  //       <GoogleMapMarker
  //         key={i.toString()}
  //         lat={place.lat}
  //         lng={place.lng}
  //         name={place.name}
  //         surname={place.surname}
  //         id={place.id}
  //         pathName={this.props.pathName}
  //         businessName={place.businessName}
  //         speciality={place.speciality}
  //         // use your hover state (from store, react-controllables etc...)
  //       />
  //     );
  //   });
  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: 'AIzaSyDsM37x40ervi3G_LLPuE_DqpnmL9XrKRo',
        // key: 'AIzaSyA_U49p1vyZooyS9dP6lIFZBeL6AbYKkaA',
        language: 'el',
        mapTypeId: 'roadmap'
      }}
      defaultCenter={center}
      defaultZoom={defaultZoom || 15}
      hoverDistance={20}
      center={{ lat, lng }}
      // eslint-disable-next-line
      onClick={({ x, y, lat, lng, event }) => onMapClick(lat, lng)}
      options={{ mapTypeControl: false, mapTypeId: 'roadmap' }}
      resetBoundsOnResize
    >
      {/* {places} */}
      <GoogleMapMarker lat={lat || center[0]} lng={lng || center[1]} name={name} />
    </GoogleMapReact>
  );
}

GoogleMap.defaultProps = {
  center: [37.9988844, 23.735316],
  zoom: 9
};

export default GoogleMap;
