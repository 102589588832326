import React from 'react';
import { Route } from 'react-router-dom';
import profileRoutes from '../routes/profile.routes';

function Profile() {
  const routes = profileRoutes.map((prop, key) => (
    <Route
      component={prop.component}
      exact={prop.exact}
      key={key.toString()}
      path={prop.path}
    />
  ));
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{routes}</>;
}

export default Profile;
