import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  card: {
    maxWidth: 345
  },
  media: {
    height: 140
  }
};

function HouseCard(props) {
  const { classes } = props;
  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={props.image}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h3">
            {props.name}
          </Typography>
          <Typography component="p">
            {props.totalArea}m² - {props.bedrooms} beds- {props.floor}
            {props.floor === 1
              ? 'st'
              : props.floor === 2
              ? 'nd'
              : props.floor === 3
              ? 'rd'
              : 'th'}{' '}
            floor
          </Typography>
          <Typography component="h4">{props.region}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default withStyles(styles)(HouseCard);
