import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

function Image({ image, styles }) {
  return (
    <LazyLoadImage
      alt={image}
      className={['img-fluid', 'property-img', styles].join(' ')}
      effect="opacity"
      src={image}
    />
  );
}

export default Image;
