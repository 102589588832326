import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from '@material-ui/core/Paper';
import ReactDOM from 'react-dom';
import SearchIcon from '@material-ui/icons/Search';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography/Typography';
import { Alert, notification, Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { apiUrl, axiosCallApi } from '../../config/config';

const styles = (theme) => ({
  button: {
    marginLeft: theme.spacing(3),
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%'
  },
  fab: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  card: {
    minWidth: 100
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

notification.config({
  placement: 'topRight',
  top: 250,
  duration: 4
});

class MarketStatisticsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conversionLoading: false,
      conversionMonth: 'January',
      conversionRate: 0,
      conversionYear: new Date().getFullYear(),
      errorMessage: '',
      isError: false,
      labelWidth: 0,
      loading: false,
      medianDaysOnMarket: 0,
      medianPrice: 0,
      medianPricePerSquare: 0,
      month: '',
      numberOfSales: 0,
      numberOfViews: 0,
      region: '',
      regionOptions: [],
      selectedRegion: '',
      transactionVolume: 0,
      year: new Date().getFullYear()
    };
  }

  async componentDidMount() {
    try {
      this.getMarketStats();
      this.getConversionRate();
      this.initPropertyFilters();
      this.setState({
        // eslint-disable-next-line
        labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
      });
    } catch (error) {
      notification.error({
        message: this.props.t('common.error-fetch')
      });
    }
  }

  initPropertyFilters = async () => {
    try {
      const regions = await axiosCallApi.get(`${apiUrl}/region/names`);
      const regionOptions = regions.data.map((el) => ({
        name: el.nameEl,
        value: el._id
      }));
      this.setState({ regionOptions });
    } catch (err) {
      notification.error({
        message: this.props.t('filters-error')
      });
    }
  };

  handleSelectChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {});
  };

  handleSelectChangeRegion = (event) => {
    if (event.target.value.length > 4) {
      const selectedRegion = this.state.regionOptions.filter(
        (el) => el.value === event.target.value
      );
      this.setState({
        selectedRegion: selectedRegion[0].name,
        [event.target.name]: event.target.value
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
        selectedRegion: 'All'
      });
    }
  };

  getMarketStats = async () => {
    const { t } = this.props;
    this.setState({ loading: true });
    const data = { year: this.state.year };
    if (!(this.state.region === '' || this.state.region === 'All')) {
      data.region = this.state.region;
    }
    if (!(this.state.month === '' || this.state.month === 'All')) {
      data.month = this.state.month;
    }
    try {
      const response = await axiosCallApi.post(
        `${apiUrl}/statistics/market-statistics`,
        data
      );

      if (response.data.length === 0) {
        this.setState({
          errorMessage: t('statistics.market.no-results'),
          isError: true,
          loading: false
        });
        return;
      }
      const stats = response.data[0];
      const moneyFormatter = new Intl.NumberFormat('gr-GR', {
        currency: 'EUR',
        style: 'currency'
      });
      this.setState({
        loading: false,
        medianDaysOnMarket: stats.medianDaysOnMarket.toFixed(3),
        medianPrice: moneyFormatter.format(stats.medianPrice.toFixed(3)),
        medianPricePerSquare: moneyFormatter.format(
          stats.medianPricePerSquare.toFixed(3)
        ),
        transactionVolume: moneyFormatter.format(stats.transactionVolume.toFixed(3))
      });
    } catch (error) {
      notification.error({
        message: t('common.error-fetch')
      });
      this.setState({
        errorMessage: t('common.error-fetch'),
        isError: true,
        loading: false
      });
    }
  };

  getConversionRate = async () => {
    this.setState({ conversionLoading: true });
    const data = {
      month: this.state.conversionMonth,
      year: this.state.conversionYear
    };
    try {
      const response = await axiosCallApi.post(
        `${apiUrl}/statistics/extra-statistics`,
        data
      );
      const stats = response.data;
      this.setState({
        conversionLoading: false,
        conversionRate: stats.conversionRate,
        numberOfSales: stats.numberOfSales,
        numberOfViews: stats.numberOfViews
      });
    } catch (error) {
      this.setState({ conversionLoading: false });
      notification.error({
        message: this.props.t('common.error-fetch')
      });
    }
  };

  render() {
    const { classes, t } = this.props;
    return (
      <>
        <Typography variant="h6" gutterBottom>
          {t('statistics.market.market-statistics')}
        </Typography>
        <Paper style={{ position: 'relative', marginTop: 10, padding: 25 }}>
          <h1 className="title-wrapper">
            <span>{t('statistics.market.filters')}</span>
          </h1>
          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-age-simple"
                >
                  {t('statistics.market.year')}
                </InputLabel>
                <Select
                  onChange={this.handleSelectChange}
                  value={this.state.year}
                  input={
                    <OutlinedInput
                      labelWidth={this.state.labelWidth}
                      name="year"
                      id="outlined-age-simple"
                    />
                  }
                >
                  <MenuItem value={2018}>2018</MenuItem>
                  <MenuItem value={2019}>2019</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-age-simple"
                >
                  {t('statistics.market.region')}
                </InputLabel>
                <Select
                  onChange={this.handleSelectChangeRegion}
                  value={this.state.region}
                  input={
                    <OutlinedInput
                      id="outlined-age-simple"
                      labelWidth={this.state.labelWidth}
                      name="region"
                    />
                  }
                >
                  <MenuItem value="All">{t('statistics.market.all-regions')}</MenuItem>
                  {this.state.regionOptions.map((el, i) => (
                    <MenuItem key={i.toString()} value={el.value}>
                      {el.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-age-simple"
                >
                  {t('statistics.market.month')}
                </InputLabel>
                <Select
                  onChange={this.handleSelectChange}
                  value={this.state.month}
                  input={
                    <OutlinedInput
                      id="outlined-age-simple"
                      labelWidth={this.state.labelWidth}
                      name="month"
                    />
                  }
                >
                  <MenuItem value="All">
                    {t('statistics.market.all-months-select')}
                  </MenuItem>
                  {months.map((el, i) => (
                    <MenuItem key={i.toString()} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Fab
                aria-label="Search"
                className={classes.fab}
                color="primary"
                onClick={this.getMarketStats}
                style={{ cursor: 'pointer' }}
              >
                <SearchIcon />
              </Fab>
            </Grid>
          </Grid>
        </Paper>
        <Spin
          tip={t('statistics.market.gathering-statistics')}
          size="large"
          spinning={this.state.loading}
        >
          <Paper style={{ position: 'relative', marginTop: 10, padding: 25 }}>
            <h1 className="title-wrapper">
              <span>
                {t('statistics.market.all-months.part1')}
                {this.state.month === '' || this.state.month === 'All'
                  ? t('statistics.market.all-months.part1b')
                  : this.state.month}{' '}
                <span style={{ opacity: 0 }}>_</span>
                {this.state.year} {t('statistics.market.all-months.part2')}
                {this.state.selectedRegion === '' || this.state.selectedRegion === 'All'
                  ? t('statistics.market.all-months.part3')
                  : this.state.selectedRegion}
              </span>
            </h1>
            {this.state.isError ? (
              <Grid container>
                <Alert
                  style={{ margin: '20px auto' }}
                  message={t('statistics.market.no-properties-found')}
                  description={this.state.errorMessage}
                  type="info"
                  showIcon
                />
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <Card className={classes.card} style={{ margin: 5 }}>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        {t('statistics.market.transaction-volume')}
                      </Typography>
                      <Typography variant="h5" component="h2">
                        {this.state.transactionVolume}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Card className={classes.card} style={{ margin: 5 }}>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        {t('statistics.market.median-price')}
                      </Typography>
                      <Typography variant="h5" component="h2">
                        {this.state.medianPrice}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Card className={classes.card} style={{ margin: 5 }}>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        {t('statistics.market.median-price-per-sq-meter')}
                      </Typography>
                      <Typography variant="h5" component="h2">
                        {this.state.medianPricePerSquare}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Card className={classes.card} style={{ margin: 5 }}>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        {t('statistics.market.median-days-on-market')}
                      </Typography>
                      <Typography variant="h5" component="h2">
                        {this.state.medianDaysOnMarket} {t('statistics.market.days')}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            )}
          </Paper>
        </Spin>
        <Paper style={{ position: 'relative', marginTop: 10, padding: 25 }}>
          <h1 className="title-wrapper">
            <span>{t('statistics.market.sales-and-conversion-rate')} </span>
          </h1>
          <Grid container>
            <Grid item xs={12} sm={12} md={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-age-simple"
                >
                  {t('statistics.market.year')}
                </InputLabel>
                <Select
                  onChange={this.handleSelectChange}
                  value={this.state.conversionYear}
                  input={
                    <OutlinedInput
                      id="outlined-age-simple"
                      labelWidth={this.state.labelWidth}
                      name="conversionYear"
                    />
                  }
                >
                  <MenuItem value={2018}>2018</MenuItem>
                  <MenuItem value={2019}>2019</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={(ref) => {
                    this.InputLabelRef = ref;
                  }}
                  htmlFor="outlined-age-simple"
                >
                  {t('statistics.market.month')}
                </InputLabel>
                <Select
                  onChange={this.handleSelectChange}
                  value={this.state.conversionMonth}
                  input={
                    <OutlinedInput
                      id="outlined-age-simple"
                      labelWidth={this.state.labelWidth}
                      name="conversionMonth"
                    />
                  }
                >
                  <MenuItem value="All">{t('statistics.market.all')}</MenuItem>
                  {months.map((el, i) => (
                    <MenuItem key={i.toString()} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Fab
                aria-label="Search"
                className={classes.fab}
                color="primary"
                onClick={this.getConversionRate}
                style={{ cursor: 'pointer' }}
              >
                <SearchIcon />
              </Fab>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 15 }}>
            <Grid item xs={12} sm={12} md={4}>
              <Spin
                size="large"
                spinning={this.state.conversionLoading}
                tip={t('statistics.market.gathering-statistics')}
              >
                <Card className={classes.card} style={{ margin: 5 }}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      {t('statistics.market.number-of-sales')}
                    </Typography>
                    <Typography variant="h5" component="h2">
                      {this.state.numberOfSales}
                    </Typography>
                  </CardContent>
                </Card>
              </Spin>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Spin
                size="large"
                spinning={this.state.conversionLoading}
                tip={t('statistics.market.gathering-statistics')}
              >
                <Card className={classes.card} style={{ margin: 5 }}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      {t('statistics.market.number-of-views')}
                    </Typography>
                    <Typography variant="h5" component="h2">
                      {this.state.numberOfViews}
                    </Typography>
                  </CardContent>
                </Card>
              </Spin>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Spin
                size="large"
                spinning={this.state.conversionLoading}
                tip={t('statistics.market.gathering-statistics')}
              >
                <Card className={classes.card} style={{ margin: 5 }}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      {t('statistics.market.conversion-rate')}
                    </Typography>
                    <Typography variant="h5" component="h2">
                      {(this.state.conversionRate * 100).toFixed(3)} %
                    </Typography>
                  </CardContent>
                </Card>
              </Spin>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

export default withTranslation()(withRouter(withStyles(styles)(MarketStatisticsList)));
