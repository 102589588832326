import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import update from 'immutability-helper';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { apiUrl, axiosCallApi } from '../../config/config';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  paperWrapper: {
    boxShadow: 'none',
    padding: 25
  },
  button: {
    backgroundColor: 'transparent',
    border: '1px solid #000000',
    boxShadow: 'none',
    color: 'black',
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'black',
      color: '#FFFFFF'
    }
  },
  input: {
    display: 'flex',
    padding: 0
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%'
  },
  valueContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap'
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    fontSize: 16,
    left: 2,
    position: 'absolute'
  },
  paper: {
    marginTop: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(2)
  }
});

notification.config({
  placement: 'topRight',
  top: 24,
  duration: 3
});

class AddNewAmenity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amenity: {
        active: true,
        nameEl: '',
        nameEn: '',
        nameZh: ''
      },
      submitBtnIsDisabled: false
    };
  }

  onInputChange = (e) => {
    this.setState(
      update(this.state, {
        amenity: {
          [e.target.name]: {
            $set: e.target.value
          }
        }
      })
    );
  };

  submitNewAmenity = (e) => {
    e.preventDefault();
    const { t } = this.props;
    const data = this.state.amenity;
    axiosCallApi
      .post(`${apiUrl}/amenity/new`, data)
      .then(() => {
        notification.success({
          message: t('amenities.add-new.create-success')
        });
        this.props.history.push('/dashboard/amenities/list');
      })
      .catch(() => {
        notification.error({
          message: t('amenities.add-new.create-error')
        });
      });
  };

  onCheckBoxActiveChange = (event) => {
    this.setState(
      update(this.state, {
        amenity: {
          active: { $set: event.target.checked }
        }
      })
    );
  };

  render() {
    const { classes, t } = this.props;
    return (
      <div>
        <Paper className={classes.paperWrapper}>
          <h1 className="title-wrapper">{t('amenities.add-new.amenity-info')}</h1>
          <form onSubmit={(e) => this.submitNewAmenity(e)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Όνομα"
                    name="nameEl"
                    onChange={this.onInputChange}
                    required
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Name"
                    name="nameEn"
                    onChange={this.onInputChange}
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="name"
                    label="名称"
                    name="nameZh"
                    onChange={this.onInputChange}
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid container>
                <FormControl className={classes.formControl} style={{ paddingTop: 10 }}>
                  <FormLabel component="legend">{t('common.status')}</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.amenity.active}
                        className="customCheckbox"
                        name="active"
                        onChange={this.onCheckBoxActiveChange}
                      />
                    }
                    label={
                      this.state.amenity.active
                        ? t('amenities.list.amenity-active')
                        : t('amenities.list.amenity-inactive')
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={10}>
              <Grid item xs={12}>
                <Button className={classes.button} type="submit" variant="contained">
                  {t('common.submit')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(withStyles(styles, { withTheme: true })(AddNewAmenity))
);
