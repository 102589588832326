import React from 'react';
import 'react-virtualized-select/styles.css';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import i18n from 'i18next';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import Select from 'react-virtualized-select';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { articleImagesUrl } from '../../config/config';
import { translateTitle } from '../../utils/utils';

const styles = () => ({
  card: {
    maxWidth: '100%'
  },
  media: {
    height: 140
  }
});

function RelatedArticles({
  classes,
  getRelatedArticles,
  handleRelatedArticleChange,
  loadingRelated,
  options,
  relatedArticles,
  relatedBox,
  removeArticle,
  t
}) {
  return (
    <>
      <Grid container>
        <Grid item md={6}>
          <Select
            async
            cacheOptions={false}
            closeMenuOnSelect={false}
            labelKey={
              i18n.language === 'el'
                ? 'titleEl'
                : i18n.language === 'en'
                ? 'titleEn'
                : 'titleZh'
            }
            loading={loadingRelated}
            loadingPlaceholder={t('blog.add-new.searching')}
            loadOptions={getRelatedArticles}
            multi
            onChange={handleRelatedArticleChange}
            options={options}
            placeholder={t('blog.add-new.enter-article-title')}
            searchPromptText={t('blog.add-new.enter-article-title-and-select')}
            style={{ width: '100%' }}
            value={relatedArticles}
            valueKey="_id"
          />
        </Grid>
      </Grid>

      {relatedBox ? (
        <Grid container style={{ marginTop: 25 }}>
          {relatedArticles.map((article, i) => (
            <Grid key={article._id} md={4} style={{ padding: 15 }}>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={`${articleImagesUrl}/${article._id}/${article.articleImage.image.name}`}
                    title="Contemplative Reptile"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {translateTitle(i18n.language, article)}
                    </Typography>
                    <p>{moment(article.createdAt).format('DD.MM.YYYY')}</p>
                  </CardContent>
                </CardActionArea>
                <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
                  <IconButton
                    aria-label="Delete"
                    className={classes.button}
                    color="secondary"
                    onClick={() => removeArticle(i)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : null}
    </>
  );
}

export default withStyles(styles, { withTheme: true })(RelatedArticles);
