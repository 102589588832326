import React, { Component } from 'react';
import Avatar from 'react-avatar-edit';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { apiUrl, axiosCallApi } from '../../config/config';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  button: {
    backgroundColor: 'transparent',
    border: '1px solid #000000',
    boxShadow: 'none',
    color: 'black',
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'black',
      color: '#FFFFFF'
    }
  },
  input: {
    display: 'flex',
    padding: 0
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%'
  },
  valueContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap'
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    fontSize: 16,
    left: 2,
    position: 'absolute'
  },
  paper: {
    marginTop: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(2)
  },
  selectControl: {
    position: 'absolute',
    width: '100%',
    zIndex: 99999
  },
  paperWrapper: {
    boxShadow: 'none',
    padding: 20
  }
});

class AddNewAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      city: '',
      email: '',
      image: '',
      mobilePhone: '',
      name: '',
      password: '',
      phone: '',
      postalCode: '',
      preview: false,
      property: '',
      src: '',
      surname: ''
    };
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  registerAgentAsUser = (id) => {
    const { t } = this.props;
    const data = {
      agentId: id,
      email: this.state.email,
      name: this.state.name,
      password: this.state.password,
      scopes: ['agent'],
      surname: this.state.surname
    };
    axiosCallApi
      .post(`${apiUrl}/admin/register`, data)
      .then(() => {
        notification.success({
          message: t('add-new-agent.response-success')
        });
        this.props.history.push('/dashboard/agents/list');
      })
      .catch(() => {
        notification.error({
          message: t('common.error-fetch')
        });
      });
  };

  submitNewAgent = (e) => {
    e.preventDefault();
    const { t } = this.props;
    axiosCallApi
      .post(`${apiUrl}/agent/new`, this.state)
      .then((res) => {
        this.registerAgentAsUser(res.data.newAgent._id);
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          if (error.response.status === 409) {
            notification.error({
              message: t('common.email-exists')
            });
          } else {
            notification.error({
              message: t('common.error-fetch')
            });
          }
        }
      });
  };

  onClose = () => {
    this.setState({ preview: null });
  };

  onCrop = (preview) => {
    this.setState({ preview });
    const data = {
      preview
    };
    axiosCallApi
      .post(`${apiUrl}/agent/upload`, data)
      .then((response) => this.setState({ image: response.data.image }))
      .catch((e) => console.log(e));
  };

  onBeforeFileLoad = (elem) => {
    const { t } = this.props;
    if (elem.target.files[0].size > 5000000) {
      // eslint-disable-next-line
      alert(t('add-new-agent.file-too-big'));
      elem.target.value = '';
    }
  };

  render() {
    const { classes, t } = this.props;
    const { preview, src } = this.state;

    return (
      <div>
        <Paper style={{ position: 'relative' }} className={classes.paperWrapper}>
          <h1 className="title-wrapper">
            <span>{t('add-new-agent.info')}</span>
          </h1>
          <form onSubmit={(e) => this.submitNewAgent(e)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 20 }}>
                  <TextField
                    fullWidth
                    id="name"
                    label={t('common.name')}
                    name="name"
                    onChange={this.onInputChange}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 20 }}>
                  <TextField
                    fullWidth
                    id="surname"
                    label={t('common.last-name')}
                    name="surname"
                    onChange={this.onInputChange}
                    required
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 20 }}>
                  <TextField
                    fullWidth
                    id="property"
                    label={t('add-new-agent.specialty')}
                    name="property"
                    onChange={this.onInputChange}
                    required
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 20 }}>
                  <TextField
                    fullWidth
                    id="password"
                    label={t('common.password')}
                    name="password"
                    inputProps={{
                      minLength: 6,
                      maxLength: 10
                    }}
                    onChange={this.onInputChange}
                    required
                    type="password"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 20 }}>
                  <TextField
                    fullWidth
                    id="phone"
                    label={t('common.phone')}
                    name="phone"
                    onChange={this.onInputChange}
                    type="number"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 20 }}>
                  <TextField
                    fullWidth
                    id="mobile"
                    label={t('common.mobile')}
                    name="mobilePhone"
                    onChange={this.onInputChange}
                    required
                    type="number"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 20 }}>
                  <TextField
                    fullWidth
                    id="email"
                    label={t('common.email')}
                    name="email"
                    onChange={this.onInputChange}
                    required
                    type="email"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 20 }}>
                  <TextField
                    fullWidth
                    id="city"
                    label={t('add-new-agent.city')}
                    name="city"
                    onChange={this.onInputChange}
                    required
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 20 }}>
                  <TextField
                    fullWidth
                    id="address"
                    label={t('add-new-agent.address')}
                    name="address"
                    onChange={this.onInputChange}
                    required
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 20 }}>
                  <TextField
                    fullWidth
                    id="zipCode"
                    label={t('add-new-agent.postal-code')}
                    name="postalCode"
                    onChange={this.onInputChange}
                    required
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div>
                  <div className="avatar-wrapper">
                    <div>
                      <span>{t('add-new-agent.choose-avatar')}</span>
                      <Avatar
                        height={200}
                        onBeforeFileLoad={this.onBeforeFileLoad}
                        onClose={this.onClose}
                        onCrop={this.onCrop}
                        onFileLoad={this.uploadImage}
                        src={src}
                        width={200}
                      />
                    </div>
                    {preview ? (
                      <div className="avatar-preview">
                        <span>{t('add-new-agent.preview-avatar')}</span>
                        <div id="avatar_container-9d8d-ba30-2b3a">
                          <div
                            className="konvajs-content preview-content"
                            role="presentation"
                          >
                            <canvas className="preview-canvas" height="200" width="200" />
                            <img src={preview} alt="Preview" />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Grid>
              <Grid container spacing={10}>
                <Grid item xs={12}>
                  <Button className={classes.button} type="submit" variant="contained">
                    {t('common.submit')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(withStyles(styles, { withTheme: true })(AddNewAgent))
);
