import ArticleList from '../components/Articles/ArticleList';
import AddNewArticle from '../components/Articles/AddNewArticle';
import EditArticle from '../components/Articles/EditArticle';
import AddNewCategory from '../components/Articles/AddNewCategory';

const articleRoutes = [
  {
    path: '/dashboard/articles/list',
    exact: false,
    name: 'List Articles',
    component: ArticleList
  },
  {
    path: '/dashboard/articles/add',
    exact: false,
    name: 'Add Articles',
    component: AddNewArticle
  },
  {
    path: '/dashboard/articles/edit/:id',
    exact: false,
    name: 'Edit Articles',
    component: EditArticle
  },
  {
    path: '/dashboard/articles/category',
    exact: false,
    name: 'Add Category',
    component: AddNewCategory
  }
];

export default articleRoutes;
