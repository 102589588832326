import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import socketIOClient from 'socket.io-client';
import Typography from '@material-ui/core/Typography';
import update from 'immutability-helper';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { connect } from 'react-redux';
import {
  CustomPaging,
  DataTypeProvider,
  PagingState,
  SearchState,
  SortingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  SearchPanel,
  Table,
  TableHeaderRow,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui';
import { Icon, notification, Tag } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { Loading } from '../UI-Elements/Loading';
import { apiUrl, axiosCallApi } from '../../config/config';
import PropertyModal from '../sharedComponents/PropertyModal';
import AgentModal from '../sharedComponents/AgentModal';
import * as actions from '../../redux/actions';

const language = localStorage.getItem('lng');

const styles = (theme) => ({
  button: {
    marginLeft: 25,
    marginTop: 25
  },
  header: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  appBar: {
    background: 'rgb(45, 52, 70)',
    position: 'relative'
  },
  flex: {
    flex: 1
  }
});

function StatusFormatter({ changeStatus, index, loading, loadingIndex, row, value }) {
  if (!value) {
    return (
      <Tag color="#f50" onClick={() => changeStatus(true, row)} className="read-request">
        {loading && loadingIndex === index ? (
          <Icon type="loading" />
        ) : (
          <Icon type="eye-invisible" />
        )}
      </Tag>
    );
  } else {
    return (
      <Tag
        color="#87d068"
        onClick={() => changeStatus(false, row)}
        className="read-request"
      >
        {loading && loadingIndex === index ? (
          <Icon type="loading" />
        ) : (
          <Icon type="eye" />
        )}
      </Tag>
    );
  }
}

function PhoneFormatter({ value }) {
  return <a href={`tel:${value}`}>{value}</a>;
}

function PhoneTypeProvider(props) {
  return <DataTypeProvider formatterComponent={PhoneFormatter} {...props} />;
}

function EmailFormatter({ value }) {
  return <a href={`mailto:${value}`}>{value}</a>;
}

function EmailTypeProvider(props) {
  return <DataTypeProvider formatterComponent={EmailFormatter} {...props} />;
}

function StatusTypeProvider(props) {
  return <DataTypeProvider formatterComponent={StatusFormatter} {...props} />;
}

function PropertyFormatter({ value, row }) {
  return (
    <a
      href={`https://www.thebrik.com/property/${row.property.slug}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {value}
    </a>
  );
}

function PropertyProvider(props) {
  return <DataTypeProvider formatterComponent={PropertyFormatter} {...props} />;
}

function AgentFormatter({ row, modal }) {
  return (
    <div className="agent-link" onClick={() => modal(row)}>
      {row.agent.name} {row.agent.surname}
    </div>
  );
}

function AgentProvider(props) {
  return <DataTypeProvider formatterComponent={AgentFormatter} {...props} />;
}

function ViewButton({ row, modal }) {
  return (
    <IconButton onClick={() => modal(row)} title="View Request">
      <VisibilityIcon />
    </IconButton>
  );
}

function Cell({
  agentModal,
  changeStatus,
  column,
  history,
  index,
  loading,
  loadingIndex,
  requestModal,
  row,
  style,
  value
}) {
  let content = value;
  if (column.name === 'view') {
    content = <ViewButton row={row} history={history} modal={requestModal} />;
  }
  if (column.name === 'agent') {
    content = (
      <AgentFormatter row={row} value={value} history={history} modal={agentModal} />
    );
  }
  if (column.name === 'email') {
    content = <EmailFormatter row={row} value={value} />;
  }
  if (column.name === 'phone') {
    content = <PhoneFormatter row={row} value={value} />;
  }
  if (column.name === 'agentPhone') {
    content = <PhoneFormatter row={row} value={value} />;
  }
  if (column.name === 'contactPhone') {
    content = <PhoneFormatter row={row} value={value} />;
  }
  if (column.name === 'read') {
    content = (
      <StatusFormatter
        changeStatus={changeStatus}
        index={index}
        loading={loading}
        loadingIndex={loadingIndex}
        row={row}
        value={value}
      />
    );
  }
  if (column.name === 'property') {
    content = <PropertyFormatter value={value} row={row} />;
  }
  return <Table.Cell style={style}>{content}</Table.Cell>;
}

class RequestsList extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      columns: [
        {
          name: 'createdAt',
          title: 'Date',
          getCellValue: (row) => moment(row.createdAt).format('DD/MM/YYYY HH:mm')
        },
        { name: 'read', title: 'Read Status', getCellValue: (row) => row.read },
        {
          name: 'name',
          title: t('requests.table.headers.client-name'),
          getCellValue: (row) => row.name
        },
        { name: 'email', title: 'Email', getCellValue: (row) => row.email },
        {
          name: 'phone',
          title: t('requests.table.headers.client-phone'),
          getCellValue: (row) => row.phone
        },
        {
          name: 'agent',
          title: t('requests.table.headers.agent')
        },
        {
          name: 'agentPhone',
          title: t('requests.table.headers.agent-phone'),
          getCellValue: (row) => row.agent.mobilePhone
        },
        {
          name: 'property',
          title: t('requests.table.headers.property'),
          getCellValue: (row) => (row.property ? row.property.titleEn : '')
        },
        { name: 'view', title: t('requests.table.headers.view-request') }
      ],
      tableColumnExtensions: [{ columnName: 'active', align: 'center' }],
      sortingStateColumnExtensions: [
        { columnName: 'edit', sortingEnabled: false },
        { columnName: 'view', sortingEnabled: false }
      ],
      agentColumns: ['agent'],
      agentModal: false,
      currentPage: 0,
      emailColumns: ['email'],
      endpoint: apiUrl,
      filter: '',
      key: 0,
      loading: true,
      loadingIndex: -1,
      loadingStatus: false,
      mobileColumns: ['mobile'],
      pageSizes: [5, 10, 15, 25, 50, 100],
      phoneColumns: ['contactPhone'],
      propertyColumns: ['property'],
      requestModal: false,
      rows: [],
      selectedRow: '',
      sorting: [{ columnName: 'createdAt', direction: 'asc' }],
      statusColumns: ['active'],
      totalCount: 0
    };

    this.loadDataDebounced = debounce(() => {
      this.loadData();
    }, 600);
  }

  componentDidMount() {
    this.loadDataDebounced();
    const { endpoint } = this.state;
    const socket = socketIOClient(endpoint);

    socket.on('new-request', (data) => {
      data.request.agent = data.agent;
      data.request.property = data.property;
      this.setState(
        update(this.state, {
          rows: {
            $apply: (items) => {
              items.unshift(data.request);
              return items;
            }
          },
          totalCount: {
            $set: this.state.totalCount + 1
          },
          key: {
            $set: this.state.key + 1
          }
        })
      );
    });

    socket.on('request-change-status', (data) => {
      const index = this.state.rows.findIndex((item) => data._id === item._id);
      this.setState(
        update(this.state, {
          rows: {
            [index]: {
              read: {
                $set: data.read
              }
            }
          }
        })
      );
    });

    socket.on('delete-request', (data) => {
      const index = this.state.rows.findIndex((item) => data.request._id === item._id);
      if (index !== -1) {
        this.setState(
          update(this.state, {
            rows: {
              $splice: [[index, 1]]
            },
            key: {
              $set: this.state.key + 1
            }
          })
        );
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.sorting !== prevState.sorting) {
      this.props.onCurrentPageChange(0);
    }
    if (
      this.props.currentPage !== prevProps.currentPage ||
      this.props.pageSize !== prevProps.pageSize ||
      this.state.filter !== prevState.filter ||
      this.state.sorting !== prevState.sorting
    ) {
      this.loadData();
    }
  }

  deleteRequest = (id) => {
    const index = this.state.rows.findIndex((item) => id === item._id);
    if (index !== -1) {
      this.setState(
        update(this.state, {
          totalCount: {
            $set: this.state.totalCount - 1
          },
          rows: {
            $splice: [[index, 1]]
          }
        })
      );
    }
  };

  handleClickOpenRequest = () => {
    this.setState({ requestModal: true });
  };

  handleCloseRequestModal = () => {
    this.setState({ requestModal: false, selectedRow: '' });
  };

  handleClickOpenAgentModal = () => {
    this.setState({ agentModal: true });
  };

  handleAgentModalClose = () => {
    this.setState({ agentModal: false, selectedRow: '' });
  };

  changeSorting = (sorting) => {
    this.setState({
      loading: true,
      sorting
    });
  };

  changePageSize = (pageSize) => {
    const { totalCount } = this.state;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.min(this.props.currentPage, totalPages - 1);

    this.setState(
      {
        loading: true
      },
      () => {
        this.props.onPageSizeChange(pageSize);
        this.props.onCurrentPageChange(currentPage);
      }
    );
  };

  changeCurrentPage = (currentPage) => {
    this.setState(
      {
        loading: true
      },
      () => this.props.onCurrentPageChange(currentPage)
    );
  };

  changeSearchValue = (searchValue) => {
    this.setState(
      {
        loading: true,
        filter: searchValue
      },
      () => this.props.onCurrentPageChange(0)
    );
  };

  showModal = (row) => {
    const { rows } = this.state;
    const index = rows.findIndex((item) => item._id === row._id);
    const data = {
      read: true
    };

    this.setState(
      update(this.state, {
        selectedRow: { $set: row },
        rows: {
          [index]: {
            read: { $set: true }
          }
        }
      })
    );

    this.handleClickOpenRequest();
    if (!rows[index].read) {
      axiosCallApi
        .patch(`${apiUrl}/request/status/${row._id}`, data)
        .catch((error) => console.log(error));
    }
  };

  showAgentModal = (row) => {
    this.setState({
      selectedRow: row
    });
    this.handleClickOpenAgentModal();
  };

  changeStatus = (status, row) => {
    const index = this.state.rows.findIndex((item) => item._id === row._id);
    this.setState({ loadingStatus: true, loadingIndex: index });
    const data = {
      read: status
    };

    axiosCallApi
      .patch(`${apiUrl}/request/status/${row._id}`, data)
      .then(() => {
        this.setState(
          update(this.state, {
            rows: {
              [index]: {
                read: { $set: status }
              }
            },
            loadingStatus: {
              $set: false
            },
            loadingIndex: {
              $set: -1
            }
          })
        );
      })
      .catch(() => {
        this.setState({ loadingStatus: false, loadingIndex: -1 });
      });
  };

  queryString() {
    const { sorting, filter } = this.state;
    const { pageSize, currentPage } = this.props;
    let queryString = `${apiUrl}/request/all/?perPage=${pageSize}&skip=${currentPage}&search=${filter}`;
    const columnSorting = sorting[0];
    if (columnSorting) {
      const sortingDirectionString = columnSorting.direction === 'desc' ? 1 : -1;
      queryString = `${queryString}&orderby=${columnSorting.columnName}`;
      queryString = `${queryString}&sort=${sortingDirectionString}`;
    }
    return queryString;
  }

  loadData() {
    const { t } = this.props;
    const queryString = this.queryString();
    if (queryString === this.lastQuery) {
      // this.setState({loading: false});
      return;
    }
    axiosCallApi
      .get(queryString)
      .then((response) => {
        this.setState({
          loading: false,
          rows: response.data.requests,
          totalCount: response.data.count
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        notification.error({ message: t('common.error-fetch') });
      });
    this.lastQuery = queryString;
  }

  render() {
    const { classes, currentPage, history, pageSize, pageSizes, t } = this.props;

    const {
      agentColumns,
      agentModal,
      columns,
      emailColumns,
      filter,
      key,
      loading,
      loadingIndex,
      loadingStatus,
      mobileColumns,
      phoneColumns,
      propertyColumns,
      requestModal,
      rows,
      selectedRow,
      sorting,
      sortingStateColumnExtensions,
      statusColumns,
      tableColumnExtensions,
      totalCount
    } = this.state;

    return (
      <>
        <Typography variant="h6" gutterBottom>
          {t('requests.list.list-requests')}
        </Typography>
        <Paper style={{ position: 'relative' }}>
          <Grid rows={rows} columns={columns} key={key}>
            <SortingState
              columnExtensions={sortingStateColumnExtensions}
              onSortingChange={this.changeSorting}
              sorting={sorting}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.changeCurrentPage}
              onPageSizeChange={this.changePageSize}
              pageSize={pageSize}
            />
            <CustomPaging totalCount={totalCount} />
            <SearchState onValueChange={this.changeSearchValue} value={filter} />
            <AgentProvider for={agentColumns} />
            <EmailTypeProvider for={emailColumns} />
            <PhoneTypeProvider for={mobileColumns} />
            <PhoneTypeProvider for={phoneColumns} />
            <PropertyProvider for={propertyColumns} />
            <StatusTypeProvider for={statusColumns} />
            <Table
              columnExtensions={tableColumnExtensions}
              cellComponent={({ column, value, style, row }) => (
                <Cell
                  agentModal={this.showAgentModal}
                  changeStatus={this.changeStatus}
                  column={column}
                  history={history}
                  index={rows.findIndex((item) => row._id === item._id)}
                  loading={loadingStatus}
                  loadingIndex={loadingIndex}
                  requestModal={this.showModal}
                  row={row}
                  style={style}
                  value={value}
                />
              )}
            />
            <TableHeaderRow showSortingControls />
            <Toolbar />
            <SearchPanel messages={{ searchPlaceholder: t('common.tables.search') }} />
            <PagingPanel
              pageSizes={pageSizes}
              messages={{
                showAll: t('common.tables.footer.all'),
                rowsPerPage: t('common.tables.footer.rows-per-page'),
                info: `${t('common.tables.footer.info.showing')} {from} ${t(
                  'common.tables.footer.info.to'
                )} {to} ({count} ${t('common.tables.footer.info.total-elements')})`
              }}
            />
          </Grid>
          {loading && <Loading />}
        </Paper>
        <AgentModal
          handleAgentModalClose={this.handleAgentModalClose}
          row={selectedRow}
          visible={agentModal}
        />
        <PropertyModal
          classes={classes}
          deleteRequest={this.deleteRequest}
          handleCloseRequestModal={this.handleCloseRequestModal}
          language={language}
          row={selectedRow}
          visible={requestModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentPage: state.requests.currentPage,
  pageSize: state.requests.pageSize,
  pageSizes: state.requests.pageSizes
});

const mapDispatchToProps = (dispatch) => ({
  onCurrentPageChange: (currentPage) =>
    dispatch(actions.createGridRequestsAction('currentPage', currentPage)),
  onPageSizeChange: (pageSize) =>
    dispatch(actions.createGridRequestsAction('pageSize', pageSize))
});

export default withTranslation()(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RequestsList))
  )
);
