import React from 'react';
import { Route } from 'react-router-dom';
import sectorRoutes from '../routes/sectors.routes';

function Sectors() {
  const routes = sectorRoutes.map((prop, key) => (
    <Route
      component={prop.component}
      exact={prop.exact}
      key={key.toString()}
      path={prop.path}
    />
  ));
  return <>{routes}</>;
}

export default Sectors;
