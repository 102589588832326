import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import Grid from '@material-ui/core/Grid';
import i18n from 'i18next';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import update from 'immutability-helper';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { translateName } from '../../utils/utils';
import { apiUrl, axiosCallApi } from '../../config/config';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  paperWrapper: {
    padding: 25,
    boxShadow: 'none'
  },
  button: {
    backgroundColor: 'transparent',
    border: '1px solid #000000',
    boxShadow: 'none',
    color: 'black',
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'black',
      color: '#FFFFFF'
    }
  },
  input: {
    display: 'flex',
    padding: 0
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%'
  },
  valueContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap'
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    fontSize: 16,
    left: 2,
    position: 'absolute'
  },
  paper: {
    marginTop: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(2)
  }
});

class EditPOI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nearby: {
        active: false,
        nameEl: '',
        nameEn: '',
        nameZh: ''
      },
      submitBtnIsDisabled: false
    };
  }

  componentDidMount() {
    const { match, t } = this.props;
    this.setState({ loading: true });
    axiosCallApi
      .get(`${apiUrl}/nearby/${match.params.id}`)
      .then((response) => {
        const { nearby } = response.data;
        this.setState({ nearby });
      })
      .catch(() => {
        notification.error({ message: t('common.error-fetch') });
      });
  }

  onInputChange = (e) => {
    this.setState(
      update(this.state, {
        nearby: {
          [e.target.name]: {
            $set: e.target.value
          }
        }
      })
    );
  };

  submitEditTransportation = (e) => {
    e.preventDefault();
    const data = this.state.nearby;
    const { match, t } = this.props;
    axiosCallApi
      .patch(`${apiUrl}/nearby/single/${match.params.id}`, data)
      .then(() => {
        notification.success({
          message: t('poi.edit.update-success')
        });
        this.props.history.push('/dashboard/nearby/list');
      })
      .catch(() => {
        notification.error({ message: t('poi.edit.update-error') });
      });
  };

  deleteTransportation = () => {
    const { match, t } = this.props;
    axiosCallApi
      .delete(`${apiUrl}/nearby/single/${match.params.id}`)
      .then(() => {
        notification.success({
          message: t('poi.edit.delete-success')
        });
        this.handleDeleteClose();
        this.props.history.push('/dashboard/nearby/list');
      })
      .catch(() => {
        notification.error({ message: t('poi.edit.delete-error') });
      });
  };

  handleDeleteOpen = () => {
    this.setState({ deleteDialog: true });
  };

  handleDeleteClose = () => {
    this.setState({ deleteDialog: false });
  };

  onCheckBoxActiveChange = (event) => {
    this.setState(
      update(this.state, {
        nearby: {
          active: { $set: event.target.checked }
        }
      })
    );
  };

  render() {
    const { classes, t, match } = this.props;
    const { nearby, deleteDialog } = this.state;
    return (
      <div>
        <Paper className={classes.paperWrapper}>
          <h1 className="title-wrapper">{t('poi.edit.edit-poi')}</h1>
          <form onSubmit={(e) => this.submitEditTransportation(e)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Όνομα"
                    name="nameEl"
                    onChange={this.onInputChange}
                    required
                    type="text"
                    value={nearby.nameEl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Name"
                    name="nameEn"
                    onChange={this.onInputChange}
                    type="text"
                    value={nearby.nameEn}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="name"
                    label="名称"
                    name="nameZh"
                    onChange={this.onInputChange}
                    type="text"
                    value={nearby.nameZh}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <FormControl className={classes.formControl}>
                <FormLabel component="legend">{t('common.status')}</FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nearby.active}
                      className="customCheckbox"
                      name="active"
                      onChange={this.onCheckBoxActiveChange}
                    />
                  }
                  label={
                    nearby.active
                      ? t('areas.list.area-active')
                      : t('areas.list.area-inactive')
                  }
                />
              </FormControl>
            </Grid>
            <Grid container spacing={10}>
              <Grid item xs={12}>
                <Button className={classes.button} type="submit" variant="contained">
                  {t('common.submit')}
                </Button>
                <Button
                  className="secondary rightButton deleteButton"
                  onClick={() => this.handleDeleteOpen()}
                  variant="contained"
                >
                  {t('poi.edit.delete')}
                </Button>
              </Grid>
            </Grid>
          </form>
          <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            onClose={this.handleDeleteClose}
            open={deleteDialog}
          >
            <DialogTitle style={{ background: 'red' }} id="alert-dialog-title">
              <span style={{ color: 'white' }}>
                {t('poi.edit.are-you-sure')}
                <em>{translateName(i18n.language, nearby)}</em>;
              </span>
            </DialogTitle>
            <DialogContent style={{ background: 'red', color: 'white' }}>
              <DialogContentText
                style={{ background: 'red', color: 'white' }}
                id="alert-dialog-description"
              >
                <strong>{t('poi.edit.attention')}</strong> {t('poi.edit.cannot-revert')}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                background: 'red',
                margin: 0,
                paddingBottom: 8,
                paddingRight: 4,
                paddingTop: 8
              }}
            >
              <Button autoFocus onClick={this.handleDeleteClose} style={{ outline: 0 }}>
                {t('common.cancel')}
              </Button>
              <Button
                style={{ outline: 0, color: 'white' }}
                onClick={() => this.deleteTransportation(match.params.id)}
              >
                {t('poi.edit.delete')}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(withStyles(styles, { withTheme: true })(EditPOI))
);
