import React, { Component, Suspense } from 'react';
import 'moment/locale/el';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { notification } from 'antd';
import { Route, Switch, withRouter } from 'react-router-dom';
import Page404 from './components/sharedComponents/Page404';
import Login from './components/Auth/Login';
import AuthGuard from './hoc/AuthGuard/AuthGuard';

moment.locale('el');

notification.config({
  duration: 5,
  placement: 'topRight',
  top: 100
});

const THEME = createTheme({
  typography: {
    fontFamily: 'Product-Sans-Regular',
    useNextVariants: true
  }
});

function Loader() {
  return (
    <div className="App">
      <div>loading...</div>
    </div>
  );
}

class App extends Component {
  componentDidMount() {
    const notifications = localStorage.getItem('notificationSound');
    if (!notifications) {
      localStorage.setItem('notificationSound', 'true');
    }
  }

  render() {
    const routes = (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route path="/" component={AuthGuard} />
        <Route component={Page404} />
      </Switch>
    );

    return (
      <Suspense fallback={<Loader />}>
        <MuiThemeProvider theme={THEME}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="el" moment={moment}>
            {routes}
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </Suspense>
    );
  }
}

export default withRouter(App);
