import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import debounce from 'lodash.debounce';
import EditIcon from '@material-ui/icons/Edit';
import FiberIcon from '@material-ui/icons/FiberManualRecord';
import i18n from 'i18next';
import IconButton from '@material-ui/core/IconButton';
// import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { connect } from 'react-redux';
import {
  CustomPaging,
  DataTypeProvider,
  PagingState,
  SearchState,
  SortingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  SearchPanel,
  Table,
  TableHeaderRow,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui';
import { Link, withRouter } from 'react-router-dom';
import { notification } from 'antd';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { translateHouseTypes, translateName } from '../../utils/utils';
import { Loading } from '../UI-Elements/Loading';
import { apiUrl, axiosCallApi } from '../../config/config';
import AgentModal from '../sharedComponents/AgentModal';
import * as actions from '../../redux/actions';

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  popper: {
    fontSize: 18
  },
  iconSmall: {
    fontSize: 20
  },
  chip: {
    top: 0,
    left: 0,
    margin: theme.spacing(1)
  }
});

function PhoneFormatter({ value }) {
  return <a href={`tel:${value}`}>{value}</a>;
}

function PhoneTypeProvider(props) {
  return <DataTypeProvider formatterComponent={PhoneFormatter} {...props} />;
}

function StatusFormatter({ value }) {
  if (value === 'Yes') {
    return (
      <p style={{ marginTop: 5, textAlign: 'center' }}>
        <span className="active-product">{value}</span>
      </p>
    );
  } else {
    return (
      <p style={{ marginTop: 5, textAlign: 'center' }}>
        <span className="not-active-product">{value}</span>
      </p>
    );
  }
}

function StatusTypeProvider(props) {
  return <DataTypeProvider formatterComponent={StatusFormatter} {...props} />;
}

function EditButton({ row, history }) {
  return (
    <IconButton
      onClick={() => {
        history.push(`/dashboard/properties/edit/${row._id}`);
      }}
      title="Edit row"
    >
      <EditIcon />
    </IconButton>
  );
}

function AgentFormatter({ row, modal }) {
  return (
    <div className="agent-link" onClick={() => modal(row)}>
      {row.agent ? `${row.agent.name} ${row.agent.surname}` : null}
    </div>
  );
}

function AgentProvider(props) {
  return <DataTypeProvider formatterComponent={AgentFormatter} {...props} />;
}

function ViewButton({ row }) {
  return (
    <a
      href={`https://www.thebrik.com/property/${row.slug}`}
      rel="noopener noreferrer"
      target="_blank"
    >
      <IconButton title="View Request">
        <VisibilityIcon />
      </IconButton>
    </a>
  );
}

const Cell = withRouter(({ column, value, style, row, history, agentModal }) => {
  let content = value;
  if (column.name === 'view') {
    content = <ViewButton row={row} history={history} />;
  }
  if (column.name === 'edit') {
    content = <EditButton row={row} history={history} />;
  }
  if (column.name === 'mobile') {
    content = <PhoneFormatter row={row} value={value} />;
  }
  if (column.name === 'mobilePhone') {
    content = <PhoneFormatter row={row} value={value} />;
  }
  if (column.name === 'active') {
    content = <StatusFormatter value={value} />;
  }
  if (column.name === 'agentName') {
    content = (
      <AgentFormatter history={history} modal={agentModal} row={row} value={value} />
    );
  }
  return <Table.Cell style={style}>{content}</Table.Cell>;
});

class PropertiesList extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      columns: [
        {
          name: 'propertyId',
          title: t('common.tables.headers.property-id'),
          // getCellValue: (row) => moment(row.createdAt).format('DD/MM/YYYY HH:mm')
          getCellValue: (row) => row.propertyId
        },
        {
          name: 'region',
          title: t('list-properties.headers.area'),
          getCellValue: (row) => translateName(i18n.language, row.region)
        },
        {
          name: 'city',
          title: t('list-properties.headers.city'),
          getCellValue: (row) => translateName(i18n.language, row.city)
        },
        {
          name: 'price',
          title: t('list-properties.headers.price'),
          getCellValue: (row) => row.price
        },
        {
          name: 'property-type',
          title: t('list-properties.headers.property-type'),
          getCellValue: (row) => translateHouseTypes(t, row.type.value)
        },
        {
          name: 'listingStatus',
          title: t('list-properties.headers.listing-status'),
          getCellValue: (row) =>
            row.approved ? (
              <Tooltip title={t('list-properties.listing-status.published')}>
                <FiberIcon style={{ color: '#87d068' }} aria-label="Delete" />
              </Tooltip>
            ) : (
              <Tooltip title={t('list-properties.listing-status.draft')}>
                <FiberIcon style={{ color: 'orange' }} aria-label="Delete" />
              </Tooltip>
            )
        },
        {
          name: 'propertyStatus',
          title: t('list-properties.headers.property-status'),
          getCellValue: (row) =>
            row.condition.value === 'available' ? (
              <Tooltip title={t('list-properties.property-status.available')}>
                <FiberIcon
                  style={{ color: '#87d068' }}
                  aria-label={row.condition.label}
                />
              </Tooltip>
            ) : row.condition.value === 'under-offer' ? (
              <Tooltip title={t('list-properties.property-status.under-offer')}>
                <FiberIcon
                  style={{ color: '#2db7f5' }}
                  aria-label={row.condition.label}
                />
              </Tooltip>
            ) : row.condition.value === 'advanced-payment' ? (
              <Tooltip title={t('list-properties.property-status.advanced-payment')}>
                <FiberIcon
                  style={{ color: '#108ee9' }}
                  aria-label={row.condition.label}
                />
              </Tooltip>
            ) : (
              <Tooltip title={t('list-properties.property-status.sold')}>
                <FiberIcon style={{ color: '#f50' }} aria-label={row.condition.label} />
              </Tooltip>
            )
        },
        {
          name: 'agentName',
          title: t('list-properties.headers.agent-name'),
          getCellValue: (row) =>
            row.agent ? `${row.agent.name} ${row.agent.surname}` : ''
        },
        {
          name: 'mobilePhone',
          title: t('list-properties.headers.agent-phone'),
          getCellValue: (row) => (row.agent ? `${row.agent.mobilePhone}` : '')
        },
        { name: 'edit', title: t('common.tables.headers.edit') },
        { name: 'view', title: t('list-properties.headers.view-property') }
      ],
      tableColumnExtensions: [{ columnName: 'active', align: 'center' }],
      sortingStateColumnExtensions: [{ columnName: 'edit', sortingEnabled: false }],
      agentColumns: ['agentName'],
      agentModal: false,
      filter: '',
      loading: true,
      mobileColumns: ['mobile'],
      phoneColumns: ['telephone'],
      rows: [],
      selectedRow: '',
      sorting: [{ columnName: 'createdAt', direction: 'asc' }],
      statusColumns: ['active'],
      totalCount: 0
    };
    this.loadDataDebounced = debounce(() => {
      this.loadData();
    }, 600);
  }

  componentDidMount() {
    axiosCallApi.post(`${apiUrl}/admin/getRole`).then((response) => {
      // eslint-disable-next-line
      this.setState({ scopes: response.data.scopes });
    });
    this.loadDataDebounced();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.sorting !== prevState.sorting) {
      this.props.onCurrentPageChange(0);
    }
    if (
      this.props.currentPage !== prevProps.currentPage ||
      this.props.pageSize !== prevProps.pageSize ||
      this.state.filter !== prevState.filter ||
      this.state.sorting !== prevState.sorting
    ) {
      this.loadData();
    }
  }

  changeSorting = (sorting) => {
    this.setState({
      loading: true,
      sorting
    });
  };

  changePageSize = (pageSize) => {
    const { totalCount } = this.state;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.min(this.props.currentPage, totalPages);

    this.setState(
      {
        loading: true
      },
      () => {
        this.props.onPageSizeChange(pageSize);
        this.props.onCurrentPageChange(currentPage);
      }
    );
  };

  changeCurrentPage = (currentPage) => {
    this.setState(
      {
        loading: true
      },
      () => this.props.onCurrentPageChange(currentPage)
    );
  };

  changeSearchValue = (searchValue) => {
    this.setState(
      {
        loading: true,
        filter: searchValue
      },
      () => this.props.onCurrentPageChange(0)
    );
  };

  showAgentModal = (row) => {
    this.setState({
      selectedRow: row
    });
    this.handleClickOpenAgentModal();
  };

  handleClickOpenAgentModal = () => {
    this.setState({ agentModal: true });
  };

  handleAgentModalClose = () => {
    this.setState({ agentModal: false, selectedRow: '' });
  };

  queryString() {
    const { sorting, filter } = this.state;
    const { pageSize, currentPage } = this.props;
    let queryString = `${apiUrl}/property/all/?perPage=${pageSize}&skip=${currentPage}&filter=${filter}`;
    const columnSorting = sorting[0];
    if (columnSorting) {
      const sortingDirectionString = columnSorting.direction === 'desc' ? 1 : -1;
      queryString = `${queryString}&orderby=${columnSorting.columnName}`;
      queryString = `${queryString}&sort=${sortingDirectionString}`;
    }
    return queryString;
  }

  loadData() {
    const { t } = this.props;
    const queryString = this.queryString();
    if (queryString === this.lastQuery) {
      // this.setState({ loading: false });
      return;
    }
    axiosCallApi
      .get(queryString)
      .then((response) => {
        this.setState({
          loading: false,
          rows: response.data.properties,
          totalCount: response.data.count
        });
      })
      .catch(() => {
        notification.error({
          message: t('common.error-fetch')
        });
        this.setState({ loading: false });
      });
    this.lastQuery = queryString;
  }

  render() {
    const { classes, currentPage, pageSize, pageSizes, t } = this.props;
    const {
      agentColumns,
      agentModal,
      columns,
      filter,
      loading,
      mobileColumns,
      phoneColumns,
      rows,
      selectedRow,
      sorting,
      sortingStateColumnExtensions,
      statusColumns,
      tableColumnExtensions,
      totalCount
    } = this.state;

    return (
      <>
        <Typography variant="h6" gutterBottom>
          {t('list-properties.list-properties')}
          <Button
            className={[classes.button, 'secondaryBtnColor'].join(' ')}
            color="secondary"
            component={Link}
            to="/dashboard/properties/add"
            variant="contained"
          >
            <AddIcon className={classes.leftIcon} />
            {t('list-properties.add-new')}
          </Button>
        </Typography>
        <Paper style={{ position: 'relative' }}>
          <Grid rows={rows} columns={columns}>
            <SortingState
              columnExtensions={sortingStateColumnExtensions}
              onSortingChange={this.changeSorting}
              sorting={sorting}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.changeCurrentPage}
              onPageSizeChange={this.changePageSize}
              pageSize={pageSize}
            />
            <CustomPaging totalCount={totalCount} />
            <SearchState onValueChange={this.changeSearchValue} value={filter} />
            <AgentProvider for={agentColumns} />
            <PhoneTypeProvider for={mobileColumns} />
            <PhoneTypeProvider for={phoneColumns} />
            <StatusTypeProvider for={statusColumns} />
            <Table
              columnExtensions={tableColumnExtensions}
              cellComponent={({ column, value, style, row }) => (
                <Cell
                  agentModal={this.showAgentModal}
                  row={row}
                  column={column}
                  value={value}
                  style={style}
                />
              )}
            />
            <TableHeaderRow showSortingControls />
            <Toolbar />
            <SearchPanel messages={{ searchPlaceholder: t('common.tables.search') }} />
            <PagingPanel
              pageSizes={pageSizes}
              messages={{
                showAll: t('common.tables.footer.all'),
                rowsPerPage: t('common.tables.footer.rows-per-page'),
                info: `${t('common.tables.footer.info.showing')} {from} ${t(
                  'common.tables.footer.info.to'
                )} {to} ({count} ${t('common.tables.footer.info.total-elements')})`
              }}
            />
          </Grid>
          {loading && <Loading />}
        </Paper>
        <AgentModal
          row={selectedRow}
          handleAgentModalClose={this.handleAgentModalClose}
          visible={agentModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentPage: state.properties.currentPage,
  pageSize: state.properties.pageSize,
  pageSizes: state.properties.pageSizes
});

const mapDispatchToProps = (dispatch) => ({
  onCurrentPageChange: (currentPage) =>
    dispatch(actions.createGridPropertiesAction('currentPage', currentPage)),
  onPageSizeChange: (pageSize) =>
    dispatch(actions.createGridPropertiesAction('pageSize', pageSize))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withStyles(styles, { withTheme: true })(PropertiesList))));
