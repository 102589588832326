import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import GridCol from '@material-ui/core/Grid';
import i18n from 'i18next';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import MatToolbar from '@material-ui/core/Toolbar';
import MobileStepper from '@material-ui/core/MobileStepper';
import moment from 'moment';
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';
import Slide from '@material-ui/core/Slide';
import { notification } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { translateName } from '../../utils/utils';
import { apiUrl, avatarUrl, axiosCallApi, imagesUrl } from '../../config/config';
import GoogleMap from '../GoogleMap/GoogleMap';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 400
  },
  header: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: 50,
    paddingLeft: theme.spacing(4)
  }
});

class PropertyModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: '0',
      deleteRequestDialog: false
    };
  }

  handleClickOpenDeleteDialog = () => {
    this.setState({ deleteRequestDialog: true });
  };

  handleCloseDeleteDialog = () => {
    this.setState({ deleteRequestDialog: false });
  };

  deleteRequest = (id) => {
    const { row, deleteRequest, handleCloseRequestModal, t } = this.props;
    axiosCallApi
      .delete(`${apiUrl}/request/${row._id}`)
      .then(() => {
        notification.success({
          message: t('requestPages.delete-success')
        });
        deleteRequest(id);
        this.handleCloseDeleteDialog();
        handleCloseRequestModal();
      })
      .catch(() => {
        notification.error({
          message: t('requestPages.delete-error')
        });
      });
  };

  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: (prevState.activeStep + 1).toString()
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: (prevState.activeStep - 1).toString()
    }));
  };

  render() {
    const { classes, handleCloseRequestModal, language, row, t, theme, visible } =
      this.props;
    const maxSteps = row ? row.property.images.length : '';
    return (
      <Dialog
        className="request-modal"
        fullScreen
        onClose={handleCloseRequestModal}
        open={visible}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <MatToolbar className="request-modal-toolbar">
            <h2>{t('requestPages.request-info')}</h2>
            <div className="request-modal-icons-section">
              <Button
                onClick={this.handleClickOpenDeleteDialog}
                style={{
                  background: '#FD3D78',
                  color: 'white',
                  marginRight: 20
                }}
                className="custom-btn"
                variant="contained"
              >
                <DeleteIcon className="button-icon" />
                {t('requestPages.delete-request')}
              </Button>
              <ReactToPrint
                trigger={() => (
                  <PrintIcon className="print-button" style={{ marginRight: 30 }} />
                )}
                content={() => this.componentRef}
              />
              <IconButton
                aria-label="Close"
                color="inherit"
                onClick={handleCloseRequestModal}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </MatToolbar>
        </AppBar>
        <div ref={(el) => (this.componentRef = el)}>
          <GridCol className="request-modal-info-section" container spacing={24}>
            <GridCol item xs={12} sm={12} md={4}>
              <div className="card-wrapper">
                <h2 className="request-title">{t('requestPages.request-info')}</h2>
                <div className="request-info-title">
                  <span className="label">{t('requestPages.created-at')}</span>{' '}
                  {moment(row.createdAt).format('DD/MM/YYYY HH:mm')}
                </div>
                <div className="request-info-title">
                  <span className="label">{t('requestPages.client-name')}</span>{' '}
                  {row.name}
                </div>
                <div className="request-info-title">
                  <span className="label">{t('requestPages.client-phone')}</span>{' '}
                  <a href={`tel:${row.phone}`}>{row.phone}</a>
                </div>
                <div className="request-info-title">
                  <span className="label">{t('requestPages.client-email')}</span>{' '}
                  <a href={`mailto:${row.email}`}>{row.email}</a>
                </div>
                <div className="request-info-title">
                  <span className="label">{t('requestPages.client-message')}</span>{' '}
                  {row.message}
                </div>
              </div>
            </GridCol>
            <GridCol item xs={12} sm={12} md={4}>
              <div className="card-wrapper">
                <h2 className="request-title">{t('requestPages.agent-info')}</h2>
                <div className="agent-info-section">
                  <div>
                    <div className="request-info-title">
                      <span className="label">{t('requestPages.agent-name')}</span>{' '}
                      {row ? row.agent.name : ''}
                    </div>
                    <div className="request-info-title">
                      <span className="label"> {t('requestPages.agent-last-name')}</span>{' '}
                      {row ? row.agent.surname : ''}
                    </div>
                    <div className="request-info-title">
                      <span className="label"> {t('requestPages.agent-phone')}</span>{' '}
                      <a href={`tel:${row ? row.agent.phone : ''}`}>
                        {row ? row.agent.phone : ''}
                      </a>
                    </div>
                    <div className="request-info-title">
                      <span className="label"> {t('requestPages.agent-mobile')}</span>{' '}
                      <a href={`tel:${row ? row.agent.mobilePhone : ''}`}>
                        {row ? row.agent.mobilePhone : ''}
                      </a>
                    </div>
                    <div className="request-info-title">
                      <span className="label"> {t('requestPages.agent-email')}</span>{' '}
                      <a href={`mailto:${row ? row.agent.email : ''}`}>
                        {row ? row.agent.email : ''}
                      </a>
                    </div>
                  </div>
                  {row ? (
                    <img
                      alt="#"
                      src={
                        row.agent.image
                          ? `${avatarUrl}/${row.agent.uid}/${row.agent.image}`
                          : `${avatarUrl}/dummy_agent_round.png`
                      }
                    />
                  ) : null}
                </div>
              </div>
            </GridCol>
            <GridCol item xs={12} sm={12} md={4} className="">
              <div className="card-wrapper">
                <h2 className="request-title"> {t('requestPages.property-info')}</h2>
                <div className="request-info-title">
                  <span className="label">{t('requestPages.property-title')}</span>{' '}
                  {row
                    ? language === 'el'
                      ? row.property.titleEl
                      : language === 'en'
                      ? row.property.titleEn
                      : row.property.titleZh
                    : ''}
                </div>
                <div className="request-info-title">
                  <span className="label">{t('requestPages.property-area')}</span>{' '}
                  {row ? translateName(i18n.language, row.property.region) : ''}
                </div>
                <div className="request-info-title">
                  <span className="label"> {t('requestPages.property-status')}</span>{' '}
                  {row ? row.property.condition.label : ''}
                </div>
                <div className="request-info-title">
                  <span className="label"> {t('requestPages.property-price')}</span>{' '}
                  {row ? row.property.price : ''}€
                </div>
              </div>
            </GridCol>
          </GridCol>
          <GridCol className="request-modal-property-section" container spacing={24}>
            <GridCol item xs={12} sm={12} md={6}>
              <div className="card-wrapper">
                <h2 className="request-title">{t('requestPages.property-images')}</h2>
                {maxSteps > 0 ? (
                  <img
                    className="img-fluid"
                    src={`${imagesUrl}/${row.property._id}/${
                      row.property.images[Number(this.state.activeStep)].image.name
                    }`}
                    alt={row.property.images[Number(this.state.activeStep)].label}
                  />
                ) : null}
                <MobileStepper
                  activeStep={this.state.activeStep}
                  className={classes.mobileStepper}
                  position="static"
                  steps={maxSteps}
                  nextButton={
                    <Button
                      size="small"
                      onClick={this.handleNext}
                      disabled={this.state.activeStep === (maxSteps - 1).toString()}
                    >
                      {t('requestPages.property-images-next')}
                      {theme.direction === 'rtl' ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button
                      disabled={this.state.activeStep === '0'}
                      onClick={this.handleBack}
                      size="small"
                    >
                      {theme.direction === 'rtl' ? (
                        <KeyboardArrowRight />
                      ) : (
                        <KeyboardArrowLeft />
                      )}
                      {t('requestPages.property-images-previous')}
                    </Button>
                  }
                />
              </div>
            </GridCol>
            <GridCol className="request-modal-map-section" item md={6} sm={12} xs={12}>
              <div className="card-wrapper">
                <h2 className="request-title">{t('requestPages.property-location')}</h2>
                <div style={{ height: 420 }}>
                  <GoogleMap
                    center={[
                      row ? row.property.fakeLat : '',
                      row ? row.property.fakeLng : ''
                    ]}
                    lat={row ? row.property.fakeLat : ''}
                    lng={row ? row.property.fakeLng : ''}
                    name={row ? row.property.titleEn : ''}
                  />
                </div>
              </div>
            </GridCol>
          </GridCol>
        </div>
        <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          onClose={this.handleCloseDeleteDialog}
          open={this.state.deleteRequestDialog}
        >
          <DialogTitle id="alert-dialog-title" style={{ background: '#FD3D78' }}>
            <span style={{ color: 'white' }}>{t('requestPages.are-you-sure')}</span>
          </DialogTitle>
          <DialogContent style={{ background: '#FD3D78' }}>
            <div style={{ color: 'white' }} className="request-info-title">
              <span style={{ color: 'white' }} className="label">
                {t('requestPages.created-at')}
              </span>{' '}
              {moment(row.createdAt).format('DD/MM/YYYY HH:mm')}
            </div>
            <div style={{ color: 'white' }} className="request-info-title">
              <span style={{ color: 'white' }} className="label">
                {t('requestPages.client-name')}
              </span>{' '}
              {row.name}
            </div>
            <div style={{ color: 'white' }} className="request-info-title">
              <span style={{ color: 'white' }} className="label">
                {t('requestPages.client-phone')}
              </span>{' '}
              <a style={{ color: 'white' }} href={`tel:${row.phone}`}>
                {row.phone}
              </a>
            </div>
            <div style={{ color: 'white' }} className="request-info-title">
              <span style={{ color: 'white' }} className="label">
                {t('requestPages.client-email')}
              </span>{' '}
              <a style={{ color: 'white' }} href={`mailto:${row.email}`}>
                {row.email}
              </a>
            </div>
            <div style={{ color: 'white' }} className="request-info-title">
              <span style={{ color: 'white' }} className="label">
                {t('requestPages.client-message')}
              </span>{' '}
              {row.message}
            </div>
            <div style={{ color: 'white', marginTop: 25 }}>
              <strong>{t('requestPages.attention')}</strong>{' '}
              {t('requestPages.cannot-revert')}
            </div>
          </DialogContent>
          <DialogActions
            style={{
              background: '#FD3D78',
              margin: 0,
              paddingBottom: 8,
              paddingRight: 4,
              paddingTop: 8
            }}
          >
            <Button
              onClick={this.handleCloseDeleteDialog}
              style={{ border: '1px solid #000000' }}
              autoFocus
            >
              {t('common.cancel')}
            </Button>
            <Button
              autoFocus
              onClick={() => this.deleteRequest(row._id)}
              style={{ outline: 0, color: 'white', border: '1px solid white' }}
            >
              {t('requestPages.delete')}
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    );
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true })(PropertyModal));
