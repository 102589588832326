import * as actionTypes from './actionTypes';

// eslint-disable-next-line
export const createGridAgentPropertiesAction = (partialStateName, partialStateValue) => ({
  type: actionTypes.GRID_STATE_AGENT_PROPERTIES_CHANGE,
  partialStateName,
  partialStateValue
});

// eslint-disable-next-line
export const resetGridAgentPropertiesAction = () => ({
  type: actionTypes.GRID_STATE_RESET_AGENT_PROPERTIES
});
