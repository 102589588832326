import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { notification } from 'antd';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { apiUrl } from '../../config/config';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid #000000',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'black',
      color: '#FFFFFF'
    }
  },
  input: {
    display: 'flex',
    padding: 0
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center'
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    marginTop: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(2)
  }
});

notification.config({
  placement: 'topRight',
  top: 24,
  duration: 3
});

class AddNewCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ''
    };
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitNewCategory = (e) => {
    e.preventDefault();
    const data = {
      title: this.state.title
    };
    axios
      .post(`${apiUrl}/categories`, data)
      .then(() => {
        notification.success({
          message: 'Η κατηγορία προστέθηκε επιτυχώς'
        });
        this.props.history.push('/dashboard/articles/list');
      })
      .catch((err) => {
        notification.error({
          message: 'Παρουσιάστηκε κάποιο σφάλμα',
          description: err
        });
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Typography variant="h6" gutterBottom>
          Προσθήκη Νέας Κατηγορίας
        </Typography>
        <form onSubmit={(e) => this.submitNewCategory(e)}>
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl className={classes.formControl}>
                <TextField
                  onChange={this.onInputChange}
                  required
                  id="title"
                  name="title"
                  label="Όνομα Κατηγορίας"
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid container spacing={10}>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" className={classes.button}>
                  Αποθηκευση
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

export default withRouter(withStyles(styles, { withTheme: true })(AddNewCategory));
