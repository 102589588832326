import PropertyStatisticsList from '../components/PropertyStatistics/PropertyStatisticsList';

const propertyStatisticsRoutes = [
  {
    path: '/dashboard/property-statistics/list',
    exact: false,
    name: 'List Notifications',
    component: PropertyStatisticsList
  }
];

export default propertyStatisticsRoutes;
