import RequestsList from '../components/Requests/RequestsList';
import ViewRequest from '../components/Requests/ViewRequest';

const requestRoutes = [
  {
    path: '/dashboard/requests/list',
    exact: true,
    name: 'List Request',
    component: RequestsList
  },
  {
    path: '/dashboard/requests/view/:id',
    exact: true,
    name: 'View Request',
    component: ViewRequest
  }
];

export default requestRoutes;
