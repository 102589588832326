import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import agentPropertiesReducers from './reducers/gridAgentProperties.reducer';
import agentRequestsReducers from './reducers/gridAgentRequests.reducer';
import agentsReducer from './reducers/gridAgents.reducer';
import amenitiesReducer from './reducers/gridAmenities.reducer';
import blogReducer from './reducers/gridBlog.reducer';
import comfortsReducer from './reducers/gridComforts.reducer';
import contentManagersReducer from './reducers/gridContentManager.reducer';
import nearbyReducer from './reducers/gridNearby.reducer';
import neighborhoodReducer from './reducers/gridNeighborhood.reducer';
import cityReducer from './reducers/gridCity.reducer';
import sectorReducer from './reducers/gridSector.reducer';
import propertiesReducer from './reducers/gridProperties.reducer';
import requestsReducer from './reducers/gridRequests.reducer';
import transportsReducer from './reducers/gridTransports.reducer';

const rootReducer = combineReducers({
  agentProperties: agentPropertiesReducers,
  agentRequests: agentRequestsReducers,
  agents: agentsReducer,
  amenities: amenitiesReducer,
  blog: blogReducer,
  cms: contentManagersReducer,
  comforts: comfortsReducer,
  nearby: nearbyReducer,
  neighborhood: neighborhoodReducer,
  city: cityReducer,
  sector: sectorReducer,
  properties: propertiesReducer,
  requests: requestsReducer,
  transports: transportsReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let middleware = [thunk];

const reduxImmutableStateInvariant = require('redux-immutable-state-invariant').default();

const logger = createLogger({ collapsed: true });
middleware = [...middleware, logger, reduxImmutableStateInvariant];
const enhancer = composeEnhancers(applyMiddleware(...middleware));

export default function configureStore() {
  return createStore(rootReducer, {}, enhancer);
}
