// console.log('process.env.NODE_ENV', process.env.NODE_ENV)

import React from 'react';
import './i18n';
import './react-select.min.css';
import './index.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import App from './App';
import configureStore from './redux/configureStore';

const store = configureStore();

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept();
}
