import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import update from 'immutability-helper';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { apiUrl, axiosCallApi } from '../../config/config';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  button: {
    backgroundColor: 'transparent',
    border: '1px solid #000000',
    boxShadow: 'none',
    color: 'black',
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'black',
      color: '#FFFFFF'
    }
  },
  input: {
    display: 'flex',
    padding: 0
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%'
  },
  valueContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    fontSize: 16,
    left: 2,
    position: 'absolute'
  },
  paper: {
    marginTop: theme.spacing(1)
  },
  selectControl: {
    position: 'absolute',
    width: '100%',
    zIndex: 99999
  },
  divider: {
    height: theme.spacing(2)
  },
  paperWrapper: {
    boxShadow: 'none',
    padding: 20
  }
});

class AddWebsiteUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isAdminOrCM: false,
      submitBtnIsDisabled: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    axiosCallApi.post(`${apiUrl}/admin/getRole`).then((response) => {
      this.setState({
        isAdminOrCM: response.data.scopes.includes('admin')
      });
    });
  }

  submitAddWebsiteUser = (e) => {
    e.preventDefault();
    const { t } = this.props;
    const data = {
      email: this.state.email,
      password: this.state.password
    };
    axiosCallApi
      .post(`${apiUrl}/public/register`, data)
      .then(() => {
        notification.success({
          message: t('website-user.response-add-success')
        });
        this.props.history.push('/dashboard/properties/list');
      })
      .catch(() => {
        notification.error({
          message: t('common.error-fetch')
        });
      });
  };

  onInputChange = (e) => {
    this.setState(
      update(this.state, {
        [e.target.name]: {
          $set: e.target.value
        }
      })
    );
  };

  render() {
    const { classes, history, t } = this.props;

    return (
      <div>
        <Paper style={{ position: 'relative' }} className={classes.paperWrapper}>
          <h1 className="title-wrapper">
            <span>{t('website-user.add-website-user')}</span>
          </h1>
          <form onSubmit={(e) => this.submitAddWebsiteUser(e)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 25 }}>
                  <TextField
                    fullWidth
                    id="email"
                    label={t('website-user.email')}
                    name="email"
                    onChange={this.onInputChange}
                    required
                    type="email"
                    value={this.state.email}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 25 }}>
                  <TextField
                    fullWidth
                    id="password"
                    label={t('website-user.password')}
                    name="password"
                    onChange={this.onInputChange}
                    required
                    type="password"
                    value={this.state.password}
                  />
                </FormControl>
              </Grid>
              <Grid container spacing={10}>
                <Grid item xs={12}>
                  <Button className={classes.button} type="submit" variant="contained">
                    {t('website-user.Create-user')}
                  </Button>
                  <Button
                    className={classes.button}
                    onClick={() => history.push('/dashboard/properties/list')}
                    variant="contained"
                  >
                    {t('website-user.Cancel')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(withStyles(styles, { withTheme: true })(AddWebsiteUser))
);
