import React from 'react';
import { Route } from 'react-router-dom';
import articleRoutes from '../routes/article.routes';

function Articles() {
  const routes = articleRoutes.map((prop, key) => (
    <Route
      component={prop.component}
      exact={prop.exact}
      key={key.toString()}
      path={prop.path}
    />
  ));
  return <>{routes}</>;
}

export default Articles;
