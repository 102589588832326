import NearbyList from '../components/PointsOfInterest/POIList';
import AddNewNearby from '../components/PointsOfInterest/AddNewPOI';
import EditNearby from '../components/PointsOfInterest/EditPOI';

const nearbyRoutes = [
  {
    path: '/dashboard/nearby/list',
    exact: false,
    name: 'List PointsOfInterest',
    component: NearbyList
  },
  {
    path: '/dashboard/nearby/add',
    exact: false,
    name: 'Add PointsOfInterest',
    component: AddNewNearby
  },
  {
    path: '/dashboard/nearby/edit/:id',
    exact: false,
    name: 'Edit PointsOfInterest',
    component: EditNearby
  }
];

export default nearbyRoutes;
