import React from 'react';
import Marker from '@material-ui/icons/Place';
import Tooltip from '@material-ui/core/Tooltip';
import { withRouter } from 'react-router-dom';

function GoogleMapMarker({ name }) {
  return (
    <div
      className="hint hint--html hint--info hint--top"
      style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}
    >
      <Tooltip title={name || ''} placement="top">
        <Marker style={{ color: '#0069A7', fontSize: '40px' }} />
      </Tooltip>
    </div>
  );
}

GoogleMapMarker.defaultProps = {};

export default withRouter(GoogleMapMarker);
