import { apiUrl, inlineImagesUrl } from '../../config/config';

class UploadAdapter {
  constructor(props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props;
    // URL where to send files.
    this.url = `${apiUrl}/uploads/new`;
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject);
      this._sendRequest();
    });
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Example implementation using XMLHttpRequest.
  _initRequest() {
    // eslint-disable-next-line no-multi-assign,no-undef
    const xhr = (this.xhr = new XMLHttpRequest());

    xhr.open('POST', this.url, true);
    xhr.responseType = 'json';
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    // xhr.setRequestHeader('Content-type', 'multipart/form-data');
    xhr.setRequestHeader('Authorization', `bearer ${localStorage.getItem('token')}`);
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject) {
    const { xhr } = this;
    const { loader } = this;
    const genericErrorText = `Couldn't upload file:  ${loader.file.name}.`;

    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    // eslint-disable-next-line consistent-return
    xhr.addEventListener('load', () => {
      const { response } = xhr;
      if (!response || response.error) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      resolve({
        default: `${inlineImagesUrl}/${response.image.name}`
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest() {
    const data = new FormData();

    this.loader.file.then((result) => {
      data.append('file', result);
      this.xhr.send(data);
    });
  }
}

export default function MyCustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
    // Configure the URL to the upload script in your back-end here!
    new UploadAdapter(loader);
}
