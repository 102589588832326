import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';
import update from 'immutability-helper';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { apiUrl, axiosCallApi } from '../../config/config';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  button: {
    backgroundColor: 'transparent',
    border: '1px solid #000000',
    color: 'black',
    margin: theme.spacing(1),
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'black',
      color: '#FFFFFF'
    }
  },
  spinner: {
    alignItems: 'center',
    display: 'flex',
    height: '80vh',
    justifyContent: 'center'
  },
  input: {
    display: 'flex',
    height: 'inherit'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%'
  },
  formControlTitle: {
    margin: theme.spacing(1),
    width: '99%'
  },
  formText: {
    margin: theme.spacing(1),
    width: '94%'
  },
  formTextTitle: {
    margin: theme.spacing(1),
    width: '99%',
    marginTop: 20
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(0.25)
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    marginTop: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(2)
  },
  selectControl: {
    position: 'relative',
    width: '100%',
    zIndex: 99999
  },
  paperWrapper: {
    padding: 25,
    boxShadow: 'none'
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          children: props.children,
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      component="div"
      selected={props.isFocused}
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={[
        props.selectProps.classes.paper,
        props.selectProps.classes.selectControl
      ].join(' ')}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

class EditSector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sector: {
        active: true,
        city: null,
        nameEl: '',
        nameEn: '',
        nameZh: ''
      },
      country: null,
      county: null,
      countries: [],
      countryList: [],
      countyList: [],
      cityList: [],
      submitBtnIsDisabled: false,
      loading: true
    };
  }

  async componentDidMount() {
    const { match, t } = this.props;

    try {
      const fetchCountries = await axiosCallApi.get(`${apiUrl}/city/countries/all`);
      const { countries } = fetchCountries.data;

      const countryList = countries.map((c) => ({ label: c.labelEl, value: c.value }));

      const fetchSector = await axiosCallApi.get(`${apiUrl}/sector/${match.params.id}`);
      const { sector } = fetchSector.data;

      sector.city = {
        ...sector.city,
        label: sector.city.nameEl,
        value: sector.city.nameEl
        // _id: sector.city._id,
      };

      let countyList = [];
      let countryIndex;
      let countyIndex;
      for (let i = 0; i < countries.length; i++) {
        if (countries[i].value === sector.city.country.value) {
          countryIndex = i;
          countyList = countries[i].counties.map((c) => ({
            label: c.labelEl,
            value: c.value
          }));
          countyIndex = countries[i].counties.findIndex(
            (cy) => cy.value === sector.city.county.value
          );

          break;
        }
      }

      const fetchCities = await axiosCallApi.get(
        `${apiUrl}/city/counties/${sector.city.country.value}/${countries[countryIndex].counties[countyIndex].value}`
      );
      const cityList = fetchCities.data.cities.map((c) => ({
        label: c.nameEl,
        value: c.value,
        _id: c._id
      }));

      this.setState({
        countries,
        countryList,
        sector,
        country: sector.city.country,
        county: sector.city.county,
        countyList,
        cityList,
        loading: false
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: t('common.error-fetch')
      });
    }
  }

  onInputChange = (e) => {
    this.setState(
      update(this.state, {
        sector: {
          [e.target.name]: {
            $set: e.target.value
          }
        }
      })
    );
  };

  submit = (e) => {
    e.preventDefault();
    const data = this.state.sector;
    const { match, t } = this.props;

    if (!data.city) {
      notification.error({
        message: t('sectors.edit.enter-city')
      });
      return;
    }

    axiosCallApi
      .patch(`${apiUrl}/sector/single/${match.params.id}`, data)
      .then(() => {
        notification.success({
          message: t('sectors.edit.update-success')
        });
        this.props.history.push('/dashboard/sector/list');
      })
      .catch(() => {
        notification.error({
          message: t('sectors.edit.update-error')
        });
      });
  };

  deleteSector = () => {
    const { match, t } = this.props;
    axiosCallApi
      .delete(`${apiUrl}/sector/single/${match.params.id}`)
      .then(() => {
        notification.success({
          message: t('sectors.edit.delete-success')
        });
        this.handleDeleteClose();
        this.props.history.push('/dashboard/sector/list');
      })
      .catch(() => {
        notification.error({
          message: t('sectors.edit.delete-error')
        });
      });
  };

  handleDeleteOpen = () => {
    this.setState({ deleteDialog: true });
  };

  handleDeleteClose = () => {
    this.setState({ deleteDialog: false });
  };

  onCheckBoxActiveChange = (event) => {
    this.setState(
      update(this.state, {
        sector: {
          active: { $set: event.target.checked }
        }
      })
    );
  };

  handleSelectChangeCountry = (country) => {
    if (
      this.state.country &&
      this.state.country.value !== undefined &&
      country.value === this.state.country.value
    ) {
      return;
    }

    const { countries } = this.state;
    for (let i = 0; i < countries.length; i++) {
      if (countries[i].value === country.value) {
        const countyList = countries[i].counties.map((c) => ({
          label: c.labelEl,
          value: c.value
        }));
        this.setState(
          update(this.state, {
            sector: {
              city: { $set: null }
            },
            country: { $set: country },
            county: { $set: null },
            countyList: { $set: countyList }
          })
        );
        return;
      }
    }
  };

  handleSelectChangeCounty = (county) => {
    const { t } = this.props;

    if (
      this.state.county &&
      this.state.county.value !== undefined &&
      county.value === this.state.county.value
    ) {
      return;
    }

    axiosCallApi
      .get(`${apiUrl}/city/counties/${this.state.country.value}/${county.value}`)
      .then((response) => {
        const { cities } = response.data;
        const cityList = cities.map((c) => ({
          label: c.nameEl,
          value: c.value,
          _id: c._id
        }));
        this.setState(
          update(this.state, {
            sector: {
              city: { $set: null }
            },
            county: { $set: county },
            cityList: { $set: cityList }
          })
        );
      })
      .catch((error) => {
        console.log(error);
        notification.error({
          message: t('common.error-fetch')
        });
      });
  };

  handleSelectChangeCity = (city) => {
    this.setState(
      update(this.state, {
        sector: {
          city: { $set: city }
        }
      })
    );
  };

  render() {
    const { classes, theme, t } = this.props;
    const { sector } = this.state;
    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary
      })
    };

    return (
      <div>
        <Paper className={classes.paperWrapper}>
          <h1 className="title-wrapper">{t('sectors.edit.edit-sector')}</h1>
          <form onSubmit={(e) => this.submit(e)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    value={this.state.country}
                    isSearchable
                    // name="country"
                    onChange={this.handleSelectChangeCountry}
                    options={this.state.countryList}
                    placeholder={t('common.country')}
                    required
                    styles={selectStyles}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    value={this.state.county}
                    isSearchable
                    // name="city"
                    onChange={this.handleSelectChangeCounty}
                    options={this.state.countyList}
                    placeholder={t('common.county')}
                    required
                    styles={selectStyles}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    value={sector.city}
                    isSearchable
                    // name="city"
                    onChange={this.handleSelectChangeCity}
                    options={this.state.cityList}
                    placeholder={t('common.city')}
                    required
                    styles={selectStyles}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Όνομα"
                    name="nameEl"
                    onChange={this.onInputChange}
                    required
                    type="text"
                    value={sector.nameEl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Name"
                    name="nameEn"
                    onChange={this.onInputChange}
                    type="text"
                    value={sector.nameEn}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="name"
                    label="名称"
                    name="nameZh"
                    onChange={this.onInputChange}
                    type="text"
                    value={sector.nameZh}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <FormControl className={classes.formControl}>
                <FormLabel component="legend">{t('common.status')}</FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sector.active}
                      className="customCheckbox"
                      name="active"
                      onChange={this.onCheckBoxActiveChange}
                    />
                  }
                  label={
                    sector.active
                      ? t('sectors.list.sector-active')
                      : t('sectors.list.sector-inactive')
                  }
                />
              </FormControl>
            </Grid>
            <Grid container spacing={10}>
              <Grid item xs={12}>
                <Button className={classes.button} type="submit" variant="contained">
                  {t('common.submit')}
                </Button>
                <Button
                  className="secondary rightButton deleteButton"
                  onClick={() => this.handleDeleteOpen()}
                  variant="contained"
                >
                  {t('sectors.edit.delete')}
                </Button>
              </Grid>
            </Grid>
          </form>
          <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            onClose={this.handleDeleteClose}
            open={this.state.deleteDialog}
          >
            <DialogTitle style={{ background: 'red' }} id="alert-dialog-title">
              <span style={{ color: 'white' }}>
                {t('cities.edit.are-you-sure')}
                <em>{sector.nameEl}</em>;
              </span>
            </DialogTitle>
            <DialogContent style={{ background: 'red', color: 'white' }}>
              <DialogContentText
                style={{ background: 'red', color: 'white' }}
                id="alert-dialog-description"
              >
                <strong>{t('sectors.edit.attention')}</strong>
                {t('cities.edit.cannot-revert')}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                background: 'red',
                margin: 0,
                paddingBottom: 8,
                paddingRight: 4,
                paddingTop: 8
              }}
            >
              <Button autoFocus onClick={this.handleDeleteClose} style={{ outline: 0 }}>
                {t('common.cancel')}
              </Button>
              <Button
                style={{ outline: 0, color: 'white' }}
                onClick={() => this.deleteSector(this.props.match.params.id)}
              >
                {t('cities.edit.delete')}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(withStyles(styles, { withTheme: true })(EditSector))
);
