import PropertiesList from '../components/Properties/PropertiesList';
import AddNewProperty from '../components/Properties/AddNewProperty';
import EditProperty from '../components/Properties/EditProperty';

const propertiesRoutes = [
  {
    path: '/dashboard/properties/list',
    exact: false,
    name: 'List Properties',
    component: PropertiesList
  },
  {
    path: '/dashboard/properties/add',
    exact: false,
    name: 'Add Property',
    component: AddNewProperty
  },
  {
    path: '/dashboard/properties/edit/:id',
    exact: false,
    name: 'Edit Trainer',
    component: EditProperty
  }
];

export default propertiesRoutes;
