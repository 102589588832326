import React, { Component } from 'react';
import Avatar from 'react-avatar-edit';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import update from 'immutability-helper';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { apiUrl, axiosCallApi } from '../../config/config';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  button: {
    backgroundColor: 'transparent',
    border: '1px solid #000000',
    boxShadow: 'none',
    color: 'black',
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'black',
      color: '#FFFFFF'
    }
  },
  input: {
    display: 'flex',
    padding: 0
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%'
  },
  valueContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap'
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    fontSize: 16,
    left: 2,
    position: 'absolute'
  },
  paper: {
    marginTop: theme.spacing(1)
  },
  selectControl: {
    position: 'absolute',
    width: '100%',
    zIndex: 99999
  },
  divider: {
    height: theme.spacing(2)
  },
  paperWrapper: {
    boxShadow: 'none',
    padding: 20
  }
});

class EditAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agent: {
        address: '',
        city: '',
        email: '',
        image: '',
        mobilePhone: '',
        name: '',
        phone: '',
        postalCode: '',
        property: '',
        surname: '',
        uid: ''
      },
      deleteDialog: false,
      preview: '',
      src: '',
      submitBtnIsDisabled: false
    };
  }

  componentDidMount() {
    const { t } = this.props;
    const { match } = this.props;
    this.setState({ loading: true });
    axiosCallApi
      .get(`${apiUrl}/agent/${match.params.id}`)
      .then((res) => {
        const { agent } = res.data;
        agent.base64 = res.data.base;
        this.setState({ agent });
      })
      .catch(() => {
        notification.error({
          message: t('common.error-fetch')
        });
      });
  }

  submitEditAgent = (e) => {
    e.preventDefault();
    const data = this.state.agent;
    const { match, t } = this.props;
    axiosCallApi
      .patch(`${apiUrl}/agent/single/${match.params.id}`, data)
      .then(() => {
        notification.success({
          message: t('add-new-agent.response-edit-success')
        });
        this.props.history.push('/dashboard/agents/list');
      })
      .catch((error) => {
        if (error.response.status === 409) {
          notification.error({
            message: t('common.email-exists')
          });
        } else {
          notification.error({
            message: t('add-new-agent.response-edit-error')
          });
        }
      });
  };

  onInputChange = (e) => {
    this.setState(
      update(this.state, {
        agent: {
          [e.target.name]: {
            $set: e.target.value
          }
        }
      })
    );
  };

  deleteAgent = () => {
    const { t } = this.props;
    const { match } = this.props;
    axiosCallApi
      .delete(`${apiUrl}/agent/single/${match.params.id}`)
      .then(() => {
        notification.success({
          message: t('add-new-agent.response-delete-success')
        });
        this.handleDeleteClose();
        this.props.history.push('/dashboard/agents/list');
      })
      .catch(() => {
        notification.error({
          message: t('add-new-agent.response-delete-error')
        });
      });
  };

  handleDeleteOpen = () => {
    this.setState({ deleteDialog: true });
  };

  handleDeleteClose = () => {
    this.setState({ deleteDialog: false });
  };

  onClose = () => {
    this.setState({ preview: null });
  };

  onCrop = (preview) => {
    this.setState({ preview });
    const data = {
      preview
    };
    axiosCallApi
      .post(`${apiUrl}/agent/upload`, data)
      .then((response) =>
        this.setState(
          update(this.state, {
            agent: {
              image: { $set: response.data.image }
            }
          })
        )
      )
      .catch((e) => console.log(e));
  };

  onBeforeFileLoad = (elem) => {
    const { t } = this.props;
    if (elem.target.files[0].size > 5000000) {
      // eslint-disable-next-line
      alert(t('add-new-agent.file-too-big'));
      elem.target.value = '';
    }
  };

  render() {
    const { classes, history, t } = this.props;
    const { agent, preview } = this.state;

    return (
      <div>
        <Paper style={{ position: 'relative' }} className={classes.paperWrapper}>
          <h1 className="title-wrapper">
            <span>{t('add-new-agent.edit')}</span>
          </h1>
          <form onSubmit={(e) => this.submitEditAgent(e)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 25 }}>
                  <TextField
                    fullWidth
                    id="name"
                    label={t('common.name')}
                    name="name"
                    onChange={this.onInputChange}
                    required
                    type="text"
                    value={this.state.agent.name}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 25 }}>
                  <TextField
                    fullWidth
                    id="surname"
                    label={t('common.last-name')}
                    name="surname"
                    onChange={this.onInputChange}
                    required
                    value={this.state.agent.surname}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 25 }}>
                  <TextField
                    fullWidth
                    id="property"
                    label={t('add-new-agent.specialty')}
                    name="property"
                    onChange={this.onInputChange}
                    required
                    text="text"
                    value={this.state.agent.property}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 25 }}>
                  <TextField
                    fullWidth
                    id="phone"
                    label={t('common.phone')}
                    name="phone"
                    onChange={this.onInputChange}
                    type="number"
                    value={this.state.agent.phone}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 25 }}>
                  <TextField
                    fullWidth
                    id="mobile"
                    label={t('common.mobile')}
                    name="mobile"
                    onChange={this.onInputChange}
                    required
                    type="number"
                    value={this.state.agent.mobilePhone}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 25 }}>
                  <TextField
                    fullWidth
                    id="email"
                    label={t('common.email')}
                    name="email"
                    onChange={this.onInputChange}
                    required
                    type="email"
                    value={this.state.agent.email}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 25 }}>
                  <TextField
                    fullWidth
                    id="city"
                    label={t('add-new-agent.city')}
                    name="city"
                    onChange={this.onInputChange}
                    required
                    type="text"
                    value={this.state.agent.city}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 25 }}>
                  <TextField
                    fullWidth
                    id="address"
                    label={t('add-new-agent.address')}
                    name="address"
                    onChange={this.onInputChange}
                    required
                    type="text"
                    value={this.state.agent.address}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 25 }}>
                  <TextField
                    fullWidth
                    id="zipCode"
                    label={t('add-new-agent.postal-code')}
                    name="zipCode"
                    onChange={this.onInputChange}
                    required
                    type="text"
                    value={this.state.agent.postalCode}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                {agent.uid ? (
                  <div className="avatar-wrapper">
                    <div>
                      <span>{t('add-new-agent.choose-avatar')}</span>
                      <Avatar
                        height={200}
                        onBeforeFileLoad={this.onBeforeFileLoad}
                        onClose={this.onClose}
                        onCrop={this.onCrop}
                        onFileLoad={this.uploadImage}
                        src={agent.base64}
                        width={200}
                      />
                    </div>
                    {preview ? (
                      <div className="avatar-preview">
                        <span>{t('add-new-agent.preview-avatar')}</span>
                        <div id="avatar_container-9d8d-ba30-2b3a">
                          <div
                            className="konvajs-content preview-content"
                            role="presentation"
                          >
                            <canvas width="200" height="200" className="preview-canvas" />
                            <img src={preview} alt="Preview" />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </Grid>
              <Grid container spacing={10}>
                <Grid item xs={12}>
                  <Button className={classes.button} type="submit" variant="contained">
                    {t('common.submit')}
                  </Button>
                  <Button
                    className={classes.button}
                    onClick={() => history.push('/dashboard/agents/list')}
                    variant="contained"
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button
                    className="secondary rightButton deleteButton"
                    onClick={() => this.handleDeleteOpen()}
                    variant="contained"
                  >
                    {t('add-new-agent.delete-agent')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            onClose={this.handleDeleteClose}
            open={this.state.deleteDialog}
          >
            <DialogTitle style={{ background: 'red' }} id="alert-dialog-title">
              <span style={{ color: 'white' }}>
                {t('add-new-agent.are-you-sure')} <em>{this.state.agent.surname}</em>
                {t('common.question-mark')}
              </span>
            </DialogTitle>
            <DialogContent style={{ background: 'red', color: 'white' }}>
              <DialogContentText
                style={{ background: 'red', color: 'white' }}
                id="alert-dialog-description"
              >
                <strong>{t('add-new-agent.attention')}</strong>
                {t('add-new-agent.cannot-revert')}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                background: 'red',
                margin: 0,
                paddingBottom: 8,
                paddingRight: 4,
                paddingTop: 8
              }}
            >
              <Button autoFocus onClick={this.handleDeleteClose} style={{ outline: 0 }}>
                {t('common.cancel')}
              </Button>
              <Button
                onClick={() => this.deleteAgent(this.props.match.params.id)}
                style={{ outline: 0, color: 'white' }}
              >
                {t('add-new-agent.delete-agent')}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(withStyles(styles, { withTheme: true })(EditAgent))
);
