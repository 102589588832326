import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { DateTimePicker } from 'material-ui-pickers';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import CreatableSelect from 'react-select/lib/Creatable';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import update from 'immutability-helper';
import { debounce } from 'lodash';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { Icon, message, Modal, notification, Upload } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { apiUrl, articleImagesUrl, axiosCallApi } from '../../config/config';
import RelatedArticles from './RelatedArticles';
import MyCustomUploadAdapterPlugin from '../UploadAdapter/UploadAdapter';

const imagesUrl = articleImagesUrl;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: 'transparent',
    color: 'black',
    border: '1px solid #000000',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'black',
      color: '#FFFFFF'
    }
  },
  input: {
    display: 'flex',
    padding: 0
  },
  formControl: {
    margin: theme.spacing(1),
    width: '98%',
    paddingBottom: 12
  },
  dateControl: {
    margin: theme.spacing(1),
    width: '15%',
    paddingBottom: 12
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center'
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  spinner: {
    alignItems: 'center',
    display: 'flex',
    height: '80vh',
    justifyContent: 'center'
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    marginTop: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(2)
  },
  paperWrapper: {
    padding: 25,
    boxShadow: 'none'
  }
});

notification.config({
  placement: 'topRight',
  top: 24,
  duration: 3
});

class EditArticle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {
        publishedAt: new Date(),
        articleImage: {},
        descriptionEl: '',
        descriptionEn: '',
        descriptionZh: '',
        published: false,
        related: [],
        tags: [],
        titleEl: '',
        titleEn: '',
        titleZh: ''
      },
      submitBtnIsDisabled: false,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      loading: true,
      tagOptions: [],
      articleImage: '',
      relatedArticles: [],
      loadingRelatedArticles: false,
      relatedBox: false,
      hasImage: false
    };
    this.searchRelatedArticlesDebounced = debounce((searchTerm, callback) => {
      this.handleRelatedArticlesSearch(searchTerm, callback);
    }, 500);
  }

  componentDidMount() {
    const { match, t } = this.props;
    this.setState({ loading: true });
    axiosCallApi
      .get(`${apiUrl}/article/${match.params.id}`)
      .then(async (response) => {
        const { article } = response.data;

        const articleImage = article.articleImage.image.name
          ? [
              {
                name: article.articleImage.image.name,
                status: 'done',
                type: 'original',
                isOld: true,
                uid: article.articleImage.image.uid,
                url: `${imagesUrl}/${article._id}/${article.articleImage.image.name}`
              }
            ]
          : [];
        const tags = await axiosCallApi.get(`${apiUrl}/article/get-tags`);
        const tagOptions = tags.data.tags.map((el) => ({
          label: el.name,
          value: el.name
        }));
        this.setState({
          article,
          fileList: articleImage,
          tagOptions,
          loading: false,
          relatedBox: article.related.length > 0
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        notification.error({
          message: t('common.error-fetch')
        });
      });
  }

  // eslint-disable-next-line react/sort-comp
  handleRelatedArticlesSearch = (searchTerm, callback) => {
    this.setState({ loadingRelatedArticles: true });
    return axiosCallApi
      .get(`${apiUrl}/article/search/?value=${searchTerm}`)
      .then((response) => {
        this.setState(
          {
            relatedArticles: response.data.articles,
            loadingRelatedArticles: false
          },
          () => callback(null, { options: this.state.relatedArticles })
        );
      })
      .catch((error) => {
        this.setState({ loadingRelatedArticles: false });
        callback(error, null);
      });
  };

  getRelatedArticles = (input, callback) => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }
    return this.searchRelatedArticlesDebounced(input, callback);
  };

  handleRelatedArticleChange = (value) => {
    this.setState(
      update(this.state, {
        article: {
          related: {
            $set: value.length === 0 ? [] : value
          }
        },
        relatedBox: {
          $set: value.length !== 0
        }
      })
    );
  };

  removeArticle = (i) => {
    this.setState(
      update(this.state, {
        article: {
          related: {
            $splice: [[i, 1]]
          }
        }
      })
    );
  };

  handleSelectChange = (value) => {
    this.setState(
      update(this.state, {
        article: {
          tags: {
            $set: value
          }
        }
      })
    );
  };

  onCheckBoxChange = (event) => {
    this.setState(
      update(this.state, {
        article: {
          published: { $set: event.target.checked }
        }
      })
    );
  };

  deleteArticle = () => {
    const { match, t } = this.props;
    axiosCallApi
      .delete(`${apiUrl}/article/single/${match.params.id}`)
      .then(() => {
        notification.success({
          message: t('blog.edit.delete-success')
        });
        this.handleDeleteClose();
        this.props.history.push('/dashboard/articles/list');
      })
      .catch(() => {
        notification.error({
          message: t('blog.edit.delete-error')
        });
      });
  };

  handleDeleteOpen = () => {
    this.setState({ deleteDialog: true });
  };

  handleDeleteClose = () => {
    this.setState({ deleteDialog: false });
  };

  submitEditArticle = (e) => {
    e.preventDefault();
    const { match, t } = this.props;
    const data = this.state.article;
    axiosCallApi
      .patch(`${apiUrl}/article/single/${match.params.id}`, data)
      .then(() => {
        notification.success({
          message: t('blog.edit.update-success')
        });
        this.props.history.push('/dashboard/articles/list');
      })
      .catch(() => {
        notification.error({
          message: t('blog.edit.update-error')
        });
      });
  };

  handleCancel = () => {
    this.setState({ previewVisible: false });
  };

  handlePreview = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  handleChange = ({ fileList }) => {
    this.setState({ fileList });
  };

  beforeUpload = (file) => {
    const { t } = this.props;
    return new Promise((resolve, reject) => {
      if (
        file.type !== 'image/jpeg' &&
        file.type !== 'image/png' &&
        file.type !== 'image/jpg'
      ) {
        notification.error({ message: t('image-upload.unsupported-files') });
        reject(file);
      } else if (file.size / 1024 / 1024 > 5) {
        notification.error({ message: t('image-upload.image-file-size') });
        reject(file);
      } else {
        resolve(file);
      }
    });
  };

  removeImage = (file) => {
    const { t } = this.props;
    const id = this.state.article._id;
    const imgName = file.response ? file.response.data.image.name : file.name;
    if (file.isOld) {
      axiosCallApi
        .delete(`${apiUrl}/article/uploads/delete/${id}/${imgName}`)
        .then(() => {
          this.setState(
            update(this.state, {
              article: {
                articleImage: {}
              }
            })
          );
          notification.success({
            message: `${t('image-upload.image-delete.part1')} ${file.name} ${t(
              'image-upload.image-delete.part2'
            )} `
          });
        });
    } else {
      axiosCallApi
        .delete(`${apiUrl}/uploads/delete/temp/single/${imgName}`)
        .then(() => {
          this.setState(
            update(this.state, {
              article: {
                articleImage: {}
              }
            })
          );
          notification.success({
            message: `${t('image-upload.image-delete.part1')} ${file.name} ${t(
              'image-upload.image-delete.part2'
            )} `
          });
        })
        .catch(() =>
          notification.error({
            message: `${t('image-upload.image-error')} ${file.name}`
          })
        );
    }
  };

  handleUpload = ({ ...props }) => {
    const { t } = this.props;
    if (this.state.fileList.length > 0) {
      return message.error(
        `${t('image-upload.max-files-reached.part1')} ${t(
          'image-upload.max-files-reached.part2'
        )} ${t('image-upload.max-files-reached.part5')}`
      );
    }

    const formData = new FormData();
    formData.append('file', props.file, props.file.name);
    axiosCallApi
      .post(`${apiUrl}/uploads/new`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: ({ total, loaded }) => {
          props.onProgress(
            { percent: Math.round((loaded / total) * 100).toFixed(2) },
            props.file
          );
        }
      })
      .then((response) => {
        props.onSuccess(response, props.file);
        notification.success({
          message: ` ${t('image-upload.image-success')}  ${props.file.name}`
        });
        this.setState(
          update(this.state, {
            article: {
              articleImage: {
                $set: response.data
              }
            }
          })
        );
      })
      .catch(() => {
        message.error(`${t('image-upload.image-error')}  ${props.file.name}`);
        return props.onError;
      });
    return {
      abort() {
        message.error(`${t('image-upload.image-stop')}  ${props.file.name}`);
      }
    };
  };

  debounceEvent = (...args) => {
    this.debouncedEvent = debounce(...args);
    return (e) => {
      e.persist();
      return this.debouncedEvent(e);
    };
  };

  onInputChange = (e) => {
    if (e.target.name === 'imageAlt') {
      this.setState(
        update(this.state, {
          article: {
            articleImage: {
              image: {
                alt: { $set: e.target.value }
              }
            }
          }
        })
      );
    }
    if (e.target.name === 'customSlug') {
      this.setState(
        update(this.state, {
          article: {
            slug: { $set: e.target.value }
          }
        })
      );
    }
    this.setState(
      update(this.state, {
        article: {
          [e.target.name]: { $set: e.target.value }
        }
      })
    );
  };

  // eslint-disable-next-line
  changeDescriptionEnDebounced = debounce(
    (input) => this.handleDescriptionChangeEn(input),
    2000,
    { maxWait: 2000 }
  );

  changeDescriptionElDebounced = debounce(
    (input) => this.handleDescriptionChangeEl(input),
    2000,
    { maxWait: 2000 }
  );

  changeDescriptionZhDebounced = debounce(
    (input) => this.handleDescriptionChangeZh(input),
    2000,
    { maxWait: 2000 }
  );

  handleDescriptionChangeEn = (value) => {
    this.setState(
      update(this.state, {
        article: {
          descriptionEn: { $set: value }
        }
      })
    );
  };

  handleDateChange = (date) => {
    this.setState(
      update(this.state, {
        article: {
          publishedAt: {
            $set: date
          }
        }
      })
    );
  };

  handleDescriptionChangeEl = (value) => {
    this.setState(
      update(this.state, {
        article: {
          descriptionEl: { $set: value }
        }
      })
    );
  };

  handleDescriptionChangeZh = (value) => {
    this.setState(
      update(this.state, {
        article: {
          descriptionZh: { $set: value }
        }
      })
    );
  };

  // handleDateChange = (date) => {
  //   this.setState(
  //     update(this.state, {
  //       article: {
  //         publishedAt: { $set: date }
  //       }
  //     })
  //   );
  // };

  render() {
    const {
      article,
      deleteDialog,
      fileList,
      loading,
      loadingRelatedArticles,
      previewImage,
      previewVisible,
      relatedArticles,
      relatedBox,
      tagOptions
    } = this.state;

    const { classes, history, t, theme } = this.props;
    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary
      })
    };

    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">{t('blog.add-new-article.upload-image')}</div>
      </div>
    );

    return (
      <div>
        <Paper className={classes.paperWrapper}>
          {!loading ? (
            <>
              <h1 className="title-wrapper">{t('blog.edit.info')}</h1>
              <form onSubmit={(e) => this.submitEditArticle(e)}>
                <Grid item xs={12} sm={12} md={6}>
                  <Card style={{ padding: 15, marginTop: 25 }}>
                    <Typography variant="h6" gutterBottom>
                      {t('add-new-property.property.header-en')}
                    </Typography>
                    <FormControl className={classes.formControl}>
                      <TextField
                        fullWidth
                        id="titleEn"
                        label={t('blog.add-new-article.title')}
                        name="titleEn"
                        onChange={this.debounceEvent(this.onInputChange, 200)}
                        //  required
                        defaultValue={article.titleEn}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        onChange={this.debounceEvent(this.onInputChange, 200)}
                        //   required
                        id="metaTitleEn"
                        name="metaTitleEn"
                        label="Meta Title"
                        fullWidth
                        defaultValue={article.metaTitleEn}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        onChange={this.debounceEvent(this.onInputChange, 200)}
                        //   required
                        id="metaDescriptionEn"
                        name="metaDescriptionEn"
                        label="Meta Description"
                        fullWidth
                        defaultValue={article.metaDescriptionEn}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        onChange={this.debounceEvent(this.onInputChange, 200)}
                        //   required
                        id="metaKeywordsEn"
                        name="metaKeywordsEn"
                        label="Meta Keywords"
                        fullWidth
                        defaultValue={article.metaKeywordsEn}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <FormLabel component="legend">
                        {t('blog.add-new-article.content')}
                      </FormLabel>
                      <CKEditor
                        config={{ extraPlugins: [MyCustomUploadAdapterPlugin] }}
                        data={article.descriptionEn ? article.descriptionEn : ''}
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          this.changeDescriptionEnDebounced(data);
                        }}
                      />
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Card style={{ padding: 15, marginTop: 25 }}>
                    <Typography variant="h6" gutterBottom>
                      {t('add-new-property.property.header-el')}
                    </Typography>
                    <FormControl className={classes.formControl}>
                      <TextField
                        fullWidth
                        id="titleEl"
                        label={t('blog.add-new-article.title')}
                        name="titleEl"
                        onChange={this.debounceEvent(this.onInputChange, 200)}
                        defaultValue={article.titleEl}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        onChange={this.debounceEvent(this.onInputChange, 200)}
                        //   required
                        id="metaTitleEl"
                        name="metaTitleEl"
                        label="Meta Title"
                        fullWidth
                        defaultValue={article.metaTitleEl}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        onChange={this.debounceEvent(this.onInputChange, 200)}
                        //   required
                        id="metaDescriptionEl"
                        name="metaDescriptionEl"
                        label="Meta Description"
                        fullWidth
                        defaultValue={article.metaDescriptionEl}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        onChange={this.debounceEvent(this.onInputChange, 200)}
                        //   required
                        id="metaKeywordsEl"
                        name="metaKeywordsEl"
                        label="Meta Keywords"
                        fullWidth
                        defaultValue={article.metaKeywordsEl}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <FormLabel component="legend">
                        {t('blog.add-new-article.content')}
                      </FormLabel>
                      <CKEditor
                        config={{ extraPlugins: [MyCustomUploadAdapterPlugin] }}
                        data={article.descriptionEl ? article.descriptionEl : ''}
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          this.changeDescriptionElDebounced(data);
                        }}
                      />
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Card style={{ padding: 15, marginTop: 25 }}>
                    <Typography variant="h6" gutterBottom>
                      {t('add-new-property.property.header-zh')}
                    </Typography>
                    <FormControl className={classes.formControl}>
                      <TextField
                        fullWidth
                        id="titleZh"
                        label={t('blog.add-new-article.title')}
                        name="titleZh"
                        onChange={this.debounceEvent(this.onInputChange, 200)}
                        defaultValue={article.titleZh}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        onChange={this.debounceEvent(this.onInputChange, 200)}
                        //   required
                        id="metaTitleZh"
                        name="metaTitleZh"
                        label="Meta Title"
                        fullWidth
                        defaultValue={article.metaTitleZh}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        onChange={this.debounceEvent(this.onInputChange, 200)}
                        //   required
                        id="metaDescriptionZh"
                        name="metaDescriptionZh"
                        label="Meta Description"
                        fullWidth
                        defaultValue={article.metaDescriptionZh}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <TextField
                        onChange={this.debounceEvent(this.onInputChange, 200)}
                        //   required
                        id="metaKeywordsZh"
                        name="metaKeywordsZh"
                        label="Meta Keywords"
                        fullWidth
                        defaultValue={article.metaKeywordsZh}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <FormLabel component="legend">
                        {t('blog.add-new-article.content')}
                      </FormLabel>
                      <CKEditor
                        config={{ extraPlugins: [MyCustomUploadAdapterPlugin] }}
                        data={article.descriptionZh ? article.descriptionZh : ''}
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          this.changeDescriptionZhDebounced(data);
                        }}
                      />
                    </FormControl>
                  </Card>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 15 }}>
                  <FormControl className={classes.formControl}>
                    <Typography variant="h6">
                      {t('blog.add-new-article.photos')}
                    </Typography>
                    <Upload
                      action="http://localhost:4000/ads/upload"
                      beforeUpload={this.beforeUpload}
                      customRequest={this.handleUpload}
                      fileList={fileList}
                      listType="picture-card"
                      onChange={this.handleChange}
                      onPreview={this.handlePreview}
                      onRemove={(file) => this.removeImage(file)}
                    >
                      {fileList.length <= 0 ? uploadButton : null}
                    </Upload>
                    <Modal
                      footer={null}
                      onCancel={this.handleCancel}
                      visible={previewVisible}
                    >
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      id="imageAlt"
                      label={t('blog.image-alt')}
                      name="imageAlt"
                      onChange={this.debounceEvent(this.onInputChange, 200)}
                      defaultValue={article.articleImage.image.alt}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      id="customSlug"
                      label={t('blog.custom-slug')}
                      name="customSlug"
                      onChange={this.debounceEvent(this.onInputChange, 200)}
                      defaultValue={article.slug}
                    />
                  </FormControl>
                </Grid>
                <Grid container style={{ marginTop: 15 }}>
                  <Grid item xs={12} sm={6} md={6}>
                    <FormControl className={classes.formControl}>
                      <Typography variant="h6">
                        {t('blog.add-new-article.tags')}
                      </Typography>
                      <CreatableSelect
                        classes={classes}
                        isMulti
                        onChange={this.handleSelectChange}
                        options={tagOptions}
                        placeholder={t('blog.add-new-article.tags')}
                        styles={selectStyles}
                        value={article.tags}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: 15 }}>
                  <FormControl className={classes.formControl}>
                    <Typography variant="h6">
                      {t('blog.add-new.related-articles')}
                    </Typography>
                    <RelatedArticles
                      getRelatedArticles={this.getRelatedArticles}
                      handleRelatedArticleChange={this.handleRelatedArticleChange}
                      loading={loadingRelatedArticles}
                      options={relatedArticles}
                      relatedArticles={article.related}
                      relatedBox={relatedBox}
                      removeArticle={this.removeArticle}
                      t={t}
                    />
                  </FormControl>
                </Grid>
                <Grid container style={{ marginTop: 15 }}>
                  <FormControl className={classes.formControl}>
                    <Typography variant="h6">Published at:</Typography>
                    <DateTimePicker
                      value={article.publishedAt}
                      onChange={this.handleDateChange}
                    />
                  </FormControl>
                </Grid>
                <Grid container>
                  <FormControl className={classes.formControl}>
                    <Typography variant="h6">{t('common.status')}</Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={article.published}
                          className="customCheckbox"
                          name="active"
                          onChange={this.onCheckBoxChange}
                        />
                      }
                      label={
                        article.published
                          ? t('blog.add-new-article.published')
                          : t('blog.add-new-article.not-published')
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid container spacing={10}>
                  <Grid item xs={12}>
                    <Button className={classes.button} type="submit" variant="contained">
                      {t('common.submit')}
                    </Button>
                    <Button
                      className={classes.button}
                      onClick={() => history.push('/dashboard/articles/list')}
                      variant="contained"
                    >
                      {t('common.cancel')}
                    </Button>
                    <Button
                      className="secondary rightButton deleteButton"
                      onClick={() => this.handleDeleteOpen()}
                      variant="contained"
                    >
                      {t('blog.edit.delete-article')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </>
          ) : (
            <div className={classes.spinner}>
              <CircularProgress disableShrink />
            </div>
          )}
          <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            onClose={this.handleDeleteClose}
            open={deleteDialog}
          >
            <DialogTitle style={{ background: 'red' }} id="alert-dialog-title">
              <span style={{ color: 'white' }}>
                {t('blog.edit.are-you-sure')}
                <em>{article.title}</em>;
              </span>
            </DialogTitle>
            <DialogContent style={{ background: 'red', color: 'white' }}>
              <DialogContentText
                style={{ background: 'red', color: 'white' }}
                id="alert-dialog-description"
              >
                <strong>{t('blog.edit.attention')}:</strong>
                {t('blog.edit.cannot-revert')}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                background: 'red',
                margin: 0,
                paddingBottom: 8,
                paddingRight: 4,
                paddingTop: 8
              }}
            >
              <Button autoFocus onClick={this.handleDeleteClose} style={{ outline: 0 }}>
                {}
              </Button>
              <Button
                onClick={() => this.deleteArticle(this.props.match.params.id)}
                style={{ outline: 0, color: 'white' }}
              >
                {t('blog.edit.delete-article')}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(withStyles(styles, { withTheme: true })(EditArticle))
);
