import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from 'react-select';
import update from 'immutability-helper';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import { apiUrl, axiosCallApi } from '../../config/config';
import GoogleMap from '../GoogleMap/GoogleMap';

const initLocation = {
  lat: 37.9988844,
  lng: 23.735316
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  button: {
    backgroundColor: 'transparent',
    border: '1px solid #000000',
    color: 'black',
    margin: theme.spacing(1),
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'black',
      color: '#FFFFFF'
    }
  },
  spinner: {
    alignItems: 'center',
    display: 'flex',
    height: '80vh',
    justifyContent: 'center'
  },
  input: {
    display: 'flex',
    height: 'inherit'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%'
  },
  formControlTitle: {
    margin: theme.spacing(1),
    width: '99%'
  },
  formText: {
    margin: theme.spacing(1),
    width: '94%'
  },
  formTextTitle: {
    margin: theme.spacing(1),
    width: '99%',
    marginTop: 20
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(0.25)
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    marginTop: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(2)
  },
  selectControl: {
    position: 'relative',
    width: '100%',
    zIndex: 99999
  },
  paperWrapper: {
    padding: 25,
    boxShadow: 'none'
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          children: props.children,
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      component="div"
      selected={props.isFocused}
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={[
        props.selectProps.classes.paper,
        props.selectProps.classes.selectControl
      ].join(' ')}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

class EditCity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      city: {
        addressCenter: '',
        addressAirport: '',
        addressPort: '',
        active: true,
        country: null,
        county: null,
        hasCenter: false,
        hasAirport: false,
        hasPort: false,
        locationAirport: initLocation,
        locationPort: initLocation,
        locationCenter: initLocation,
        nameEl: '',
        nameEn: '',
        nameZh: ''
      },
      initCenterLocation: initLocation,
      initAirportLocation: initLocation,
      initPortLocation: initLocation,
      countries: [],
      countryList: [],
      countyList: [],
      submitBtnIsDisabled: false,
      loading: true
    };
  }

  async componentDidMount() {
    const { match, t } = this.props;

    try {
      const fetchCountries = await axiosCallApi.get(`${apiUrl}/city/countries/all`);
      const { countries } = fetchCountries.data;

      const countryList = countries.map((c) => ({ label: c.labelEl, value: c.value }));

      const fetchCity = await axiosCallApi.get(`${apiUrl}/city/${match.params.id}`);
      const { city } = fetchCity.data;

      let countyList = [];
      for (let i = 0; i < countries.length; i++) {
        if (countries[i].value === city.country.value) {
          countyList = countries[i].counties.map((c) => ({
            label: c.labelEl,
            value: c.value
          }));
          break;
        }
      }

      this.setState({
        countries,
        countryList,
        city,
        countyList,
        loading: false,
        initCenterLocation: city.locationCenter,
        initAirportLocation: city.locationAirport,
        initPortLocation: city.locationPort
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      notification.error({
        message: t('common.error-fetch')
      });
    }
  }

  onInputChange = (e) => {
    this.setState(
      update(this.state, {
        city: {
          [e.target.name]: {
            $set: e.target.value
          }
        }
      })
    );
  };

  submit = (e) => {
    e.preventDefault();
    const data = this.state.city;
    const { match, t } = this.props;

    axiosCallApi
      .patch(`${apiUrl}/city/single/${match.params.id}`, data)
      .then(() => {
        notification.success({
          message: t('areas.edit.update-success')
        });
        this.props.history.push('/dashboard/city/list');
      })
      .catch((error) => {
        console.log('err', error);
        notification.error({
          message: t('cities.edit.update-error')
        });
      });
  };

  deleteCity = () => {
    const { match, t } = this.props;
    axiosCallApi
      .delete(`${apiUrl}/city/single/${match.params.id}`)
      .then(() => {
        notification.success({
          message: t('cities.edit.delete-success')
        });
        this.handleDeleteClose();
        this.props.history.push('/dashboard/city/list');
      })
      .catch(() => {
        notification.error({
          message: t('cities.edit.delete-error')
        });
      });
  };

  handleDeleteOpen = () => {
    this.setState({ deleteDialog: true });
  };

  handleDeleteClose = () => {
    this.setState({ deleteDialog: false });
  };

  onCheckBoxActiveChange = (event) => {
    this.setState(
      update(this.state, {
        city: {
          active: { $set: event.target.checked }
        }
      })
    );
  };

  onCheckBoxCenter = (event) => {
    this.setState(
      update(this.state, {
        city: {
          hasCenter: { $set: event.target.checked }
        }
      })
    );
  };

  onCheckBoxAirport = (event) => {
    this.setState(
      update(this.state, {
        city: {
          hasAirport: { $set: event.target.checked }
        }
      })
    );
  };

  onCheckBoxPort = (event) => {
    this.setState(
      update(this.state, {
        city: {
          hasPort: { $set: event.target.checked }
        }
      })
    );
  };

  handleSelectChangeCountry = (country) => {
    if (
      this.state.city.country &&
      this.state.city.country.value !== undefined &&
      country.value === this.state.city.country.value
    ) {
      return;
    }

    const { countries } = this.state;
    for (let i = 0; i < countries.length; i++) {
      if (countries[i].value === country.value) {
        const countyList = countries[i].counties.map((c) => ({
          label: c.labelEl,
          value: c.value
        }));
        this.setState(
          update(this.state, {
            city: {
              country: { $set: country },
              county: { $set: null }
            },
            countyList: { $set: countyList }
          })
        );
        return;
      }
    }
  };

  handleSelectChangeCounty = (county) => {
    if (
      this.state.city.county &&
      this.state.city.county.value !== undefined &&
      county.value === this.state.city.county.value
    ) {
      return;
    }

    this.setState(
      update(this.state, {
        city: {
          county: { $set: county }
        }
      })
    );
  };

  onMapClickAirport = (lat, lng) => {
    this.setState(
      update(this.state, {
        city: {
          locationAirport: {
            lat: { $set: lat },
            lng: { $set: lng }
          }
        }
      })
    );
  };

  onMapClickPort = (lat, lng) => {
    this.setState(
      update(this.state, {
        city: {
          locationPort: {
            lat: { $set: lat },
            lng: { $set: lng }
          }
        }
      })
    );
  };

  onMapClickCenter = (lat, lng) => {
    this.setState(
      update(this.state, {
        city: {
          locationCenter: {
            lat: { $set: lat },
            lng: { $set: lng }
          }
        }
      })
    );
  };

  handleChangeCenter = (address) => {
    this.setState(
      update(this.state, {
        city: {
          addressCenter: { $set: address }
        }
      })
    );
  };

  handleSelectCenter = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState(
          update(this.state, {
            city: {
              addressCenter: { $set: address },
              locationCenter: {
                lat: { $set: latLng.lat },
                lng: { $set: latLng.lng }
              }
            }
          })
        );
      })
      .catch((error) => console.error('Error', error));
  };

  handleChangeAirport = (address) => {
    this.setState(
      update(this.state, {
        city: {
          addressAirport: { $set: address }
        }
      })
    );
  };

  handleSelectAirport = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState(
          update(this.state, {
            city: {
              addressAirport: { $set: address },
              locationAirport: {
                lat: { $set: latLng.lat },
                lng: { $set: latLng.lng }
              }
            }
          })
        );
      })
      .catch((error) => console.error('Error', error));
  };

  handleChangePort = (address) => {
    this.setState(
      update(this.state, {
        city: {
          addressPort: { $set: address }
        }
      })
    );
  };

  handleSelectPort = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState(
          update(this.state, {
            city: {
              addressPort: { $set: address },
              locationPort: {
                lat: { $set: latLng.lat },
                lng: { $set: latLng.lng }
              }
            }
          })
        );
      })
      .catch((error) => console.error('Error', error));
  };

  render() {
    const { classes, theme, t } = this.props;
    const {
      addressCenter,
      addressAirport,
      addressPort,
      hasCenter,
      hasAirport,
      hasPort,
      locationAirport,
      locationPort,
      locationCenter
    } = this.state.city;
    const {
      initCenterLocation,
      initAirportLocation,
      initPortLocation,
      loading: fetching
    } = this.state;
    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary
      })
    };

    return (
      <div>
        <Paper className={classes.paperWrapper}>
          <h1 className="title-wrapper">{t('cities.edit.edit-city')}</h1>
          <form onSubmit={(e) => this.submit(e)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    value={this.state.city.country}
                    isSearchable
                    // name="country"
                    onChange={this.handleSelectChangeCountry}
                    options={this.state.countryList}
                    placeholder={t('common.country')}
                    required
                    styles={selectStyles}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    value={this.state.city.county}
                    isSearchable
                    // name="city"
                    onChange={this.handleSelectChangeCounty}
                    options={this.state.countyList}
                    placeholder={t('common.county')}
                    required
                    styles={selectStyles}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Όνομα"
                    name="nameEl"
                    onChange={this.onInputChange}
                    required
                    type="text"
                    value={this.state.city.nameEl}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="name"
                    label="Name"
                    name="nameEn"
                    onChange={this.onInputChange}
                    type="text"
                    value={this.state.city.nameEn}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="name"
                    label="名称"
                    name="nameZh"
                    onChange={this.onInputChange}
                    type="text"
                    value={this.state.city.nameZh}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <FormControl
                className={classes.formControl}
                style={{ paddingTop: 28, marginBottom: 16 }}
              >
                <FormLabel component="legend">{t('common.status')}</FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.city.active}
                      className="customCheckbox"
                      name="active"
                      onChange={this.onCheckBoxActiveChange}
                    />
                  }
                  label={
                    this.state.city.active
                      ? t('areas.list.area-active')
                      : t('areas.list.area-inactive')
                  }
                />
              </FormControl>
            </Grid>

            <Grid container>
              <FormControl
                className={classes.formControl}
                style={{ paddingTop: 28, marginBottom: 16 }}
              >
                <FormLabel component="legend">{t('cities.center')}</FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasCenter}
                      className="customCheckbox"
                      name="active"
                      onChange={this.onCheckBoxCenter}
                    />
                  }
                  label={
                    hasCenter
                      ? t('areas.list.area-active')
                      : t('areas.list.area-inactive')
                  }
                />
                {hasCenter && !fetching && (
                  <>
                    <PlacesAutocomplete
                      onChange={this.handleChangeCenter}
                      onSelect={this.handleSelectCenter}
                      value={addressCenter}
                    >
                      {({
                        getInputProps,
                        getSuggestionItemProps,
                        loading,
                        suggestions
                      }) => (
                        <div className="location-search-section">
                          <FormControl
                            className={classes.formControl}
                            style={{ paddingTop: 12 }}
                          >
                            <TextField
                              {...getInputProps({
                                placeholder: t(
                                  'add-new-property.location.search-address'
                                ),
                                className: 'location-search-input'
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && (
                                <div>{t('add-new-property.location.loading')}</div>
                              )}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer'
                                    }
                                  : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer'
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </FormControl>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <div style={{ height: 400, width: '100%' }}>
                      <GoogleMap
                        center={[initCenterLocation.lat, initCenterLocation.lng]}
                        lat={locationCenter.lat}
                        lng={locationCenter.lng}
                        name="my title"
                        onMapClick={this.onMapClickCenter}
                      />
                    </div>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid container>
              <FormControl
                className={classes.formControl}
                style={{ paddingTop: 28, marginBottom: 16 }}
              >
                <FormLabel component="legend">{t('cities.airport')}</FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasAirport}
                      className="customCheckbox"
                      name="active"
                      onChange={this.onCheckBoxAirport}
                    />
                  }
                  label={
                    hasAirport
                      ? t('areas.list.area-active')
                      : t('areas.list.area-inactive')
                  }
                />
                {hasAirport && !fetching && (
                  <>
                    <PlacesAutocomplete
                      onChange={this.handleChangeAirport}
                      onSelect={this.handleSelectAirport}
                      value={addressAirport}
                    >
                      {({
                        getInputProps,
                        getSuggestionItemProps,
                        loading,
                        suggestions
                      }) => (
                        <div className="location-search-section">
                          <FormControl
                            className={classes.formControl}
                            style={{ paddingTop: 12 }}
                          >
                            <TextField
                              {...getInputProps({
                                placeholder: t(
                                  'add-new-property.location.search-address'
                                ),
                                className: 'location-search-input'
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && (
                                <div>{t('add-new-property.location.loading')}</div>
                              )}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer'
                                    }
                                  : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer'
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </FormControl>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <div style={{ height: 400, width: '100%' }}>
                      <GoogleMap
                        center={[initAirportLocation.lat, initAirportLocation.lng]}
                        lat={locationAirport.lat}
                        lng={locationAirport.lng}
                        name="my title"
                        onMapClick={this.onMapClickAirport}
                      />
                    </div>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid container>
              <FormControl
                className={classes.formControl}
                style={{ paddingTop: 28, marginBottom: 16 }}
              >
                <FormLabel component="legend">{t('cities.port')}</FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasPort}
                      className="customCheckbox"
                      name="active"
                      onChange={this.onCheckBoxPort}
                    />
                  }
                  label={
                    hasPort ? t('areas.list.area-active') : t('areas.list.area-inactive')
                  }
                />
                {hasPort && !fetching && (
                  <>
                    <PlacesAutocomplete
                      onChange={this.handleChangePort}
                      onSelect={this.handleSelectPort}
                      value={addressPort}
                    >
                      {({
                        getInputProps,
                        getSuggestionItemProps,
                        loading,
                        suggestions
                      }) => (
                        <div className="location-search-section">
                          <FormControl
                            className={classes.formControl}
                            style={{ paddingTop: 12 }}
                          >
                            <TextField
                              {...getInputProps({
                                placeholder: t(
                                  'add-new-property.location.search-address'
                                ),
                                className: 'location-search-input'
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && (
                                <div>{t('add-new-property.location.loading')}</div>
                              )}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer'
                                    }
                                  : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer'
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </FormControl>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    <div style={{ height: 400, width: '100%' }}>
                      <GoogleMap
                        center={[initPortLocation.lat, initPortLocation.lng]}
                        lat={locationPort.lat}
                        lng={locationPort.lng}
                        name="my title"
                        onMapClick={this.onMapClickPort}
                      />
                    </div>
                  </>
                )}
              </FormControl>
            </Grid>

            <Grid container spacing={10}>
              <Grid item xs={12}>
                <Button className={classes.button} type="submit" variant="contained">
                  {t('common.submit')}
                </Button>
                <Button
                  className="secondary rightButton deleteButton"
                  onClick={() => this.handleDeleteOpen()}
                  variant="contained"
                >
                  {t('cities.edit.delete')}
                </Button>
              </Grid>
            </Grid>
          </form>
          <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            onClose={this.handleDeleteClose}
            open={this.state.deleteDialog}
          >
            <DialogTitle style={{ background: 'red' }} id="alert-dialog-title">
              <span style={{ color: 'white' }}>
                {t('cities.edit.are-you-sure')}
                <em>{this.state.city.nameEl}</em>;
              </span>
            </DialogTitle>
            <DialogContent style={{ background: 'red', color: 'white' }}>
              <DialogContentText
                style={{ background: 'red', color: 'white' }}
                id="alert-dialog-description"
              >
                <strong>{t('cities.edit.attention')}</strong>
                {t('cities.edit.cannot-revert')}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              style={{
                background: 'red',
                margin: 0,
                paddingBottom: 8,
                paddingRight: 4,
                paddingTop: 8
              }}
            >
              <Button autoFocus onClick={this.handleDeleteClose} style={{ outline: 0 }}>
                {t('common.cancel')}
              </Button>
              <Button
                style={{ outline: 0, color: 'white' }}
                onClick={() => this.deleteCity(this.props.match.params.id)}
              >
                {t('cities.edit.delete')}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(withStyles(styles, { withTheme: true })(EditCity))
);
