import List from '../components/Cities/CitiesList';
import Add from '../components/Cities/AddNewCity';
import Edit from '../components/Cities/EditCity';

const routes = [
  {
    path: '/dashboard/city/list',
    exact: false,
    name: 'List City',
    component: List
  },
  {
    path: '/dashboard/city/add',
    exact: false,
    name: 'Add City',
    component: Add
  },
  {
    path: '/dashboard/city/edit/:id',
    exact: false,
    name: 'Edit City',
    component: Edit
  }
];

export default routes;
