import React, { Component, Fragment, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import Grid from '@material-ui/core/Grid';
import i18n from 'i18next';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import update from 'immutability-helper';
import { debounce } from 'lodash';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { Icon, message, Modal, notification, Upload } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import {
  categories,
  energyClassOptions,
  generateFakeLocation,
  houseTypes,
  stickers,
  translateName
} from '../../utils/utils';
import { apiUrl, axiosCallApi } from '../../config/config';
import GoogleMap from '../GoogleMap/GoogleMap';
import ReorderPhotos from '../../utils/ReorderPhotos';
import MyCustomUploadAdapterPlugin from '../UploadAdapter/UploadAdapter';
import { produce } from 'immer';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  button: {
    backgroundColor: 'transparent',
    border: '1px solid #000000',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'black',
      color: '#FFFFFF'
    },
    color: 'black',
    margin: theme.spacing(1)
  },
  input: {
    display: 'flex',
    height: 'inherit'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%'
  },
  formControlTitle: {
    margin: theme.spacing(1),
    width: '99%'
  },
  formText: {
    margin: theme.spacing(1),
    width: '94%'
  },
  formTextTitle: {
    margin: theme.spacing(1),
    width: '99%',
    marginTop: 20
  },
  valueContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(0.25)
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    fontSize: 16,
    left: 2,
    position: 'absolute'
  },
  paper: {
    marginTop: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(2)
  },
  selectControl: {
    position: 'absolute',
    width: '100%',
    zIndex: 99999
  },
  paperWrapper: {
    boxShadow: 'none',
    padding: 25
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          children: props.children,
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          ...props.innerProps
        }
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      component="div"
      selected={props.isFocused}
      style={{ fontWeight: props.isSelected ? 500 : 400 }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Menu(props) {
  return (
    <Paper
      className={[
        props.selectProps.classes.paper,
        props.selectProps.classes.selectControl
      ].join(' ')}
      square
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

class AddNewProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      property: {
        metaTitleEn: '',
        metaTitleEl: '',
        metaTitleZh: '',
        metaDescriptionEn: '',
        metaDescriptionEl: '',
        metaDescriptionZh: '',
        metaKeywordsEn: '',
        metaKeywordsEl: '',
        metaKeywordsZh: '',
        propertyId: '',
        _3dView: '',
        address: '',
        agent: '',
        amenities: [],
        approved: false,
        bathrooms: '',
        bedrooms: '',
        // balconies: '',
        category: [],
        city: '',
        condition: '',
        constructionYear: '',
        descriptionEl: '',
        descriptionEn: '',
        descriptionZh: '',
        fakeLat: 37.9988844,
        fakeLng: 23.735316,
        fakePinLat: 37.9988844,
        fakePinLng: 23.735316,
        floor: '',
        floorImage: {},
        furnished: false,
        images: [],
        lat: 37.9988844,
        lng: 23.735316,
        // nearBy: [],
        ownerEmail: '',
        ownerName: '',
        ownerPhone: '',
        ownerSurname: '',
        pdf: '',
        price: '',
        // pricePerSquare: '',
        recommended: false,
        region: '',
        regionDescriptionEl: '',
        regionDescriptionEn: '',
        regionDescriptionZh: '',
        regionImages: [],
        // regions: [],
        renovated: '',
        stickers: [],
        energyClass: '',
        titleEl: '',
        titleEn: '',
        titleZh: '',
        totalArea: '',
        totalFloors: '',
        transportations: [],
        type: ''
      },
      agents: [],
      amenities: [],
      amenity: [],
      fileListFloor: [],
      editFileListProperty: false,
      fileListProperty: [],
      fileListPdf: [],
      // fileListRegion: [],
      // nearby: [],
      neighborhood: [],
      regions: [],
      scopes: [],
      submitBtnIsDisabled: false,
      transportation: [],
      transportations: []
    };
  }

  async componentDidMount() {
    const { t } = this.props;

    const status = [
      { value: 'renovated', label: t('list-properties.property-status.renovated') },
      {
        value: 'needs-to-be-renovated',
        label: t('list-properties.property-status.needs-to-be-renovated')
      },
      {
        value: 'satisfactory',
        label: t('list-properties.property-status.satisfactory')
      },
      {
        value: 'good',
        label: t('list-properties.property-status.good')
      },
      {
        value: 'very-good',
        label: t('list-properties.property-status.very-good')
      },
      {
        value: 'excellent',
        label: t('list-properties.property-status.excellent')
      },
      {
        value: 'new',
        label: t('list-properties.property-status.new')
      }
      // { value: 'renovated', label: t('list-properties.property-status.renovated') },
    ];

    const responseScopes = await axiosCallApi.post(`${apiUrl}/admin/getRole`);
    this.setState({ scopes: responseScopes.data.scopes, status });
    this.initSelects();
  }

  initSelects = () => {
    const { t } = this.props;

    axiosCallApi
      .get(`${apiUrl}/agent/names`)
      .then((response) => {
        const newAgents = [];
        response.data.forEach((el) => {
          newAgents.push({ label: `${el.name} ${el.surname}`, value: el._id });
        });
        this.setState({ agents: newAgents });
      })
      .catch(() => {
        notification.error({
          message: t('common.no-agents-found')
        });
      });

    // axiosCallApi
    //   .get(`${apiUrl}/nearby/names`)
    //   .then((response) => {
    //     const newNearbys = [];
    //     response.data.forEach((el) => {
    //       newNearbys.push({
    //         label: translateName(i18n.language, el),
    //         value: el._id
    //       });
    //     });
    //     this.setState({ nearby: newNearbys });
    //   })
    //   .catch(() => {
    //     notification.error({
    //       message: t('common.no-poi-found')
    //     });
    //   });

    axiosCallApi
      .get(`${apiUrl}/amenity/names`)
      .then((response) => {
        const newAmenities = [];
        response.data.forEach((el) => {
          newAmenities.push({
            label: translateName(i18n.language, el),
            value: el._id
          });
        });
        this.setState({ amenities: newAmenities });
      })
      .catch(() => {
        notification.error({
          message: t('common.no-amenities-found')
        });
      });

    axiosCallApi
      .get(`${apiUrl}/city/all/properties`)
      .then((response) => {
        this.setState({ cities: response.data.cities });
      })
      .catch(() => {
        notification.error({
          message: t('common.no-cities-found')
        });
      });

    // axiosCallApi
    //   .get(`${apiUrl}/region/names`)
    //   .then((response) => {
    //     const newAmenities = [];
    //     response.data.forEach((el) => {
    //       newAmenities.push({
    //         label: translateName(i18n.language, el),
    //         value: el._id
    //       });
    //     });
    //     this.setState({ regions: newAmenities });
    //   })
    //   .catch(() => {
    //     notification.error({
    //       message: t('common.no-regions-found')
    //     });
    //   });

    axiosCallApi
      .get(`${apiUrl}/transportation/names`)
      .then((response) => {
        const newAmenities = [];
        response.data.forEach((el) => {
          newAmenities.push({
            label: translateName(i18n.language, el),
            value: el._id
          });
        });
        this.setState({ transportations: newAmenities });
      })
      .catch(() => {
        notification.error({
          message: t('common.no-transportations-found')
        });
      });
  };

  handleChangeFurnished = (event) => {
    this.setState(
      update(this.state, {
        property: {
          furnished: { $set: event.target.checked }
        }
      })
    );
  };

  handleChangeApproved = (event) => {
    this.setState(
      update(this.state, {
        property: {
          approved: { $set: event.target.checked }
        }
      })
    );
  };

  handleChangeRecommended = (event) => {
    this.setState(
      update(this.state, {
        property: {
          recommended: { $set: event.target.checked }
        }
      })
    );
  };

  debounceEvent = (...args) => {
    this.debouncedEvent = debounce(...args);
    return (e) => {
      e.persist();
      return this.debouncedEvent(e);
    };
  };

  onInputChange = (e) => {
    if (e) {
      this.setState(
        update(this.state, {
          property: {
            [e.target.name]: { $set: e.target.value }
          }
        })
      );
    }
  };

  // handleChangeType = event => {
  //   this.setState({[event.target.name]: event.target.value});
  // };

  handleSelectChangeAgent = (agent) => {
    this.setState(
      update(this.state, {
        property: {
          agent: { $set: agent }
        }
      })
    );
  };

  handleSelectChangeCity = (city) => {
    if (city._id === this.state.property.city._id) {
      return;
    }

    const { t } = this.props;

    axiosCallApi
      .get(`${apiUrl}/region/city/${city._id}`)
      .then((response) => {
        this.setState({ regions: response.data.regions });
      })
      .catch(() => {
        notification.error({
          message: t('common.no-poi-found')
        });
      });

    this.setState(
      update(this.state, {
        property: {
          city: { $set: city },
          region: { $set: null }
        }
      })
    );
  };

  handleSelectChangeRegion = (region) => {
    this.setState(
      update(this.state, {
        property: {
          region: { $set: region }
        }
      })
    );
  };

  handleSelectChangeFlatType = (value) => {
    this.setState(
      update(this.state, {
        property: {
          type: { $set: value }
        }
      })
    );
  };

  handleSelectChangeStatus = (condition) => {
    this.setState(
      update(this.state, {
        property: {
          condition: { $set: condition }
        }
      })
    );
  };

  handleSelectChangeCategory = (category) => {
    this.setState(
      update(this.state, {
        property: {
          category: { $set: category }
        }
      })
    );
  };

  handleSelectChangeEnergy = (energy) => {
    this.setState(
      update(this.state, {
        property: {
          energyClass: { $set: energy }
        }
      })
    );
  };

  handleSelectChangeStickers = (sticker) => {
    this.setState(
      update(this.state, {
        property: {
          stickers: { $set: sticker }
        }
      })
    );
  };

  // eslint-disable-next-line
  changeDescriptionEnDebounced = debounce(
    (input) => this.handleDescriptionChangeEn(input),
    2000,
    { maxWait: 2000 }
  );

  changeDescriptionElDebounced = debounce(
    (input) => this.handleDescriptionChangeEl(input),
    2000,
    { maxWait: 2000 }
  );

  changeDescriptionZhDebounced = debounce(
    (input) => this.handleDescriptionChangeZh(input),
    2000,
    { maxWait: 2000 }
  );

  handleDescriptionChangeEn = (value) => {
    this.setState(
      update(this.state, {
        property: {
          descriptionEn: { $set: value }
        }
      })
    );
  };

  handleDescriptionChangeEl = (value) => {
    this.setState(
      update(this.state, {
        property: {
          descriptionEl: { $set: value }
        }
      })
    );
  };

  handleDescriptionChangeZh = (value) => {
    this.setState(
      update(this.state, {
        property: {
          descriptionZh: { $set: value }
        }
      })
    );
  };

  changeNeighborhoodDescriptionEnDebounced = debounce(
    (input) => this.handleNeighborhoodDescriptionChangeEn(input),
    800,
    { maxWait: 800 }
  );

  changeNeighborhoodDescriptionElDebounced = debounce(
    (input) => this.handleNeighborhoodDescriptionChangeEl(input),
    800,
    { maxWait: 800 }
  );

  changeNeighborhoodDescriptionZhDebounced = debounce(
    (input) => this.handleNeighborhoodDescriptionChangeZh(input),
    800,
    { maxWait: 800 }
  );

  handleNeighborhoodDescriptionChangeEn = (value) => {
    this.setState(
      update(this.state, {
        property: {
          regionDescriptionEn: { $set: value }
        }
      })
    );
  };

  handleNeighborhoodDescriptionChangeEl = (value) => {
    this.setState(
      update(this.state, {
        property: {
          regionDescriptionEl: { $set: value }
        }
      })
    );
  };

  handleNeighborhoodDescriptionChangeZh = (value) => {
    this.setState(
      update(this.state, {
        property: {
          regionDescriptionZh: { $set: value }
        }
      })
    );
  };

  handlePreviewProperty = (file) => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handleChangeProperty = ({ fileList }) => {
    this.setState({ fileListProperty: fileList });
  };

  handleChangeFloor = ({ fileList }) => {
    this.setState({ fileListFloor: fileList });
  };

  handleChangePdf = ({ fileList }) => {
    this.setState({ fileListPdf: fileList });
  };

  // handleChangeRegion = ({ fileList }) => {
  //   this.setState({ fileListRegion: fileList });
  // };

  handleAmenityMultipleChange = (e) => {
    this.setState(
      update(this.state, {
        property: { amenities: { $set: e } }
      })
    );
  };

  handleTransportationMultipleChange = (e) => {
    this.setState(
      update(this.state, {
        property: { transportations: { $set: e } }
      })
    );
  };

  // handleNeighborhoodsMultipleChange = (e) => {
  //   this.setState(
  //     update(this.state, {
  //       property: { regions: { $set: e } }
  //     })
  //   );
  // };

  // handleNearByMultipleChange = (e) => {
  //   this.setState(
  //     update(this.state, {
  //       property: { nearBy: { $set: e } }
  //     })
  //   );
  // };

  beforeUploadProperty = (file) => {
    const { t } = this.props;
    return new Promise((resolve, reject) => {
      if (
        file.type !== 'image/jpeg' &&
        file.type !== 'image/png' &&
        file.type !== 'image/jpg'
      ) {
        notification.error({ message: t('image-upload.unsupported-files') });
        reject(file);
      } else if (file.size / 1024 / 1024 > 5) {
        notification.error({ message: t('image-upload.image-file-size') });
        reject(file);
      } else {
        resolve(file);
      }
    });
  };

  beforeUploadPdf = (file) => {
    const { t } = this.props;
    return new Promise((resolve, reject) => {
      if (file.type !== 'application/pdf') {
        notification.error({ message: t('pdf-unsuported') });
        reject(file);
      } else if (file.size / 1024 / 1024 > 5) {
        notification.error({ message: t('image-upload.image-file-size') });
        reject(file);
      } else {
        resolve(file);
      }
    });
  };

  removeImageProperty = (file) => {
    const { t } = this.props;
    const imgName = file.response.data.image.name;
    axiosCallApi
      .delete(`${apiUrl}/uploads/delete/temp/single/${imgName}`)
      .then(() => {
        this.setState(
          update(this.state, {
            property: {
              images: (images) => images.filter((item) => item.image.name !== imgName)
            }
          })
        );
        notification.success({
          message: `${t('image-upload.image-delete.part1')} ${file.name} ${t(
            'image-upload.image-delete.part2'
          )} `
        });
      })
      .catch(() => message.error(`${t('image-upload.image-error')} ${file.name}`));
  };

  removeImageFloor = (file) => {
    const { t } = this.props;
    const imgName = file.response.data.image.name;
    axiosCallApi
      .delete(`${apiUrl}/uploads/delete/temp/single/${imgName}`)
      .then(() => {
        this.setState(
          update(this.state, {
            property: {
              floorImage: {}
            }
          })
        );
        notification.success({
          message: `${t('image-upload.image-delete.part1')} ${file.name} ${t(
            'image-upload.image-delete.part2'
          )} `
        });
      })
      .catch(() => {
        message.error(`${t('image-upload.image-error')}  ${file.name}`);
      });
  };

  removePdf = async (file) => {
    const { t } = this.props;
    const imgName = file.response.data.pdf.name;
    axiosCallApi
      .delete(`${apiUrl}/uploads/deletePdf/temp/single/${imgName}`)
      .then(() => {
        this.setState(
          update(this.state, {
            property: {
              pdf: {
                $set: {}
              }
            }
          })
        );
      })
      .catch(() => message.error(`${t('pdf-error')} ${imgName}`));
  };

  removeImageRegion = (file) => {
    const { t } = this.props;
    const imgName = file.response.data.image.name;
    axiosCallApi
      .delete(`${apiUrl}/uploads/delete/temp/single/${imgName}`)
      .then(() => {
        this.setState(
          update(this.state, {
            property: {
              regionImages: (regionImages) =>
                regionImages.filter((item) => item.image.name !== imgName)
            }
          })
        );
        notification.success({
          message: `${t('image-upload.image-delete.part1')} ${file.name} ${t(
            'image-upload.image-delete.part2'
          )} `
        });
      })
      .catch(() => {
        message.error(`${t('image-upload.image-error')} ${file.name}`);
      });
  };

  handleUploadProperty = ({ ...props }) => {
    const { t } = this.props;
    if (this.state.fileListProperty.length > 30) {
      return message.error(
        `${t('image-upload.max-files-reached.part1')} ${t(
          'image-upload.max-files-reached.part4'
        )} ${t('image-upload.max-files-reached.part5')}`
      );
    }
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append('file', props.file, props.file.name);
    axiosCallApi
      .post(`${apiUrl}/uploads/new`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: ({ total, loaded }) => {
          props.onProgress(
            { percent: Math.round((loaded / total) * 100).toFixed(2) },
            props.file
          );
        }
      })
      .then((response) => {
        props.onSuccess(response, props.file);
        notification.success({
          message: ` ${t('image-upload.image-success')}  ${props.file.name}`
        });
        this.setState(
          update(this.state, {
            property: {
              images: {
                $push: [{ ...response.data, originalFileName: props.file.name }]
              }
            }
          })
        );
      })
      .catch(() => {
        message.error(`${t('image-upload.image-error')}  ${props.file.name}`);
        return props.onError;
      });
    return {
      abort() {
        message.error(`${t('image-upload.image-stop')}  ${props.file.name}`);
      }
    };
  };

  handleUploadFloor = ({ ...props }) => {
    const { t } = this.props;
    if (this.state.fileListFloor.length > 0) {
      return message.error(
        `${t('image-upload.max-files-reached.part1')} ${t(
          'image-upload.max-files-reached.part2'
        )} ${t('image-upload.max-files-reached.part5')}`
      );
    }

    this.setState({ loading: true });
    const formData = new FormData();
    formData.append('file', props.file, props.file.name);
    axiosCallApi
      .post(`${apiUrl}/uploads/new`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: ({ total, loaded }) => {
          props.onProgress(
            { percent: Math.round((loaded / total) * 100).toFixed(2) },
            props.file
          );
        }
      })
      .then((response) => {
        props.onSuccess(response, props.file);
        message.success(` ${t('image-upload.image-success')}  ${props.file.name}`);
        this.setState(
          update(this.state, {
            property: {
              floorImage: {
                $set: response.data
              }
            }
          })
        );
      })
      .catch(() => {
        message.error(`${t('image-upload.image-error')}  ${props.file.name}`);
        return props.onError;
      });
    return {
      abort() {
        message.error(`${t('image-upload.image-stop')}  ${props.file.name}`);
      }
    };
  };

  handleUploadPdf = ({ ...props }) => {
    const formData = new FormData();
    formData.append('file', props.file, props.file.name);
    axiosCallApi
      .post(`${apiUrl}/uploads/pdf`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((response) => {
        props.onSuccess(response, props.file);
        this.setState(
          update(this.state, {
            property: {
              pdf: {
                $set: response.data.pdf
              }
            }
          })
        );
      })
      .catch(
        () =>
          // message.error(`${t('image-upload.image-error')}  ${props.file.name}`);
          props.onError
      );
  };

  handleUploadRegion = ({ ...props }) => {
    const { t } = this.props;
    if (this.state.fileListFloor.length > 20) {
      return message.error(
        `${t('image-upload.max-files-reached.part1')} ${t(
          'image-upload.max-files-reached.part3'
        )} ${t('image-upload.max-files-reached.part5')}`
      );
    }

    this.setState({ loading: true });
    const formData = new FormData();
    formData.append('file', props.file, props.file.name);
    axiosCallApi
      .post(`${apiUrl}/uploads/new`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: ({ total, loaded }) => {
          props.onProgress(
            { percent: Math.round((loaded / total) * 100).toFixed(2) },
            props.file
          );
        }
      })
      .then((response) => {
        props.onSuccess(response, props.file);
        message.success(` ${t('image-upload.image-uploaded')} ${props.file.name}`);
        this.setState(
          update(this.state, {
            property: {
              regionImages: {
                $push: [response.data]
              }
            }
          })
        );
      })
      .catch(() => {
        message.error(`${t('image.upload-image-error')} ${props.file.name}`);
        return props.onError;
      });
    return {
      abort() {
        message.error(`${t('image-upload.image-stop')} ${props.file.name}`);
      }
    };
  };

  submitNewProperty = (e) => {
    e.preventDefault();
    const { t } = this.props;
    const { property } = this.state;

    if (!property.agent) {
      notification.error({
        message: t('add-new-property.error-agent')
      });
      return;
    }
    if (!property.region) {
      notification.error({
        message: t('add-new-property.error-region')
      });
      return;
    }
    if (property.images.length === 0) {
      notification.error({
        message: t('add-new-property.error-images')
      });
      return;
    }

    const data = property;
    data.agent = property.agent.value;
    axiosCallApi
      .post(`${apiUrl}/property/new`, data)
      .then(() => {
        notification.success({
          message: t('add-new-property.submit-success')
        });
        this.props.history.push('/dashboard/properties/list');
      })
      .catch(() => {
        notification.error({
          message: t('add-new-property.submit-error')
        });
      });
  };

  handleChange = (address) => {
    this.setState(
      update(this.state, {
        property: {
          address: { $set: address }
        }
      })
    );
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.setState(
          update(this.state, {
            property: {
              address: { $set: address },
              fakeLat: { $set: latLng.lat },
              fakeLng: { $set: latLng.lng },
              fakePinLat: { $set: latLng.lat },
              fakePinLng: { $set: latLng.lng },
              lat: { $set: latLng.lat },
              lng: { $set: latLng.lng }
            }
          })
        );
      })
      .catch((error) => console.error('Error', error));
  };

  onMapClick = (lat, lng) => {
    this.setState(
      update(this.state, {
        property: {
          fakeLat: { $set: lat },
          fakeLng: { $set: lng }
        }
      })
    );
  };

  randomize = () => {
    const fakeLocation = generateFakeLocation(
      this.state.property.lat,
      this.state.property.lng
    );
    this.setState(
      update(this.state, {
        property: {
          fakePinLat: { $set: fakeLocation.lat },
          fakePinLng: { $set: fakeLocation.lng }
        }
      })
    );
  };

  editFileListProperty = () => {
    this.setState({
      editFileListProperty: !this.state.editFileListProperty
    });
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  moveImage = (left, i) => {
    const { fileListProperty } = this.state;
    const { images } = this.state.property;

    const newFileList = [...fileListProperty];
    const newImages = [...images];

    if (left) {
      const it = newFileList[i];
      newFileList[i] = newFileList[i - 1];
      newFileList[i - 1] = it;
      const it2 = newImages[i];
      newImages[i] = newImages[i - 1];
      newImages[i - 1] = it2;
    } else {
      const it = newFileList[i];
      newFileList[i] = newFileList[i + 1];
      newFileList[i + 1] = it;
      const it2 = newImages[i];
      newImages[i] = newImages[i + 1];
      newImages[i + 1] = it2;
    }

    this.setState(
      update(this.state, {
        fileListProperty: { $set: newFileList },
        property: {
          images: {
            $set: newImages
          }
        }
      })
    );
  };

  savePhotosOrder = (orderedPhotos) => {
    const newImages = orderedPhotos.map((op) => op?.response?.data || op);

    this.setState(
      update(this.state, {
        fileListProperty: { $set: orderedPhotos },
        editFileListProperty: { $set: false },
        property: {
          images: {
            $set: newImages
          }
        }
      })
    );
  };

  onAltTextChange = (value, index) => {
    const updatedItems = produce(this.state.property.images, (draft) => {
      draft[index].image.alt = value.target.value;
    });

    this.setState(
      update(this.state, {
        property: {
          images: {
            $set: updatedItems
          }
        }
      })
    );
  };

  render() {
    const { classes, theme, t } = this.props;
    const {
      fileListFloor,
      editFileListProperty,
      fileListProperty,
      fileListPdf,
      // fileListRegion,
      previewImage,
      previewVisible,
      property,
      regions,
      scopes
    } = this.state;

    const { approved } = property;

    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">
          {t('add-new-property.property.upload-images')}
        </div>
      </div>
    );

    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary
      })
    };

    return (
      <div>
        <Typography variant="h6" gutterBottom>
          {t('add-new-property.new-property')}
        </Typography>
        <Paper className={classes.paperWrapper}>
          <h1 className="title-wrapper">
            <span>{t('add-new-property.owner.info')}</span>
          </h1>
          <form onSubmit={(e) => this.submitNewProperty(e)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="ownerName"
                    label={t('add-new-property.owner.name')}
                    name="ownerName"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="ownerSurname"
                    label={t('add-new-property.owner.last-name')}
                    name="ownerSurname"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="ownerEmail"
                    label={t('add-new-property.owner.email')}
                    name="ownerEmail"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                    type="email"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="ownerPhone"
                    label={t('add-new-property.owner.phone')}
                    name="ownerPhone"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                    type="number"
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: 35 }}>
              <h1 className="title-wrapper">
                <span>{t('add-new-property.property.info')}</span>
              </h1>
            </Grid>
            {(scopes.includes('admin') || scopes.includes('CM')) && (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={property.approved}
                          className="customCheckbox"
                          name="active"
                          onChange={this.handleChangeApproved}
                        />
                      }
                      label={
                        property.approved
                          ? t('add-new-property.property.approved')
                          : t('add-new-property.property.not-approved')
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <Grid container>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="propertyId"
                    label={t('add-new-property.property.propertyId')}
                    name="propertyId"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                    required={approved}
                    type="number"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  {/* <InputLabel htmlFor="age-simple">Agent</InputLabel> */}
                  <Select
                    classes={classes}
                    components={components}
                    isSearchable
                    name="agent"
                    onChange={this.handleSelectChangeAgent}
                    options={this.state.agents}
                    placeholder={`${t('add-new-property.property.agent')} ${
                      approved ? '*' : ''
                    }`}
                    required={approved}
                    styles={selectStyles}
                    value={property.agent}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    isSearchable
                    name="city"
                    onChange={this.handleSelectChangeCity}
                    options={this.state.cities}
                    placeholder={`${t('add-new-property.property.city')} ${
                      approved ? '*' : ''
                    }`}
                    required={approved}
                    styles={selectStyles}
                    getOptionLabel={(o) => o.nameEl}
                    value={property.city}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    isSearchable
                    name="region"
                    onChange={this.handleSelectChangeRegion}
                    options={regions}
                    placeholder={`${t('add-new-property.property.area')} ${
                      approved ? '*' : ''
                    }`}
                    required={approved}
                    styles={selectStyles}
                    getOptionLabel={(o) =>
                      `${o.nameEl}${
                        o.sector && o.sector !== undefined ? ` - ${o.sector.nameEl}` : ''
                      }`
                    }
                    value={property.region}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    isSearchable
                    name="type"
                    onChange={this.handleSelectChangeFlatType}
                    options={houseTypes(t)}
                    placeholder={`${t('add-new-property.property.type')} ${
                      approved ? '*' : ''
                    }`}
                    required={approved}
                    styles={selectStyles}
                    value={property.type}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="totalArea"
                    label={t('add-new-property.property.aream2')}
                    name="totalArea"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                    required={approved}
                    type="number"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="price"
                    label={`${t('add-new-property.property.price')} ${
                      approved ? '*' : ''
                    }`}
                    name="price"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                    type="number"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    isSearchable
                    name="category"
                    isMulti
                    onChange={this.handleSelectChangeCategory}
                    options={categories}
                    placeholder={`${t('add-new-property.property.category')}`}
                    styles={selectStyles}
                    value={property.category}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    isSearchable
                    name="sticker"
                    isMulti
                    onChange={this.handleSelectChangeStickers}
                    options={stickers}
                    placeholder="Stickers"
                    styles={selectStyles}
                    value={property.stickers}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    isSearchable
                    name="energyClass"
                    onChange={this.handleSelectChangeEnergy}
                    options={energyClassOptions}
                    getOptionLabel={(c) => t(`energy.${c.label}`)}
                    placeholder="Energy class"
                    styles={selectStyles}
                    value={property.energyClass}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    components={components}
                    isSearchable
                    name="condition"
                    onChange={this.handleSelectChangeStatus}
                    options={this.state.status}
                    placeholder={`${t('add-new-property.property.status')} ${
                      approved ? '*' : ''
                    }`}
                    required={approved}
                    styles={selectStyles}
                    value={property.status}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="total-floors"
                    label={`${t('add-new-property.property.floors')} ${
                      approved ? '*' : ''
                    }`}
                    name="totalFloors"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                    type="number"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="floor"
                    label={t('add-new-property.property.floor')}
                    name="floor"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                    required={approved}
                    type="number"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="beds"
                    label={t('add-new-property.property.bedrooms')}
                    name="bedrooms"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                    required={approved}
                    type="number"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="renovated"
                    label={t('add-new-property.property.renovated')}
                    name="renovated"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="bats"
                    label={t('add-new-property.property.bathrooms')}
                    name="bathrooms"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                    required={approved}
                    type="number"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl className={classes.formControl}>
                  <TextField
                    fullWidth
                    id="building-age"
                    label={t('add-new-property.property.year-of-construction')}
                    name="constructionYear"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                    required={approved}
                    type="number"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl className={classes.formControl}>
                  {/* <InputLabel htmlFor="select-multiple-chip">Ανέσεις Ακινήτου</InputLabel> */}
                  <Select
                    classes={classes}
                    className="basic-multi-select"
                    classNamePrefix="Ανέσεις"
                    components={components}
                    required={approved}
                    isMulti
                    onChange={this.handleAmenityMultipleChange}
                    options={this.state.amenities}
                    placeholder={t('add-new-property.property.amenities')}
                    styles={selectStyles}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    className="basic-multi-select"
                    classNamePrefix="Μεταφορές"
                    components={components}
                    required={approved}
                    isMulti
                    onChange={this.handleTransportationMultipleChange}
                    options={this.state.transportations}
                    placeholder={`${t('add-new-property.property.transports')} ${
                      approved ? '*' : ''
                    }`}
                    styles={selectStyles}
                  />
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} md={3}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    className="basic-multi-select"
                    classNamePrefix="Περιοχές"
                    components={components}
                    isMulti
                    onChange={this.handleNeighborhoodsMultipleChange}
                    options={this.state.regions}
                    placeholder={t('add-new-property.property.areas')}
                    styles={selectStyles}
                  />
                </FormControl>
              </Grid> */}
              {/* <Grid item xs={12} md={3}>
                <FormControl className={classes.formControl}>
                  <Select
                    classes={classes}
                    className="basic-multi-select"
                    classNamePrefix="Περιοχές"
                    components={components}
                    isMulti
                    onChange={this.handleNearByMultipleChange}
                    options={this.state.nearby}
                    placeholder={t('add-new-property.property.nearby')}
                    styles={selectStyles}
                  />
                </FormControl>
              </Grid> */}
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={property.furnished}
                        className="customCheckbox"
                        name="active"
                        onChange={this.handleChangeFurnished}
                      />
                    }
                    label={t('add-new-property.property.furnished')}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={this.handleChangeRecommended}
                        className="customCheckbox"
                        name="active"
                        checked={property.recommended}
                      />
                    }
                    label={t('add-new-property.property.recommended')}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Card style={{ padding: 15, marginTop: 25 }}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h6" gutterBottom>
                  {t('add-new-property.property.header-en')}
                </Typography>
                <FormControl className={classes.formControlTitle}>
                  <TextField
                    fullWidth
                    id="titleEn"
                    label={t('add-new-property.property.title-en')}
                    name="titleEn"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                    required={approved}
                  />
                </FormControl>
                <FormControl className={classes.formControlTitle}>
                  <TextField
                    fullWidth
                    id="metaTitleEn"
                    label="Meta Title English"
                    name="metaTitleEn"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                  />
                </FormControl>
                <FormControl className={classes.formControlTitle}>
                  <TextField
                    fullWidth
                    id="metaDescriptionEn"
                    label="Meta Description English"
                    name="metaDescriptionEn"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                  />
                </FormControl>
                <FormControl className={classes.formControlTitle}>
                  <TextField
                    fullWidth
                    id="metaKeywordsEn"
                    label="Meta Keywords English"
                    name="metaKeywordsEn"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                  />
                </FormControl>
                <FormControl className={classes.formTextTitle}>
                  <FormLabel component="legend" style={{ marginBottom: 16 }}>
                    {t('add-new-property.property.description-en')}
                  </FormLabel>
                  <CKEditor
                    config={{ extraPlugins: [MyCustomUploadAdapterPlugin] }}
                    data={property.descriptionEn ? property.descriptionEn : ''}
                    editor={ClassicEditor}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.changeDescriptionEnDebounced(data);
                    }}
                  />
                </FormControl>
              </Grid>
            </Card>
            <Card style={{ padding: 15, marginTop: 25 }}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h6" gutterBottom>
                  {t('add-new-property.property.header-el')}
                </Typography>
                <FormControl className={classes.formControlTitle}>
                  <TextField
                    fullWidth
                    id="titleEl"
                    label={t('add-new-property.property.title-el')}
                    name="titleEl"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                    required={approved}
                  />
                </FormControl>
                <FormControl className={classes.formControlTitle}>
                  <TextField
                    fullWidth
                    id="metaTitleEl"
                    label="Meta Title Greek"
                    name="metaTitleEn"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                  />
                </FormControl>
                <FormControl className={classes.formControlTitle}>
                  <TextField
                    fullWidth
                    id="metaDescriptionEl"
                    label="Meta Description Greek"
                    name="metaDescriptionEl"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                  />
                </FormControl>
                <FormControl className={classes.formControlTitle}>
                  <TextField
                    fullWidth
                    id="metaKeywordsEl"
                    label="Meta Keywords Greek"
                    name="metaKeywordsEl"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                  />
                </FormControl>
                <FormControl className={classes.formTextTitle}>
                  <FormLabel component="legend" style={{ marginBottom: 16 }}>
                    {t('add-new-property.property.description-el')}
                  </FormLabel>
                  <CKEditor
                    config={{ extraPlugins: [MyCustomUploadAdapterPlugin] }}
                    data={property.descriptionEl}
                    editor={ClassicEditor}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.changeDescriptionElDebounced(data);
                    }}
                  />
                </FormControl>
              </Grid>
            </Card>
            <Card style={{ padding: 15, marginTop: 25, marginBottom: 25 }}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h6" gutterBottom>
                  {t('add-new-property.property.header-zh')}
                </Typography>
                <FormControl className={classes.formControlTitle}>
                  <TextField
                    fullWidth
                    id="titleZh"
                    label={t('add-new-property.property.title-zh')}
                    name="titleZh"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                  />
                </FormControl>
                <FormControl className={classes.formControlTitle}>
                  <TextField
                    fullWidth
                    id="metaTitleZh"
                    label="Meta Title Chinese"
                    name="metaTitleZh"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                  />
                </FormControl>
                <FormControl className={classes.formControlTitle}>
                  <TextField
                    fullWidth
                    id="metaDescriptionZh"
                    label="Meta Description Chinese"
                    name="metaDescriptionZh"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                  />
                </FormControl>
                <FormControl className={classes.formControlTitle}>
                  <TextField
                    fullWidth
                    id="metaKeywordsZh"
                    label="Meta Keywords Chinese"
                    name="metaKeywordsZh"
                    onChange={this.debounceEvent(this.onInputChange, 100)}
                  />
                </FormControl>
                <FormControl className={classes.formTextTitle}>
                  <FormLabel component="legend" style={{ marginBottom: 16 }}>
                    {t('add-new-property.property.description-zh')}
                  </FormLabel>
                  <CKEditor
                    config={{ extraPlugins: [MyCustomUploadAdapterPlugin] }}
                    data={property.descriptionZh}
                    editor={ClassicEditor}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      this.changeDescriptionZhDebounced(data);
                    }}
                  />
                </FormControl>
              </Grid>
            </Card>
            <Grid item xs={12} md={12}>
              <FormControl className={classes.formControl}>
                <FormLabel component="legend">
                  {`${t('add-new-property.property.images')} ${approved ? '*' : ''}`}
                </FormLabel>

                {!editFileListProperty && fileListProperty.length > 1 && (
                  <Button
                    variant="contained"
                    className={classes.button}
                    onClick={this.editFileListProperty}
                    style={{ marginBottom: 16 }}
                  >
                    edit
                  </Button>
                )}
                {editFileListProperty ? (
                  <ReorderPhotos
                    className={classes.button}
                    images={fileListProperty}
                    save={this.savePhotosOrder}
                    close={() => this.setState({ editFileListProperty: false })}
                  />
                ) : (
                  <Upload
                    beforeUpload={this.beforeUploadProperty}
                    customRequest={this.handleUploadProperty}
                    fileList={fileListProperty}
                    multiple
                    listType="picture-card"
                    onChange={this.handleChangeProperty}
                    onPreview={this.handlePreviewProperty}
                    onRemove={(file) => this.removeImageProperty(file)}
                  >
                    {fileListProperty.length < 30 ? uploadButton : null}
                  </Upload>
                )}
                {this.state.property.images.map((item, index) => (
                  <Fragment key={item.image.uid}>
                    <FormControl className={classes.formControl}>
                      <TextField
                        fullWidth
                        id={item.image.uid}
                        label={`Image ${index + 1} Alt:`}
                        name={item.image.name}
                        onChange={(e) => this.onAltTextChange(e, index)}
                        defaultValue={item?.image?.alt}
                      />
                    </FormControl>
                  </Fragment>
                ))}

                <Modal
                  footer={null}
                  onCancel={this.handleCancel}
                  visible={previewVisible}
                >
                  <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl className={classes.formControl}>
                <FormLabel component="legend">
                  {t('add-new-property.property.floor-plan')}
                </FormLabel>
                <Upload
                  // action="http://localhost:4000/ads/upload"
                  beforeUpload={this.beforeUploadProperty}
                  customRequest={this.handleUploadFloor}
                  fileList={fileListFloor}
                  listType="picture-card"
                  onChange={this.handleChangeFloor}
                  onPreview={this.handlePreviewProperty}
                  onRemove={(file) => this.removeImageFloor(file)}
                >
                  {fileListFloor.length < 1 ? uploadButton : null}
                </Upload>
                <Modal
                  footer={null}
                  onCancel={this.handleCancel}
                  visible={previewVisible}
                >
                  <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
              </FormControl>
            </Grid>
            {/* PDF */}
            <Grid item xs={12} md={12}>
              <Upload
                beforeUpload={this.beforeUploadPdf}
                customRequest={this.handleUploadPdf}
                fileList={fileListPdf}
                onChange={this.handleChangePdf}
                onRemove={(file) => this.removePdf(file)}
              >
                {fileListPdf.length < 1 && (
                  <Button
                  /* icon={<UploadOutlined />} */
                  >
                    Upload Pdf
                  </Button>
                )}
              </Upload>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FormControl className={classes.formControl}>
                <TextField
                  fullWidth
                  id="price-per-meter"
                  label={t('add-new-property.property.3dview')}
                  name="_3dView"
                  onChange={this.debounceEvent(this.onInputChange, 100)}
                />
              </FormControl>
            </Grid>
            <Grid container style={{ marginTop: 35 }}>
              <Grid item xs={12} sm={6} md={12}>
                <h1 className="title-wrapper">
                  <span>{t('add-new-property.location.info')}</span>
                </h1>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                style={{
                  alignItems: 'flex-start',
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: 20,
                  width: '100%'
                }}
              >
                <InputLabel
                  className="custom-label"
                  htmlFor="team-disabled"
                  style={{ paddingLeft: 12 }}
                  required={approved}
                >
                  {t('add-new-property.location.address')}
                </InputLabel>
                <PlacesAutocomplete
                  onChange={this.handleChange}
                  onSelect={this.handleSelect}
                  value={property.address}
                >
                  {({ getInputProps, getSuggestionItemProps, loading, suggestions }) => (
                    <div className="location-search-section">
                      <FormControl
                        className={classes.formControl}
                        style={{ paddingTop: 12 }}
                      >
                        <TextField
                          {...getInputProps({
                            placeholder: t('add-new-property.location.search-address'),
                            className: 'location-search-input'
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>{t('add-new-property.location.loading')}</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: '#fafafa',
                                  cursor: 'pointer'
                                }
                              : {
                                  backgroundColor: '#ffffff',
                                  cursor: 'pointer'
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </FormControl>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Grid>
              <div style={{ height: 400, width: '100%' }}>
                <GoogleMap
                  center={[property.fakeLat, property.fakeLng]}
                  lat={property.fakeLat}
                  lng={property.fakeLng}
                  name={property.title}
                  onMapClick={this.onMapClick}
                />
              </div>
            </Grid>

            {/* FAKE LOCATION */}

            <Grid container style={{ marginTop: 35 }}>
              <Grid item xs={12} sm={6} md={12}>
                <h1 className="title-wrapper">
                  <span>{t('add-new-property.location.fake')}</span>
                </h1>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                style={{
                  alignItems: 'flex-start',
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: 20,
                  width: '100%'
                }}
              >
                <Button
                  variant="contained"
                  className={classes.button}
                  onClick={this.randomize}
                >
                  {t('add-new-property.location.randomize')}
                </Button>
              </Grid>
              <div style={{ height: 400, width: '100%' }}>
                <GoogleMap
                  center={[this.state.property.fakeLat, this.state.property.fakeLng]}
                  lat={this.state.property.fakePinLat || this.state.property.fakeLat}
                  lng={this.state.property.fakePinLng || this.state.property.fakeLng}
                  name={this.state.property.titleEn}
                  onMapClick={() => 0}
                />
              </div>
            </Grid>

            <Grid container style={{ marginTop: 35 }}>
              {/* <Grid item xs={12} sm={12} md={12}>
                <h1 className="title-wrapper">
                  <span>{t('add-new-property.area.info')}</span>
                </h1>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Card style={{ padding: 15, marginTop: 25 }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6" gutterBottom>
                      {t('add-new-property.property.area-description-en')}
                    </Typography>
                    <FormControl className={classes.formTextTitle}>
                      <FormLabel component="legend" style={{ marginBottom: 16 }}>
                        {t('add-new-property.property.description-en')}
                      </FormLabel>
                      <CKEditor
                        data={property.regionDescriptionEn}
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          this.changeNeighborhoodDescriptionEnDebounced(data);
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Card>
                <Card style={{ padding: 15, marginTop: 25 }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6" gutterBottom>
                      {t('add-new-property.property.area-description-el')}
                    </Typography>
                    <FormControl className={classes.formTextTitle}>
                      <FormLabel component="legend" style={{ marginBottom: 16 }}>
                        {t('add-new-property.property.description-el')}
                      </FormLabel>
                      <CKEditor
                        data={property.regionDescriptionEl}
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          this.changeNeighborhoodDescriptionElDebounced(data);
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Card>
                <Card style={{ padding: 15, marginTop: 25, marginBottom: 25 }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="h6" gutterBottom>
                      {t('add-new-property.property.area-description-zh')}
                    </Typography>
                    <FormControl className={classes.formTextTitle}>
                      <FormLabel component="legend" style={{ marginBottom: 16 }}>
                        {t('add-new-property.property.description-zh')}
                      </FormLabel>
                      <CKEditor
                        data={property.regionDescriptionZh}
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          this.changeNeighborhoodDescriptionZhDebounced(data);
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Card>
              </Grid> */}
              {/* <Grid item xs={12} md={12}>
                <FormControl className={classes.formControl}>
                  <FormLabel component="legend">
                    {t('add-new-property.area.images')}
                  </FormLabel>
                  <Upload
                    // action="http://localhost:4000/ads/upload"
                    beforeUpload={this.beforeUploadProperty}
                    customRequest={this.handleUploadRegion}
                    fileList={fileListRegion}
                    multiple
                    listType="picture-card"
                    onChange={this.handleChangeRegion}
                    onPreview={this.handlePreviewProperty}
                    onRemove={(file) => this.removeImageRegion(file)}
                  >
                    {fileListRegion.length < 10 ? uploadButton : null}
                  </Upload>
                  <Modal
                    footer={null}
                    onCancel={this.handleCancel}
                    visible={previewVisible}
                  >
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                  </Modal>
                </FormControl>
              </Grid> */}
              <Grid item xs={12} style={{ textAlign: 'right' }}>
                <Button className={classes.button} type="submit" variant="contained">
                  {this.state.scopes.includes('agent')
                    ? t('add-new-property.submit-for-approval')
                    : t('common.submit')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(withStyles(styles, { withTheme: true })(AddNewProperty))
);
