import AgentProfile from '../components/Profiles/Profile';

const profileRoutes = [
  {
    path: '/dashboard/profile',
    exact: false,
    name: 'List Agents',
    component: AgentProfile
  }
];

export default profileRoutes;
