import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import update from 'immutability-helper';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { notification } from 'antd';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { apiUrl, axiosCallApi } from '../../config/config';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  button: {
    backgroundColor: 'transparent',
    border: '1px solid #000000',
    boxShadow: 'none',
    color: 'black',
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'black',
      color: '#FFFFFF'
    }
  },
  input: {
    display: 'flex',
    padding: 0
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center'
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    fontSize: 16,
    left: 2,
    position: 'absolute'
  },
  paper: {
    marginTop: theme.spacing(1)
  },
  divider: {
    height: theme.spacing(2)
  },
  selectControl: {
    position: 'absolute',
    width: '100%',
    zIndex: 99999
  },
  paperWrapper: {
    padding: 20,
    boxShadow: 'none'
  }
});

class EditContentManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cm: {
        active: '',
        email: '',
        mobile: '',
        name: '',
        password: '',
        scopes: ['CM'],
        surname: ''
      }
    };
  }

  componentDidMount() {
    const { match, t } = this.props;
    this.setState({ loading: true });
    axiosCallApi
      .get(`${apiUrl}/content-manager/${match.params.id}`)
      .then((res) => {
        this.setState(
          update(this.state, {
            cm: {
              $set: res.data.contentManager
            }
          })
        );
      })
      .catch(() => {
        notification.error({
          message: t('common.error-fetch')
        });
      });
  }

  onInputChange = (e) => {
    this.setState(
      update(this.state, {
        cm: {
          [e.target.name]: e.target.value
        }
      })
    );
  };

  submitEditContentManager = (e) => {
    e.preventDefault();
    const data = this.state.cm;
    const { match, t } = this.props;
    axiosCallApi
      .patch(`${apiUrl}/content-manager/single/${match.params.id}`, data)
      .then(() => {
        notification.success({
          message: t('content-managers.edit.update-success')
        });
        this.props.history.push('/dashboard/content-managers/list');
      })
      .catch(() => {
        notification.error({
          message: t('content-managers.edit.update-error')
        });
      });
  };

  onCheckBoxActiveChange = (event) => {
    this.setState(
      update(this.state, {
        cm: {
          active: { $set: event.target.checked }
        }
      })
    );
  };

  render() {
    const { cm } = this.state;
    const { classes, t } = this.props;
    return (
      <div>
        <Paper style={{ position: 'relative' }} className={classes.paperWrapper}>
          <h1 className="title-wrapper">
            <span>{t('content-managers.edit.edit-manager')}</span>
          </h1>
          <form onSubmit={(e) => this.submitEditContentManager(e)}>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 20 }}>
                  <TextField
                    fullWidth
                    id="name"
                    label={t('common.name')}
                    name="name"
                    onChange={this.onInputChange}
                    required
                    value={cm.name}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 20 }}>
                  <TextField
                    fullWidth
                    id="surname"
                    label={t('common.last-name')}
                    name="surname"
                    onChange={this.onInputChange}
                    required
                    value={cm.surname}
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 20 }}>
                  <TextField
                    fullWidth
                    id="email"
                    label={t('common.email')}
                    value={cm.email}
                    name="email"
                    onChange={this.onInputChange}
                    required
                    type="email"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 20 }}>
                  <TextField
                    fullWidth
                    id="password"
                    inputProps={{
                      minLength: 6,
                      maxLength: 10
                    }}
                    label={t('common.password')}
                    name="password"
                    onChange={this.onInputChange}
                    type="password"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} style={{ paddingTop: 20 }}>
                  <TextField
                    fullWidth
                    id="mobile"
                    label={t('common.mobile')}
                    value={cm.mobile}
                    name="mobile"
                    onChange={this.onInputChange}
                    required
                    type="number"
                  />
                </FormControl>
              </Grid>
              <Grid container style={{ marginTop: 20 }}>
                <FormControl className={classes.formControl}>
                  <FormLabel component="legend">{t('common.status')}</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={this.onCheckBoxActiveChange}
                        className="customCheckbox"
                        name="active"
                        checked={cm.active}
                      />
                    }
                    label={
                      cm.active
                        ? t('content-managers.edit.active')
                        : t('content-managers.edit.inactive')
                    }
                  />
                </FormControl>
              </Grid>
              <Grid container spacing={10}>
                <Grid item xs={12}>
                  <Button className={classes.button} type="submit" variant="contained">
                    {t('common.submit')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(withStyles(styles, { withTheme: true })(EditContentManager))
);
