import React from 'react';
import { Route } from 'react-router-dom';
import requestRoutes from '../routes/request.route';

function Request() {
  const routes = requestRoutes.map((prop, key) => (
    <Route
      component={prop.component}
      exact={prop.exact}
      key={key.toString()}
      path={prop.path}
    />
  ));
  return <>{routes}</>;
}

export default Request;
