import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import debounce from 'lodash.debounce';
import EditIcon from '@material-ui/icons/Edit';
import FiberIcon from '@material-ui/icons/FiberManualRecord';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import {
  CustomPaging,
  DataTypeProvider,
  PagingState,
  SearchState,
  SortingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  SearchPanel,
  Table,
  TableHeaderRow,
  Toolbar
} from '@devexpress/dx-react-grid-material-ui';
import { Link, withRouter } from 'react-router-dom';
import { notification } from 'antd';
import { withStyles } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import { Loading } from '../UI-Elements/Loading';
import { apiUrl, articleImagesUrl, axiosCallApi } from '../../config/config';
import * as actions from '../../redux/actions';

const styles = (theme) => ({
  button: {
    marginLeft: theme.spacing(3),
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  paperWrapper: {
    boxShadow: 'none'
  }
});

function StatusFormatter({ value }) {
  if (value === 'Ναι') {
    return (
      <p style={{ marginTop: 5, textAlign: 'center' }}>
        <span className="active-product">{value}</span>
      </p>
    );
  } else {
    return (
      <p style={{ marginTop: 5, textAlign: 'center' }}>
        <span className="not-active-product">{value}</span>
      </p>
    );
  }
}

function StatusTypeProvider(props) {
  return <DataTypeProvider formatterComponent={StatusFormatter} {...props} />;
}

function ImageFormatter({ value, row }) {
  return (
    <img
      className="list-images"
      src={`${articleImagesUrl}/${row._id}/${value}`}
      alt="article"
    />
  );
}

function ImageTypeProvider(props) {
  return <DataTypeProvider formatterComponent={ImageFormatter} {...props} />;
}

function EditButton({ row, history }) {
  return (
    <IconButton
      onClick={() => {
        history.push(`/dashboard/articles/edit/${row._id}`);
      }}
      title="Edit row"
    >
      <EditIcon />
    </IconButton>
  );
}

const Cell = withRouter(({ column, value, style, row, history }) => {
  let content = value;
  if (column.name === 'edit') {
    content = <EditButton row={row} history={history} />;
  }
  if (column.name === 'image') {
    content = <ImageFormatter row={row} value={value} />;
  }
  if (column.name === 'active') {
    content = <StatusFormatter value={value} />;
  }
  return <Table.Cell style={style}>{content}</Table.Cell>;
});

class ArticleList extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      scopes: [],
      columns: [
        {
          name: 'image',
          title: t('blog.table.headers.image'),
          getCellValue: (row) => row.articleImage.thumbs[1].name
        },
        {
          name: 'titleEl',
          title: t('blog.table.headers.titleEl')
        },
        {
          name: 'titleEn',
          title: t('blog.table.headers.titleEn')
        },
        {
          name: 'titleZh',
          title: t('blog.table.headers.titleZh')
        },
        {
          name: 'createdAt',
          title: t('blog.table.headers.submitted-at'),
          getCellValue: (row) => moment(row.createdAt).format('DD/MM/YYYY HH:mm')
        },
        {
          name: 'updatedAt',
          title: t('blog.table.headers.updated-at'),
          getCellValue: (row) => moment(row.updatedAt).format('DD/MM/YYYY HH:mm')
        },
        {
          name: 'published',
          title: t('common.status'),
          getCellValue: (row) =>
            row.published ? (
              <Tooltip title={t('content-managers.edit.active')}>
                <FiberIcon style={{ color: '#87d068' }} aria-label="Delete" />
              </Tooltip>
            ) : (
              <Tooltip title={t('content-managers.edit.inactive')}>
                <FiberIcon style={{ color: '#f50' }} aria-label="Delete" />
              </Tooltip>
            )
        },
        { name: 'edit', title: t('blog.table.headers.edit-article') }
      ],
      sortingStateColumnExtensions: [
        { columnName: 'edit', sortingEnabled: false },
        { columnName: 'image', sortingEnabled: false }
      ],
      filter: '',
      imageColumn: [''],
      loading: true,
      rows: [],
      sorting: [{ columnName: 'createdAt', direction: 'asc' }],
      statusColumns: ['published'],
      totalCount: 0
    };
    this.loadDataDebounced = debounce(() => {
      this.loadData();
    }, 600);
  }

  componentDidMount() {
    axiosCallApi.post(`${apiUrl}/admin/getRole`).then((response) => {
      this.setState({ scopes: response.data.scopes }, () => {
        if (this.state.scopes.includes('CM')) {
          const newcolumns = [...this.state.columns];
          newcolumns.pop();
          this.setState({ columns: newcolumns });
        }
      });
    });
    this.loadDataDebounced();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.sorting !== prevState.sorting) {
      this.props.onCurrentPageChange(0);
    }
    if (
      this.props.currentPage !== prevProps.currentPage ||
      this.props.pageSize !== prevProps.pageSize ||
      this.state.filter !== prevState.filter ||
      this.state.sorting !== prevState.sorting
    ) {
      this.loadData();
    }
  }

  changeSorting = (sorting) => {
    this.setState({
      loading: true,
      sorting
    });
  };

  changePageSize = (pageSize) => {
    const { totalCount } = this.state;
    const totalPages = Math.ceil(totalCount / pageSize);
    const currentPage = Math.min(this.props.currentPage, totalPages - 1);

    this.setState(
      {
        loading: true
      },
      () => {
        this.props.onPageSizeChange(pageSize);
        this.props.onCurrentPageChange(currentPage);
      }
    );
  };

  changeCurrentPage = (currentPage) => {
    this.setState(
      {
        loading: true
      },
      () => this.props.onCurrentPageChange(currentPage)
    );
  };

  changeSearchValue = (searchValue) => {
    this.setState(
      {
        filter: searchValue,
        loading: true
      },
      () => this.props.onCurrentPageChange(0)
    );
  };

  queryString() {
    const { sorting, filter } = this.state;
    const { pageSize, currentPage } = this.props;
    let queryString = `${apiUrl}/article/all/?perPage=${pageSize}&skip=${currentPage}&filter=${filter}`;
    const columnSorting = sorting[0];
    if (columnSorting) {
      const sortingDirectionString = columnSorting.direction === 'desc' ? 1 : -1;
      queryString = `${queryString}&orderby=${columnSorting.columnName}`;
      queryString = `${queryString}&sort=${sortingDirectionString}`;
    }
    return queryString;
  }

  loadData() {
    const { t } = this.props;
    const queryString = this.queryString();
    if (queryString === this.lastQuery) {
      // this.setState({loading: false});
      return;
    }
    axiosCallApi
      .get(queryString)
      .then((response) => {
        this.setState({
          loading: false,
          rows: response.data.articles,
          totalCount: response.data.pages
        });
      })
      .catch(() => {
        notification.error({
          message: t('common.error-fetch')
        });
        this.setState({ loading: false });
      });
    this.lastQuery = queryString;
  }

  render() {
    const { classes, currentPage, pageSize, pageSizes, t } = this.props;
    const {
      columns,
      filter,
      imageColumn,
      loading,
      rows,
      sorting,
      sortingStateColumnExtensions,
      statusColumns,
      tableColumnExtensions,
      totalCount
    } = this.state;

    return (
      <>
        <Typography variant="h6" gutterBottom>
          {t('blog.list.list-articles')}
          <Button
            className={[classes.button, 'secondaryBtnColor'].join(' ')}
            color="secondary"
            component={Link}
            to="/dashboard/articles/add"
            variant="contained"
          >
            <AddIcon className={classes.leftIcon} />
            {t('blog.add-new.add')}
          </Button>
        </Typography>
        <Paper style={{ position: 'relative' }} className={classes.paperWrapper}>
          <Grid rows={rows} columns={columns}>
            <SortingState
              columnExtensions={sortingStateColumnExtensions}
              onSortingChange={this.changeSorting}
              sorting={sorting}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.changeCurrentPage}
              onPageSizeChange={this.changePageSize}
              pageSize={pageSize}
            />
            <CustomPaging totalCount={totalCount} />
            <SearchState onValueChange={this.changeSearchValue} value={filter} />
            <StatusTypeProvider for={statusColumns} />
            <ImageTypeProvider for={imageColumn} />
            <Table cellComponent={Cell} columnExtensions={tableColumnExtensions} />
            <TableHeaderRow showSortingControls />
            <Toolbar />
            <SearchPanel messages={{ searchPlaceholder: t('common.tables.search') }} />
            <PagingPanel
              pageSizes={pageSizes}
              messages={{
                showAll: t('common.tables.footer.all'),
                rowsPerPage: t('common.tables.footer.rows-per-page'),
                info: `${t('common.tables.footer.info.showing')} {from} ${t(
                  'common.tables.footer.info.to'
                )} {to} ({count} ${t('common.tables.footer.info.total-elements')})`
              }}
            />
          </Grid>
          {loading && <Loading />}
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentPage: state.blog.currentPage,
  pageSize: state.blog.pageSize,
  pageSizes: state.blog.pageSizes
});

const mapDispatchToProps = (dispatch) => ({
  onCurrentPageChange: (currentPage) =>
    dispatch(actions.createGridBlogAction('currentPage', currentPage)),
  onPageSizeChange: (pageSize) =>
    dispatch(actions.createGridBlogAction('pageSize', pageSize))
});

export default withTranslation()(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ArticleList))
  )
);
