import React from 'react';

export function houseTypes(t) {
  return [
    { label: t('houseTypes.apartment'), value: 'apartment' },
    { label: t('houseTypes.studio'), value: 'studio' },
    { label: t('houseTypes.maisonette'), value: 'maisonette' },
    { label: t('houseTypes.villa'), value: 'villa' },
    { label: t('houseTypes.detouched'), value: 'detouched' }
    //  { label: t('houseTypes.investment-building'), value: 'investment-building' }
  ];
}

export function translateHouseTypes(t, value) {
  switch (value) {
    case 'apartment':
      return t('houseTypes.apartment');
    case 'studio':
      return t('houseTypes.studio');
    case 'maisonette':
      return t('houseTypes.maisonette');
    case 'villa':
      return t('houseTypes.villa');
    // case 'investment-building':
    //   return t('houseTypes.investment-building');
    case 'detouched':
      return t('houseTypes.detouched');
    default:
      return 'apartment';
  }
}

// eslint-disable-next-line
export function translateName(lang, element) {
  if (element) {
    switch (lang) {
      case 'el':
        return element.nameEl || element.nameEn;
      case 'en':
        return element.nameEn || element.nameEl;
      case 'zh':
        return element.nameZh;
      default:
        return 'en';
    }
  }
}

// eslint-disable-next-line
export function translateTitle(lang, element) {
  if (element) {
    switch (lang) {
      case 'el':
        return element.titleEl;
      case 'en':
        return element.titleEn;
      case 'zh':
        return element.titleZh;
      default:
        return 'en';
    }
  }
}

// eslint-disable-next-line
export function translateDescription(lang, element) {
  if (element) {
    switch (lang) {
      case 'el':
        return element.descriptionEl;
      case 'en':
        return element.descriptionEn;
      case 'zh':
        return element.descriptionZh;
      default:
        return 'en';
    }
  }
}

export const months = [
  { label: 'Ιανουάριος', value: 0 },
  { label: 'Φεβρουάριος', value: 1 },
  { label: 'Μάρτιος', value: 2 },
  { label: 'Απρίλιος', value: 3 },
  { label: 'Μαϊος', value: 4 },
  { label: 'Ιούνιος', value: 5 },
  { label: 'Ιούλιος', value: 6 },
  { label: 'Αύγουστος', value: 7 },
  { label: 'Σεπτέμβριος', value: 8 },
  { label: 'Οκτώβριος', value: 9 },
  { label: 'Νοέμβριος', value: 10 },
  { label: 'Δεκέμβριος', value: 11 }
];

export const years = [
  { label: 2018, value: 2018 },
  { label: 2019, value: 2019 },
  { label: 2020, value: 2020 },
  { label: 2021, value: 2021 },
  { label: 2022, value: 2022 },
  { label: 2023, value: 2023 },
  { label: 2024, value: 2024 },
  { label: 2025, value: 2025 }
];

export const days = [
  { label: 'Δευτέρα', value: 'monday' },
  { label: 'Τρίτη', value: 'tuesday' },
  { label: 'Τετάρτη', value: 'wednesday' },
  { label: 'Πέμπτη', value: 'thursday' },
  { label: 'Παρασκευή', value: 'friday' },
  { label: 'Σάββατο', value: 'saturday' },
  { label: 'Κυριακή', value: 'sunday' },
  { label: 'Εμβόλιμα', value: 'emvolima' }
];

export const data = [
  {
    name: 'January',
    views: 0,
    requests: 0
  },
  {
    name: 'February',
    views: 0,
    requests: 0
  },
  {
    name: 'March',
    views: 0,
    requests: 0
  },
  {
    name: 'April',
    views: 0,
    requests: 0
  },
  {
    name: 'May',
    views: 0,
    requests: 0
  },
  {
    name: 'June',
    views: 0,
    requests: 0
  },
  {
    name: 'July',
    views: 0,
    requests: 0
  },
  {
    name: 'August',
    views: 0,
    requests: 0
  },
  {
    name: 'September',
    views: 0,
    requests: 0
  },
  {
    name: 'October',
    views: 0,
    requests: 0
  },
  {
    name: 'November',
    views: 0,
    requests: 0
  },
  {
    name: 'December',
    views: 0,
    requests: 0
  }
];

// Κατηγορίες ακινήτων: Family, Sea-View, Downtown, Buy-to-let, New Built, Renovated, Fixers, Athens Riviera

export const categories = [
  {
    value: 'family',
    label: 'Family'
  },
  {
    value: 'sea-view',
    label: 'Sea-View'
  },
  {
    value: 'downtown',
    label: 'Downtown'
  },
  {
    value: 'buy-to-let',
    label: 'Buy-to-let'
  },
  {
    value: 'new-built',
    label: 'New Built'
  },
  {
    value: 'renovated',
    label: 'Renovated'
  },
  {
    value: 'fixers',
    label: 'Fixers'
  },
  {
    value: 'athens riviera',
    label: 'Athens Riviera'
  }
];

export const stickers = [
  {
    value: 'family',
    label: 'Family'
  },
  {
    value: 'sea-view',
    label: 'Sea-View'
  },
  {
    value: 'price-reduced',
    label: 'Price Reduced'
  },
  {
    value: 'buy-to-let',
    label: 'Buy-to-let'
  },
  {
    value: 'renovated',
    label: 'Renovated'
  },
  {
    value: 'fixer',
    label: 'Fixer'
  }
];

export const energyClassOptions = [
  { label: 'in-proccess', value: 'in-proccess' },
  { label: 'a', value: 'a' },
  { label: 'a+', value: 'a+' },
  { label: 'b', value: 'b' },
  { label: 'b+', value: 'b+' },
  { label: 'c', value: 'c' },
  { label: 'd', value: 'd' },
  { label: 'e', value: 'e' },
  { label: 'f', value: 'f' },
  { label: 'g', value: 'g' }
];

export const translateMonths = (t, month) => t(`months.${month}`);

export function ArrowLeft() {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="20px"
      height="20px"
      viewBox="0 0 123.725 123.725"
    >
      <path
        fill="rgba(0,0,0,0.5)"
        d="M2.325,67.002l38.6,30c3.9,3.101,9.7,0.3,9.7-4.7V81.902c0-3.3,2.6-5.9,5.899-5.9h61.5c3.301,0,5.7-2.8,5.7-6.1v-16.1
  		c0-3.3-2.399-5.8-5.7-5.8h-61.5c-3.3,0-5.899-2.9-5.899-6.2v-10.3c0-5-5.8-7.9-9.8-4.8l-38.5,30.8
  		C-0.775,59.902-0.775,64.603,2.325,67.002z"
      />
    </svg>
  );
}

export function ArrowRight() {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="20px"
      height="20px"
      viewBox="0 0 123.964 123.964"
    >
      <path
        fill="rgba(0,0,0,0.5)"
        d="M121.7,57.681L83,26.881c-4-3.1-10-0.3-10,4.8v10.3c0,3.3-2.2,6.2-5.5,6.2H6c-3.3,0-6,2.4-6,5.8v16.2c0,3.2,2.7,6,6,6h61.5
  		c3.3,0,5.5,2.601,5.5,5.9v10.3c0,5,6,7.8,9.9,4.7l38.6-30C124.7,64.781,124.8,60.081,121.7,57.681z"
      />
    </svg>
  );
}

// based on https://gis.stackexchange.com/questions/25877/generating-random-locations-nearby

export const generateFakeLocation = (lat, lng) => {
  const r = 150 / 111300; // = 150 meters
  const y0 = lat;
  const x0 = lng;
  const u = Math.random();
  const v = Math.random();
  const w = r * Math.sqrt(u);
  const t = 2 * Math.PI * v;
  const x = w * Math.cos(t);
  const y1 = w * Math.sin(t);
  const x1 = x / Math.cos(y0);

  return { lat: y0 + y1, lng: x0 + x1 };
};
